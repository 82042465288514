// import AsyncStorage from "@react-native-async-storage/async-storage";
// import * as SecureStore from "expo-secure-store";

export const getKey = async key => {
  let result = await sessionStorage.getItem(key)

  return result
}

export const getData = async key => {
  try {
    const jsonValue = await sessionStorage.getItem(key)
    return jsonValue != null ? JSON.parse(jsonValue) : null
  } catch (e) {
    // error reading value
  }
}

import React from "react"
import { Fragment, useState } from "react"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import BeneficiaryPrincipalProfile from "./tabs/principalProfile"
import HouseHoldDetails from "./tabs/houseHoldDetails"
import DependantsDetails from "./tabs/dependants"
import {
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

const BeneficiaryProfile = () => {
  const [activeTab, setActiveTab] = React.useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const navigate = useNavigate()
  const params = useParams()

  return (
    <Fragment>
      <Container fluid>
        <div className="page-content">
          <div>
            <div className="d-flex justify-content-between">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active text-primary" : ""}
                    onClick={() => {
                      toggle("1")
                    }}
                  >
                    Principal Beneficiary's Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active text-primary" : ""}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    HouseHold Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" ? "active text-primary" : ""}
                    onClick={() => {
                      toggle("3")
                    }}
                  >
                    Dependants
                  </NavLink>
                </NavItem>
              </Nav>
              <Button
                onClick={() => {
                  navigate(-1)
                }}
                className="w-25 bg-info border-0"
              >
                Back
              </Button>
            </div>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="mt-5">
                  <BeneficiaryPrincipalProfile />
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="mt-5">
                  <HouseHoldDetails />
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="mt-5">
                  <DependantsDetails />
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default BeneficiaryProfile

import React, { useMemo, Fragment, useState, useEffect } from "react"
import { Container, Col, Row, Button, Card, CardBody, Table } from "reactstrap"
import { useQuery } from "react-query"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GENERAL } from "../../../api/general"
import dayjs from "dayjs"
import PaginationComponent from "components/Common/pagination"
import {
  API_LIST_ORDER,
  API_RECORD_LIST_SIZE,
} from "helpers/global_variables.helper"
import yearOptions, {
  VERIFICATION_STATUS,
  filterMonthOptions,
} from "helpers/options.helper"
import ActivityLoader from "components/activity-loader"
import useDebounce from "helpers/hooks/useDebounce"

const SuperAdminViewVerification = () => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const [data, setRecords] = useState([])
  const [verificationOption, setVerificationOption] = React.useState("")
  const [yearOption, setYearOption] = React.useState(new Date().getFullYear())
  const [monthOption, setMonthOption] = React.useState(
    new Date().getMonth() + 1
  )
  const [searchFilter, setSearchFilter] = React.useState("")
  const onChange = value => {
    setSearchFilter(value)
  }
  const { value: searchQuery } = useDebounce(searchFilter, 1000)

    useEffect(() => {
      setCurrentIndex(1)
    }, [searchFilter, monthOption, yearOption])


  const { data: getVerifications, isLoading } = useQuery({
    queryKey: [
      "getVerifications",
      {
        verificationStatus: verificationOption,
        page: currentIndex,
        searchQuery,
        month: monthOption,
        year: yearOption,
      },
    ],
    queryFn: () => {
      return GENERAL.getVerifications({
        order: API_LIST_ORDER.desc,
        verificationStatus: verificationOption,
        search: searchQuery,
        page: currentIndex,
        take: API_RECORD_LIST_SIZE,
        month: monthOption,
        year: yearOption,
      })
    },
    onSuccess: async res => {
      setRecords(res.records)
    },
  })

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return data?.map((_, index) => index + offset + 1)
  }, [data, currentIndex, API_RECORD_LIST_SIZE])

  const NINFormat = cell => {
    const NIN = cell.cell.row.original.beneficiary
      ? cell.cell.row.original.beneficiary.nin
      : ""
    const TrackingId = cell.cell.row.original.beneficiary
      ? cell.cell.row.original?.beneficiary.beneficiaryNin?.trackingId
      : ""

    if (NIN) {
      return <p>{NIN}</p>
    } else {
      return <p>{TrackingId}</p>
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        Cell: cell => {
          return <p>{getSns[cell.row.index]}</p>
        },
      },
      {
        Header: "Full Name",
        Cell: cellProps => {
          const firstName = cellProps.row.original.beneficiary.firstName
          const middleName = cellProps.row.original.beneficiary.middleName
          const surname = cellProps.row.original.beneficiary.surname
          return `${firstName + " " ?? ""}${middleName + " " ?? ""}${
            surname + " " ?? ""
          } `
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiary.beneficiaryId",
      },
      {
        Header: "NIN/Tracking ID",
        Cell: cellProps => {
          return <NINFormat {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "beneficiary.phoneNumber",
      },

      // {
      //   Header: "LGA of Residence",
      //   accessor: "beneficiary.lgaOfOrigin",
      // },
      // {
      //   Header: "Address",
      //   accessor: "beneficiary.address",
      // },

      {
        Header: "State",
        accessor: "beneficiary.stateOfOrigin",
      },
      {
        Header: "Agent",
        accessor: "createdBy",
      },
      {
        Header: "Verification Date",
        accessor: "verificationDate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
    ],
    [data, currentIndex]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: {
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const FormatVerificationStatus = cell => {
    return cell.value ? "Verified" : "Not Verified"
  }
  const filterOptions = [
    { id: 1, name: "All Beneficiaries" },
    { id: 2, name: "Beneficiaries With NIN" },
    { id: 3, name: "Beneficiaries Without NIN" },
    { id: 4, name: "Ineligible Applicants" },
  ]

  const veriicationOptions = [
    { id: VERIFICATION_STATUS.VERIFIED, name: "Verified Beneficiaries" },
    { id: VERIFICATION_STATUS.UNVERIFIED, name: "Unverified Beneficiaries" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title="All Verifications"
              breadcrumbItem="All Verifications"
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <Fragment>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "2rem",
                  }}
                >
                  <Col md={3}>
                    <div className=" h4 card-title">
                      All Verifications - {getVerifications?.total}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="search-box d-inline-block">
                      <div
                        className="position-relative"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label htmlFor="search-bar-0" className="search-label">
                          <input
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`search`}
                            value={searchFilter || ""}
                          />
                        </label>
                        <i
                          className="bx bx-search-alt search-icon"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    </div>
                  </Col>
                  {/* <Col md={2}>
                    <select
                      onChange={({ target }) =>
                        setVerificationOption(target.value)
                      }
                      className="form-select"
                      value={verificationOption}
                    >
                      <option key="" value="">
                        All verifications
                      </option>
                      {veriicationOptions?.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col> */}
                  <Col md={3}>
                    <select
                      onChange={({ target }) => setMonthOption(target.value)}
                      className="form-select"
                      value={monthOption}
                    >
                      {filterMonthOptions?.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={3}>
                    <select
                      onChange={({ target }) => setYearOption(target.value)}
                      className="form-select"
                      value={yearOption}
                    >
                      {yearOptions?.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {isLoading ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : rows.length > 0 ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (getVerifications?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SuperAdminViewVerification

// convert image to base64
export const FileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

 export const Greeting = (name) => {
  let timeNow = new Date() // Get the current time
  let hours = timeNow.getHours() // Get the number of hours

 if(hours >= 18) return `Good evening, ${name} !`;
 if(hours >= 12) return `Good afternoon, ${name} !`;
 return `Good morning, ${name}!`;
}

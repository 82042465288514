import React, { useMemo, Fragment, useState } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
  Modal,
  ModalBody,
  Form,
  Label,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { GENERAL } from "../../../api/general"
import Swal from "sweetalert2"
import {
  USER_STATUS,
} from "helpers/global_variables.helper"
import ActivityLoader from "components/activity-loader"
import { preview_base64 } from "helpers/methods.helper"

const UserProfileView = props => {
  const params = useParams()
  const queryClient = useQueryClient()

  const [agents, setAgents] = useState([])
  const navigate = useNavigate()
  //meta title
  document.title = "Agents Registration - eRecruitement"

  const { data: userProfile, isLoading } = useQuery({
    queryKey: ["getUserById", params?.id],
    queryFn: () => {
      return GENERAL.getUserById({
        id: params.id,
      })
    },
  })

  const [show, setShow] = useState(false)
  const [agentId, setAgentId] = useState(null)

  const closeModal = () => {
    setShow(false)
  }

  const handleSelectAgent = evt => {
    const agentId = evt.target.value
    setAgentId(agentId)
  }

  const deactivateAgent = useMutation({
    mutationFn: payload => {
      return GENERAL.deactivateUser(payload)
    },
    onSuccess: async response => {
      Swal.close()
      queryClient.invalidateQueries({ queryKey: ["getUserById"] })
      Swal.fire("Process Successful", response.data.message, "success")
    },
    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })
  const activateAgent = useMutation({
    mutationFn: payload => {
      return GENERAL.activateUser(payload)
    },
    onSuccess: async response => {
      Swal.close()
      queryClient.invalidateQueries({ queryKey: ["getUserById"] })
      Swal.fire("Process Successful", response.data.message, "success")
    },
    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })
  const deactivateUser = userProfile => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to deactivate this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deactivating Agent...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        // setTimeout(() => {
        //   Swal.fire(
        //     "Process Successful",
        //     "You have successfully deactivated the user",
        //     "success"
        //   )
        // }, 2000)

        const deactivateAgentDto = {
          id: userProfile.id,
        }
        deactivateAgent.mutate(deactivateAgentDto)
      }
    })
  }
  const activateUser = userProfile => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to activate this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Activating Agent...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        const activateAgentDto = {
          id: userProfile.id,
        }
        activateAgent.mutate(activateAgentDto)
      }
    })
  }

  const handleMigrateBeneficiary = () => {
    setShow(false)
    Swal.fire({
      title: "Migrating Beneficiaries...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    migrateBeneficiaryFn.mutate({ agentFrom: params?.id, agentTo: agentId })
  }

  const migrateBeneficiaryFn = useMutation({
    mutationFn: payload => {
      return GENERAL.migrateBeneficiaries(payload.agentFrom, payload.agentTo)
    },
    onSuccess: async response => {
      Swal.close()
      if (response.status === 200) {
        Swal.close()
        setTimeout(() => {
          Swal.fire(
            "Process Successful",
            "Successfully migrated beneficiaries",
            "success"
          ).then(response => {
            if (response.isConfirmed) {
              getUserById.refetch()
            }
          })
        }, 2000)
      }
      if (response.status === 400) {
        Swal.close()
        Swal.fire("Process Failed!", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.close()
        Swal.fire(
          "Process Failed!",
          response?.data?.message.join(", "),
          "error"
        )
      }
      if (response.status === 401) {
        Swal.close()
        Swal.fire("Process Failed!", "Unauthorized!", "error")
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Agents")}
              childLink={props.t("Profile")}
              hasBackBtn={true}
            />
          </Row>

          <Modal size="lg" isOpen={show} centered={true}>
            <ModalBody className="py-3 px-5">
              <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                <Col
                  lg={12}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5>Migrate Beneficiaries</h5>

                    <div onClick={closeModal}>
                      <i
                        className="bx bx-x"
                        style={{ fontSize: 24, cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                </Col>
                <Form>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password-Input">
                          Select Agent <span className="text-danger">*</span>
                        </Label>
                        <div className="col-md-12">
                          <select
                            onChange={handleSelectAgent}
                            className="form-control"
                          >
                            <option>Select</option>
                            {agents?.map(agent => (
                              <option key={agent.id} value={agent.id}>
                                {agent.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Row>
                <div className="text-center">
                  <button
                    style={{ marginRight: 10 }}
                    className="btn btn-danger w-80 mr-2"
                    onClick={closeModal}
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    onClick={handleMigrateBeneficiary}
                    className="btn btn-primary w-80 ml-5"
                  >
                    Migrate Beneficiaries
                  </button>
                </div>
              </Row>
            </ModalBody>
          </Modal>

          {isLoading ? (
            <center>
              <ActivityLoader variant="primary" />
            </center>
          ) : (
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div className="text-black">
                      <h5>Agent Profile</h5>
                    </div>

                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col lg="2" className="mt-5">
                        <div className="d-flex text-center">
                          <div className="ms-3 text-center">
                            <img
                              src={preview_base64(userProfile?.photo || "")}
                              width={400}
                              height={400}
                              style={{ borderRadius: "50%" }}
                              alt={userProfile?.name}
                              className="avatar-xl"
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg="8">
                        <Row>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Full Name</p>
                            <h5>{userProfile?.name ?? "---"}</h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Gender</p>
                            <h5>{userProfile?.gender ?? "---"}</h5>
                          </Col>{" "}
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Date of Birth</p>
                            <h5>{userProfile?.date_of_birth ?? "---"}</h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">State</p>
                            <h5>{userProfile?.state_of_origin ?? "---"}</h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">LGA</p>
                            <h5>{userProfile?.lga_of_origin ?? "---"}</h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Status</p>
                            <h5>
                              {userProfile?.status === "new"
                                ? "Active"
                                : userProfile?.status ?? "---"}
                            </h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Phone Number</p>
                            <h5>{userProfile?.phone_number ?? "---"}</h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Email Address</p>
                            <h5>{userProfile?.email ?? "---"}</h5>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg="2" className="mt-5">
                        {[USER_STATUS.NEW, USER_STATUS.ACTIVE].includes(
                          userProfile?.status
                        ) && (
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-danger btn-block"
                              type="submit"
                              onClick={() => deactivateUser(userProfile)}
                            >
                              Deactivate User
                            </button>
                          </div>
                        )}
                        {userProfile?.status === USER_STATUS.LOCKED && (
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              onClick={() => activateUser(userProfile)}
                            >
                              Activate Agent
                            </button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(UserProfileView)

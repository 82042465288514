import React, { Fragment, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"
import SuperAdminBeneficiaries from "./SuperAdminBeneficiaries"
import SupervisorBeneficiaries from "./SupervisorBeneficiaries"
import { ROLES } from "helpers/global_variables.helper"

const Beneficiary = props => {
  const [userRole, setUserRole] = useState(
    JSON.parse(sessionStorage.getItem("userRole"))
  )

  return (
    <Fragment>
      {userRole === ROLES.superadmin && <SuperAdminBeneficiaries />}
      {userRole === ROLES.supervisor && <SupervisorBeneficiaries />}
      {userRole === ROLES.lga_coordinator && <SupervisorBeneficiaries />}
    </Fragment>
  )
}

export default withTranslation()(Beneficiary)

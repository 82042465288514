import axios from "axios"
import Alert from "./alert.helper"

class ErrorHandler {
  static parser(error) {
    let errorMessage

    if (axios.isAxiosError(error)) {
      errorMessage = error.response
        ? ErrorHandler.#parseAxiosResponse(error.response)
        : "NETWORK ERROR: Something went wrong while processing this request."

      return Alert(errorMessage, "error")
    }

    return Alert("NETWORK ERROR", "error")
  }

  static #parseAxiosResponse(response) {
    const responseData = response.data && response.data.message
    return Array.isArray(responseData) ? responseData[0] : responseData
  }
}

export default ErrorHandler

import React, { Fragment, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SupervisorBeneficiaryStatisticsDashboard from "./SupervisorBeneficiaryStatisticsDashboard"

const SupervisorDashboard = () => {
  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            parentLink="Beneficiary Statistics"
          />

          <Row>
            <Col lg={12}>
              <CardBody>
                <SupervisorBeneficiaryStatisticsDashboard />
              </CardBody>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default SupervisorDashboard

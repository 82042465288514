import React, { Fragment, useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { GENERAL } from "../../../api/general"
import { useQuery } from "react-query"
import { data, states } from "common/data/states"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ActivityLoader from "components/activity-loader"
import CountUp from "react-countup"
import yearOptions, { filterMonthOptions } from "helpers/options.helper"
import { ROLES } from "helpers/global_variables.helper"

const SuperAdminAgentPayment = props => {
  const statesData = states
  const [records, setRecords] = useState([])
  const [yearOption, setYearOption] = React.useState(new Date().getFullYear())
  const [monthOption, setMonthOption] = React.useState(
    new Date().getMonth() + 1
  )

  const { data: agentsGroupByState, isLoading } = useQuery({
    queryKey: ["get_agents_group_by_state"],
    queryFn: () => {
      return GENERAL.getAgentsGroupByState()
    },
  })
  const { data: agentsCount, isLoading: agentLoading } = useQuery({
    queryKey: ["get_agents_coount"],
    queryFn: () => {
      return GENERAL.getUserCount({
        role: ROLES.agent,
      })
    },
  })

  useEffect(() => {
    if (agentsGroupByState) {
      const aMapping = agentsGroupByState?.reduce((acc, item) => {
        let stateKey = item?.state?.toLowerCase()
        // Replace "fct abuja" with "federal capital territory"
        if (stateKey === "fct abuja") {
          stateKey = "federal capital territory"
        }

        acc[stateKey] = item
        return acc
      }, {})

    const newArray = statesData?.map(itemB => {
      let stateKey = itemB?.state?.toLowerCase()
      if (stateKey === "fct abuja") {
        stateKey = "federal capital territory"
      }
      const matchedItem = aMapping[stateKey]
      return matchedItem ? { ...itemB, ...matchedItem } : itemB
    })

      setRecords(newArray.sort((a, b) => a.state.localeCompare(b.state)))
    }
  }, [agentsGroupByState])

  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"
  return (
    <React.Fragment>
      <Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Approved Agents Registration"
              breadcrumbItem="Approved Agents Registration"
            />

            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "end",
                marginBottom: "2rem",
              }}
            >
              <Col md="3">
                <Card
                  className="large-stats-wid mb-0"
                  style={{
                    height: "180px",
                    background:
                      "transparent linear-gradient(144deg, #4CA8F8 0%, #8AC3F3 46%, #4CA8F8 100%) 0% 0% no-repeat padding-box",
                  }}
                >
                  <CardBody>
                    <div
                      className="d-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        className="avatar-sm rounded-circle bg-primary mini-stat-icon"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                      >
                        <span className="avatar-title rounded-circle">
                          <i className={"bx bx-copy-alt font-size-24"}></i>
                        </span>
                      </div>

                      <div className="flex-grow-1">
                        {agentLoading ? (
                          ""
                        ) : (
                          <h3 className="mb-0 text-white">
                            {" "}
                            <CountUp
                              start={0}
                              end={agentsCount[0]?.count}
                              duration={2.75}
                            />
                          </h3>
                        )}
                        <p className="text-white fw-medium">Total Agents </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md="6">
                <Row>
                  <Col md={6}>
                    <select
                      onChange={({ target }) => setMonthOption(target.value)}
                      className="form-select"
                      value={monthOption}
                    >
                      <option key="" value="">
                        Select Month
                      </option>
                      {filterMonthOptions?.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={6}>
                    <select
                      onChange={({ target }) => setYearOption(target.value)}
                      className="form-select"
                      value={yearOption}
                    >
                      <option key="" value="">
                        Select Year
                      </option>
                      {yearOptions?.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
            {isLoading ? (
              <center className="h-full py-5">
                <ActivityLoader variant="primary" />
              </center>
            ) : (
              <Row>
                {records?.map((item, key) => (
                  <Col key={"_col_" + key} md="3">
                    <Card className="mini-stats-wid">
                      <CardBody
                        style={{
                          paddingTop: 40,
                          paddingBottom: 40,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div className="text-center mb-4">
                          <img
                            src={getItemByName(
                              data,
                              item?.state?.toLowerCase()
                            )}
                            width={100}
                            height={100}
                            style={{ textAlign: "center" }}
                          />

                          <div className="row justify-content-center mt-3">
                            <div className="col-xl-10">
                              <h4 className="text-primary">{item.state}</h4>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col md="5">
                            <Row>
                              <h5
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              >
                                Threshold
                              </h5>
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                {item?.threshold}
                              </h6>
                            </Row>
                          </Col>
                          <Col md="7">
                            <Row>
                              <h5
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              >
                                Agents
                              </h5>
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                {item?.count?.toLocaleString()}
                              </h6>
                            </Row>
                          </Col>
                        </Row>
                        <hr />

                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/agent-payments-by-lga/${
                                  item?.id
                                }/${encodeURIComponent(
                                  item?.state
                                )}/${monthOption}/${yearOption}`}
                              >
                                View LGAs
                              </Link>
                            </h5>
                          </Col>
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/view-agent-payments-by-state/${item?.state}/${monthOption}/${yearOption}`}
                              >
                                View Agents
                              </Link>
                            </h5>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </div>
      </Fragment>
    </React.Fragment>
  )
}

export default SuperAdminAgentPayment

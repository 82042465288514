import React from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const BackButton = props => {
  console.log("page props :: ", props)
  const history = useNavigate()
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-sm-flex align-items-center justify-content-between">
            {props.hasBackBtn && (
              <i
                className="bx bx-chevron-left"
                style={{
                  fontSize: 35,
                  marginRight: 20,
                  color: "white",
                  cursor: "pointer",
                  background: 'green',
                  borderRadius: '50%'
                }}
                onClick={() => {
                  history(-1)
                }}
              ></i>
            )}

            {/* <p className="mb-sm-0 font-size-14" style={{ textTransform: 'uppercase' }}>Good morning, John Doe</p> */}
          </div>
          <div className="page-title-right">
            {/* <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="/">Dashboard</Link>/
                <Link to="#">{props.parentLink}</Link>/
                <Link to="#">{props.childLink}</Link>
              </BreadcrumbItem>
            </ol> */}
          </div>
        </div>
      </Col>
    </Row>
  )
}

BackButton.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default BackButton

import { get, post, put } from "../services"
import { getKey } from "../helpers"

export const STATISTICS = {
  getTopFiveBeneficiariesByState: async () => {
    let data = await get(`/stats/top-5-beneficiary-by-state`)

    return data
  },
  getTopFiveBeneficiariesByLga: async () => {
    let data = await get(`/stats/top-5-beneficiary-by-lga`)

    return data
  },
  getBeneficiaryCount: async () => {
    let data = await get(`/stats/beneficry-count`)

    return data
  },
  getBeneficiariesByGender: async () => {
    let data = await get(`/stats/beneficiary-by-gender`)

    return data
  },
  getBeneficiariesByMaritalStatus: async () => {
    let data = await get(`/stats/beneficiary-by-marital-status`)

    return data
  },
  getBeneficiariesByAge: async () => {
    let data = await get(`/stats/beneficiary-by-age`)

    return data
  },
  top3VerificationsByState: async () => {
    let data = await get(`/stats/top-3-verifications-by-state`)

    return data
  },
  top5PaymentsByState: async () => {
    let data = await get(`/stats/top-5-payments-by-state`)

    return data
  },
  verifiedByMonth: async () => {
    let data = await get(`/stats/verified-by-month`)

    return data
  },
  topPaymentsByState: async () => {
    let data = await get(`/stats/top-5-payments-by-state`)

    return data
  },
  getTotalPaymentsByState: async () => {
    let data = await get(`/stats/total-payments-by-states`)

    return data
  },
  getTotalPaymentsByMonth: async year => {
    let data = await get(`/stats/payments-by-month/${year}`)

    return data
  },
  getTotalPaymentsToBeneficiaries: async year => {
    let data = await get(`/stats/total-paid-to-beneficiaries`)

    return data
  },
  getUnpaidTotalPaymentsToBeneficiaries: async year => {
    let data = await get(`/stats/total-unpaid-to-beneficiaries`)

    return data
  },
}

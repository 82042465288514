import React, { Fragment, useState } from "react"
import BeneficiaryStatisticsDashboard from "./BeneficiaryStatisticsDashboard"
import VerificationStatisticsDashboard from "./VerificationStatisticsDashboard"
import PaymentStatisticsDashboard from "./PaymentStatisticsDashboard"
import {
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classNames from "classnames"
const RegionalCoordinatorDashboard = () => {
  const dashboard = {
    BENEFICIARY: 5,
    VERIFICATION: 6,
    PAYMENT: 7,
  }
  const [activeTab1, setactiveTab1] = useState(dashboard.BENEFICIARY)
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            parentLink={
              activeTab1 === dashboard.BENEFICIARY
                ? "Beneficiary Statistics"
                : activeTab1 === dashboard.VERIFICATION
                ? "Verification Statistics"
                : "Payment Statistics"
            }
          />

          <Row>
            <Col lg={12}>
              <CardBody>
                <Nav pills className="navtab-bg nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: activeTab1 === dashboard.BENEFICIARY,
                      })}
                      onClick={() => {
                        toggle1(dashboard.BENEFICIARY)
                      }}
                    >
                      Beneficiary Statistics
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: activeTab1 === dashboard.VERIFICATION,
                      })}
                      onClick={() => {
                        toggle1(dashboard.VERIFICATION)
                      }}
                    >
                      Verification Statistics
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: activeTab1 === dashboard.PAYMENT,
                      })}
                      onClick={() => {
                        toggle1(dashboard.PAYMENT)
                      }}
                    >
                      Payment Statistics
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={activeTab1}
                  className="text-black pt-3"
                  style={{}}
                >
                  <TabPane tabId={dashboard.BENEFICIARY}>
                    <BeneficiaryStatisticsDashboard />
                  </TabPane>
                  <TabPane tabId={dashboard.VERIFICATION}>
                    <VerificationStatisticsDashboard />
                  </TabPane>
                  <TabPane tabId={dashboard.PAYMENT}>
                    <PaymentStatisticsDashboard />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default RegionalCoordinatorDashboard

import React, { Fragment, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { useTable } from "react-table"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import ActivityLoader from "components/activity-loader"
import dayjs from "dayjs"
import Alert from "helpers/alert.helper"
import ErrorHandler from "helpers/error.helper"
import { useFileUploader } from "helpers/hooks/useFileUploader"
import { FileUp } from "lucide-react"
import { withTranslation } from "react-i18next"
import {
  fetchDocumentPreviewUrl,
  fetchDocumentUploads,
  trigerDocumentProcessing,
} from "services/upload.service"
import PaginationComponent from "components/Common/pagination"
import useDebounce from "helpers/hooks/useDebounce"
import {
  API_LIST_ORDER,
  API_RECORD_LIST_SIZE,
} from "helpers/global_variables.helper"

const index = props => {
  //meta title
  document.title = "Agents Registration - eRecruitement"

  const [showUploadModal, setShowUploadModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [activity, setActivity] = useState({
    isStartingDocumentProcessing: false,
    isProcessingDownload: false,
  })
  const [searchFilter, setSearchFilter] = useState("")
  const [currentIndex, setCurrentIndex] = useState(1)

  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const { data: uploadedDocs, isLoading: isLoadingUploadedDocs } = useQuery({
    queryKey: ["uploaded-documents", { page: currentIndex, searchQuery }],
    queryFn: () => {
      return fetchDocumentUploads({
        take: API_RECORD_LIST_SIZE,
        order: API_LIST_ORDER.desc,
        page: currentIndex,
        search: searchQuery,
      })
    },
    refetchInterval: 1000 * 60,
    refetchIntervalInBackground: 1000 * 60,
  })

  useEffect(() => {
    uploadedDocs && setCurrentIndex(uploadedDocs?.pageOptions.page)
  }, [uploadedDocs])

  const handleDocumentDownload = async cell => {
    const filename = cell.row.original.fileName
    const etag = cell.row.original.checksum
    try {
      setActivity(prev => ({ ...prev, isProcessingDownload: true }))
      Alert("Downloading...", "info")

      const response = await fetchDocumentPreviewUrl(filename, etag)
      setActivity(prev => ({ ...prev, isProcessingDownload: false }))

      window.location = response.url
    } catch (error) {
      setActivity(prev => ({ ...prev, isProcessingDownload: false }))
      ErrorHandler.parser(error)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "File Name",
        accessor: "parentFileName",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Overall Checks",
        accessor: "totalRecord",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <p>{cellProps.value.toString()}</p>
        },
      },
      {
        Header: "Successful Checks",
        accessor: "validRecords",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <p>{cellProps.value.toString()}</p>
        },
      },
      {
        Header: "Unsuccessful Checks",
        accessor: "invalidRecords",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <p>{cellProps.value.toString()}</p>
        },
      },
      {
        Header: "Check Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          if (cellProps.value == "in_progress")
            return (
              <p className="text-warning d-flex align-items-center ">
                {/* <Clock size={16} /> */}
                In Progress
              </p>
            )
          return (
            <p className="text-success d-flex align-items-center">
              {/* <CheckCircle2 size={16} /> */}
              Completed
            </p>
          )
        },
      },
      {
        Header: "Uploaded Date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
      {
        Header: "",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                handleDocumentDownload(cellProps)
              }}
              disabled={activity.isProcessingDownload}
            >
              Downloaded Response
            </Button>
          )
        },
      },
    ],
    [activity.isProcessingDownload]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: uploadedDocs?.data ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: 10,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }
  const FullnameFormat = record => {
    console.log("this is the cell record :: ", record?.cell?.row?.original)
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.first_name ?? ""} ${rowRecord?.middle_name ?? ""} ${
      rowRecord?.surname ?? ""
    }`
  }

  // Upload hook
  const { upload: uploadDocument, loading: isUploading } = useFileUploader()

  const handleFileChange = evt => {
    setSelectedFile(evt.target.files[0])
  }

  const handleDocumentUpload = async () => {
    if (!selectedFile) return Alert("Select a document for upload!", "info")

    // ensure only .csv files are uploaded
    if (selectedFile.type != "text/csv")
      return Alert("Please select a (.csv) document!", "error", 5000)

    const formData = new FormData()
    formData.append("file", selectedFile)

    try {
      const { preview_url: key, etag } = await uploadDocument({
        file_name: selectedFile.name,
        file: formData,
        mimeType: selectedFile.type,
      })

      // trigger document processing
      setActivity(prev => ({ ...prev, isStartingDocumentProcessing: true }))
      const response = await trigerDocumentProcessing(key, etag)

      setShowUploadModal(false)
      Alert(response.message, "success", 5000)

      setActivity(prev => ({ ...prev, isStartingDocumentProcessing: false }))
    } catch (error) {
      setActivity(prev => ({ ...prev, isStartingDocumentProcessing: false }))
      ErrorHandler.parser(error)
    }
  }

  return (
    <>
      <section className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Uploaded Documents")}
              //   childLink={props.t("Uploaded Documents")}
              //   thirdLink={props.t(params?.lga)}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={5}>
                  <div className="mb-4 h4 card-title">
                    All Uploaded Documents -{" "}
                    {uploadedDocs?.pageOptions.itemCount}
                  </div>
                </Col>

                <Col md={7} className="mb-2">
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col md={5}>
                      <Button
                        color="primary"
                        onClick={() => setShowUploadModal(true)}
                      >
                        Upload Beneficiaries File
                      </Button>
                    </Col>
                    <Col md={5}>
                      <div className="search-box me-xxl-4 my-3 my-xxl-0 d-inline-block d-flex justify-content-end">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={({ target }) =>
                                setSearchFilter(target.value)
                              }
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${uploadedDocs?.pageOptions.itemCount} records...`}
                              value={searchFilter}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {isLoadingUploadedDocs ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : uploadedDocs?.data.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-end">
                    <PaginationComponent
                      maxPages={uploadedDocs?.pageOptions.pageCount}
                      getCurrentPage={setCurrentIndex}
                      page={uploadedDocs?.pageOptions.pageCount}
                    />
                  </div>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </section>

      {/* Upload document modal */}
      <Modal size="lg" isOpen={showUploadModal} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5>Upload Documents</h5>

                <div onClick={() => setShowUploadModal(false)}>
                  <i
                    className="bx bx-x"
                    style={{ fontSize: 24, cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </Col>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <label
                htmlFor="document-upload"
                style={{ borderStyle: "dashed" }}
                className="p-3 w-100"
              >
                <div className="d-flex justify-content-center">
                  <FileUp size={34} />
                </div>
                <p className="text-center">
                  {selectedFile
                    ? selectedFile.name
                    : "Click to upload csv file"}
                </p>
                <input
                  id="document-upload"
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                />
              </label>
              <div className="d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => handleDocumentUpload()}
                  disabled={
                    isUploading || activity.isStartingDocumentProcessing
                  }
                  className="w-25"
                >
                  {isUploading || activity.isStartingDocumentProcessing ? (
                    <ActivityLoader />
                  ) : (
                    "Upload Document"
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default withTranslation()(index)

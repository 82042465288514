import React from "react"
import { RotatingLines } from "react-loader-spinner"

const ActivityLoader = ({
  variant = "secondary",
  size = "18",
  visible = true,
}) => (
    <RotatingLines
      strokeColor={variant == "secondary" ? "#ffffff" : "#0e7706"}
      strokeWidth="5"
      animationDuration="0.75"
      width={size}
      visible={visible}
    />
)

export default ActivityLoader

import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GENERAL } from "../../../api/general"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { data } from "common/data/states"
import errorImg from "assets/images/error-img.png"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"

const SuperAdminBeneficiaryByLga = props => {
  const params = useParams()
  const [isErr, setIsErr] = useState(false)
  const [records, setRecords] = useState([])

  useEffect(() => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    getBeneficiariesByLga
  }, [])

  const getBeneficiariesByLga = useQuery({
    queryKey: ["getBeneficiariesByLga"],
    queryFn: () => {
      return GENERAL.getBeneficiariesByLga(params?.state.toLowerCase())
    },
    onSuccess: async res => {
      Swal.close()
      console.log("getBeneficiariesByLga ::", res)

      // if (res.status === 200) {
      setIsErr(false)
      setRecords(res?.sort((a, b) => a.name.localeCompare(b.name)))
      // }
      if (res.status === 500) {
        setIsErr(true)
      }
    },
  })
  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }
  //meta title
  document.title = "Agents Registration - eRecruitement"
  return (
    <React.Fragment>
      {!isErr && (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Row>
              <Breadcrumbs
                title={props.t("Approved Agents Registration")}
                breadcrumbItem={props.t("Approved Agents Registration By LGA")}
                hasBackBtn={true}
              />
            </Row>

            <Row>
              {records?.map((item, key) => (
                <Col key={"_col_" + key} md="4" lg="3">
                  <Card className="mini-stats-wid">
                    <CardBody
                      style={{
                        paddingTop: 40,
                        paddingBottom: 40,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <div className="text-center mb-4">
                        <img
                          src={getItemByName(
                            data,
                            String(params?.state).toLowerCase()
                          )}
                          width={100}
                          height={100}
                          style={{ textAlign: "center" }}
                        />

                        <div className="row justify-content-center mt-3">
                          <div className="col-xl-10">
                            <h4 className="text-primary">{item.name}</h4>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col md="5">
                          <Row>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              Threshold
                            </h5>
                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                                background: "#bcebca",
                                padding: "10px",
                                marginLeft: "20%",
                                width: "70%",
                                borderRadius: "10px",
                              }}
                            >
                              {/* {item?.threshold.toLocaleString()} */}
                              1,200
                            </h6>
                          </Row>
                        </Col>
                        <Col md="7">
                          <Row>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              Beneficiaries
                            </h5>

                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                                background: "#bcebca",
                                padding: "10px",
                                marginLeft: "20%",
                                width: "70%",
                                borderRadius: "10px",
                              }}
                            >
                              {item?.registeredBeneficiaries.toLocaleString()}
                            </h6>
                          </Row>
                        </Col>
                      </Row>
                      <hr />

                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            <Link
                              to={`/view-beneficiary-by-lga/${item.id}/lga/${item.name}`}
                            >
                              View Beneficiaries
                            </Link>
                          </h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      )}

      {isErr && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={errorImg} width={500} />
          <p style={{ marginTop: 5, fontSize: 18 }}>
            Error fetching records...
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default withTranslation()(SuperAdminBeneficiaryByLga)

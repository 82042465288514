import { toast } from "sonner";

const Alert = (message, type, duration = 5000) => {
  const types = {
    success: () => toast.success(message, { duration }),
    warning: () => toast.warning(message, { duration }),
    info: () => toast.info(message, { duration }),
    error: () => toast.error(message, { duration }),
  };

  return types[type]();
};

export default Alert;

import React, { Fragment, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { API_RECORD_LIST_SIZE, GLOBAL_VARIABLES } from "helpers/global_variables.helper";
import useDebounce from "helpers/hooks/useDebounce";
import { generateYears } from "helpers/methods.helper";
import { filterMonthOptions } from "helpers/options.helper";
import { GENERAL } from "../../../api/general";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const SupervisorAgentPayment = props => {
  const navigate = useNavigate()

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const userData = JSON.parse(sessionStorage.getItem("me"))

  const [page, setPage] = useState(1)
  const [data, setRecords] = useState([
    {
      id: 1,
      fullname: "John Smith",
      state: "Adamawa",
      lga: "Demsa",
      total_registration: "456",
      total_verification: "56",
      total_unverified: "34",
      payment_qualification: "90%",
      bank: "First Bank",
      bankNumber: "12345678900",
      created_at: "2015-09-12",
      status: "Pending",
    },
  ])

  const [filter, setFilter] = React.useState({
    status: "",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [currentIndex, setCurrentIndex] = useState(1)
  const [searchFilter, setSearchFilter] = useState("")

  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const { data: agentsData, isLoading: isLoadingAgents } = useQuery({
    queryKey: ["getAgentsByRole", { searchQuery }],
    queryFn: () =>
      GENERAL.getUsersByRole({
        search: searchQuery,
        take: API_RECORD_LIST_SIZE,
        // state: userData.stateOfOrigin,
        // lga: userData.lgaOfOrigin,
        role: "agent"
      }),
  })

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "fullname",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FormatBold {...cellProps} />
        },
      },
      {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA",
        accessor: "lga",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Total Registration",
        accessor: "total_registration",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Total Verification",
        accessor: "total_verification",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Total Unverified",
        accessor: "total_unverified",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Payment Qualification",
        accessor: "payment_qualification",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Bank Name",
        accessor: "bank",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Account Number",
        accessor: "bankNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StatusFormat {...cellProps} />
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: agentsData?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: API_RECORD_LIST_SIZE,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ?? cell.value}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ?? cell.value
  }

  const StatusFormat = cell => {
    return (
      <div
        style={{
          background:
            String(cell?.value).toLowerCase() === "pending"
              ? "#E8A45F"
              : "#027A18",
          color: "white",
          display: "flex",
          justifyContent: "center",
          padding: "5px 0px",
          borderRadius: "15px",
        }}
      >
        {cell.value ?? cell.value}
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink="Payment"
              childLink="Beneficiary"
              thirdLink="State"
              hasBackBtn={true}
            />
          </Row>

          <Row>
            <Col md="3">
              <Card
                className="large-stats-wid"
                style={{
                  height: "180px",
                  background:
                    "transparent linear-gradient(144deg, #4CA8F8 0%, #8AC3F3 46%, #4CA8F8 100%) 0% 0% no-repeat padding-box",
                  boxShadow: "0px 8px 14px #00000029",
                  borderRadius: "11px",
                }}
              >
                <CardBody>
                  <Link>
                    <div
                      className="d-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        className="avatar-sm rounded-circle bg-primary mini-stat-icon"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                      >
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            className={"bx bx-copy-alt font-size-24"}
                            style={{ color: "#4ca8f8" }}
                          ></i>
                        </span>
                      </div>

                      <div className="flex-grow-1">
                        <h3 className="mb-0 text-white">123,345</h3>
                        <p
                          className="text-white fw-medium"
                          style={{ fontSize: 16 }}
                        >
                          All Agents
                        </p>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={4}>
                  <div className="h4 card-title">
                    All Agents - {agentsData?.total ?? 0}
                  </div>
                </Col>
                <Col md={8}>
                  <Row>
                    <Col md={4}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${
                                agentsData?.total ?? 0
                              } records...`}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col
                          md={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ padding: 0, margin: 0 }}>Year</label>
                        </Col>
                        <Col md={10}>
                          <select
                            onChange={({ target }) =>
                              setFilter(prev => ({
                                ...prev,
                                year: target.value,
                              }))
                            }
                            className="form-select"
                            value={filter.year}
                          >
                            {generateYears(GLOBAL_VARIABLES.starting_year)?.map(
                              item => (
                                <option key={item.value} value={item.value}>
                                  {item.name}
                                </option>
                              )
                            )}
                          </select>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={4}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col
                          md={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ padding: 0, margin: 0 }}>Month</label>
                        </Col>
                        <Col md={10}>
                          <select
                            onChange={({ target }) =>
                              setFilter(prev => ({
                                ...prev,
                                month: target.value,
                              }))
                            }
                            className="form-select"
                            value={filter.month}
                          >
                            {filterMonthOptions?.map(item => (
                              <option key={item.value} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Fragment>
                <Row className="mb-2"></Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SupervisorAgentPayment

import React, { Fragment, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  Modal,
  ModalBody,
  Form,
  FormFeedback,
  Label,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { GENERAL } from "../../../api/general"
import { useQuery, useMutation } from "react-query"
import errorImg from "assets/images/error-img.png"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Swal from "sweetalert2"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//i18n
import { withTranslation } from "react-i18next"
// import ViewApprovedAgentsByLga from "pages/Agents/ViewApprovedAgentsByLga"

const EligibilitySetup = props => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isErr, setIsErr] = useState(false)
  const [records, setRecords] = useState([
    {
      id: 31,
      title: "BVN Eligibility Setup",
      qualification_rule:
        "Beneficiary must not have up to #15,000 in their bank account within the last six(6) months",
      failure_rule:
        "Beneficiary had up to #15,000 in their bank account within the last six(6) months",
    },
  ])

  useEffect(() => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    getEligibiltyRecords
  }, [])

  const getEligibiltyRecords = useQuery({
    queryKey: ["getEligibiltyRecords"],
    queryFn: () => {
      return GENERAL.getEligibiltyRecords()
    },
    onSuccess: async res => {
      Swal.close()
      console.log("getEligibiltyRecords response ::", res.data)
      setRecords(res.data)
    },
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: "",
      title: "",
      qualification_rule: "",
      failure_rule: "",
      amount: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      qualification_rule: Yup.string().required(
        "Qualification rule is required"
      ),
      failure_rule: Yup.string().required("Failure rule is required"),
      amount: Yup.string().required("Amount is required"),
    }),
    onSubmit: values => {
      console.log("calling signin mutation...")
      console.log("is isUpdate :: ", isUpdate)
      if (!isUpdate) {
        Swal.fire({
          title: "Creating Eligibility Setup...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })
        const creatEligibilityDto = {
          title: values.title,
          amount: values.amount,
          failureRule: values.failure_rule,
          qualificationRule: values.qualification_rule,
        }
        console.log("creatEligibilityDto :: ", creatEligibilityDto)

        createEligibilityFn.mutate(creatEligibilityDto)
      }
      if (isUpdate) {
        Swal.fire({
          title: "Updating Eligibility Setup...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })
        const creatEligibilityDto = {
          id: values.id,
          title: values.title,
          amount: values.amount,
          failureRule: values.failure_rule,
          qualificationRule: values.qualification_rule,
        }
        console.log("creatEligibilityDto :: ", creatEligibilityDto)

        updateEligibilityFn.mutate(creatEligibilityDto)
      }
    },
  })

  const createEligibilityFn = useMutation({
    mutationFn: payload => {
      return GENERAL.createEligibilityRecord(payload)
    },
    onSuccess: async response => {
      Swal.close()
      console.log("create eligibility response :: ", response)
      if (response.status === 201) {
        getEligibiltyRecords
        Swal.fire(
          "Success",
          "Eligibility created successfully.",
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            validation.setValues({
              id: "",
              qualification_rule: "",
              failure_rule: "",
              title: "",
              amount: "",
            })
            getEligibiltyRecords.refetch()
            setShow(false)
          }
        })
      }
      if (response.status === 400) {
        Swal.fire("Success", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.fire("Success", response?.data?.message.join(", "), "error")
      }
      if (response.status === 401) {
        Swal.fire("Success", "Unauthorized!", "error")
      }
    },
  })

  const updateEligibilityFn = useMutation({
    mutationFn: payload => {
      return GENERAL.updateEligibilityRecord(payload)
    },
    onSuccess: async response => {
      console.log("update eligibility response :: ", response)
      Swal.close()
      if (response.status === 200) {
        Swal.fire(
          "Success",
          "Eligibility updated successfully.",
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            validation.setValues({
              id: "",
              qualification_rule: "",
              failure_rule: "",
              title: "",
              amount: "",
            })
            getEligibiltyRecords.refetch()
            setShow(false)
          }
        })
      }
      if (response.status === 400) {
        Swal.fire("Success", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.fire("Success", response?.data?.message.join(", "), "error")
      }
      if (response.status === 401) {
        Swal.fire("Success", "Unauthorized!", "error")
      }
    },
  })

  const gotoLink = () => {
    navigate("/program-setup")
  }

  const showModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const updateRecord = record => {
    console.log("current record ::", record)
    let { qualification_rule, failure_rule, title, amount, id } = record
    validation.setValues({
      id: id,
      qualification_rule: qualification_rule,
      failure_rule: failure_rule,
      title: title,
      amount: amount,
    })
    setIsUpdate(true)
    setShow(true)
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const handleToggleActivate = evt => {
    Swal.fire({
      title: `Are you sure you want to ${
        evt.eligibility_status ? "deactivate" : "activate"
      } this eligibility setup?`,
      icon: "question",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (evt.eligibility_status) {
          Swal.fire({
            title: "Deactivating Eligibility Setup...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(Swal.getDenyButton())
            },
          })

          deactivateEligibilityFn.mutate(evt.id)
        }
        if (!evt.eligibility_status) {
          Swal.fire({
            title: "Activating Eligibility Setup...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(Swal.getDenyButton())
            },
          })
          activateEligibilityFn.mutate(evt.id)
        }
      } else if (result.isDenied) {
        Swal.fire("Process cancelled", "", "info")
      }
    })
  }

  const deactivateEligibilityFn = useMutation({
    mutationFn: payload => {
      return GENERAL.deactivateEligibility(payload)
    },
    onSuccess: async response => {
      Swal.close()
      console.log("update eligibility response :: ", response)
      if (response.status === 200) {
        Swal.fire(
          "Process Successful",
          "Successfully deactivated setup",
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            getEligibiltyRecords.refetch()
          }
        })
      }
      if (response.status === 400) {
        Swal.fire("Success", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.fire("Success", response?.data?.message.join(", "), "error")
      }
      if (response.status === 401) {
        Swal.fire("Success", "Unauthorized!", "error")
      }
    },
  })

  const activateEligibilityFn = useMutation({
    mutationFn: payload => {
      return GENERAL.activateEligibility(payload)
    },
    onSuccess: async response => {
      console.log("update eligibility response :: ", response)
      Swal.close()
      if (response.status === 200) {
        Swal.fire(
          "Process Successful",
          "Successfully activated setup",
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            getEligibiltyRecords.refetch()
          }
        })
      }
      if (response.status === 400) {
        Swal.fire("Success", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.fire("Success", response?.data?.message.join(", "), "error")
      }
      if (response.status === 401) {
        Swal.fire("Success", "Unauthorized!", "error")
      }
    },
  })

  return (
    <React.Fragment>
      <Modal size="md" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5>{isUpdate ? "Update" : "Create"} Eligibility</h5>

                <div onClick={closeModal}>
                  <i
                    className="bx bx-x"
                    style={{ fontSize: 24, cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </Col>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-email-Input">
                      Eligibility Title <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="title"
                        className="form-control"
                        placeholder="Enter title"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-qualification_rule-Input">
                      Qualification Rule <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="qualification_rule"
                        className="form-control"
                        placeholder="Enter qualification rule"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.qualification_rule || ""}
                        invalid={
                          validation.touched.qualification_rule &&
                          validation.errors.qualification_rule
                            ? true
                            : false
                        }
                      />
                      {validation.touched.qualification_rule &&
                      validation.errors.qualification_rule ? (
                        <FormFeedback type="invalid">
                          {validation.errors.qualification_rule}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-failure_rule-Input">
                      Failure Rule <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="failure_rule"
                        className="form-control"
                        placeholder="Enter failure rule"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.failure_rule || ""}
                        invalid={
                          validation.touched.failure_rule &&
                          validation.errors.failure_rule
                            ? true
                            : false
                        }
                      />
                      {validation.touched.failure_rule &&
                      validation.errors.failure_rule ? (
                        <FormFeedback type="invalid">
                          {validation.errors.failure_rule}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-amount-Input">
                      Amount <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="amount"
                        className="form-control"
                        placeholder="Enter amount"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.amount || ""}
                        invalid={
                          validation.touched.amount && validation.errors.amount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.amount && validation.errors.amount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.amount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="text-center">
                  <button
                    style={{ marginRight: 10 }}
                    className="btn btn-danger w-80 mr-2"
                    onClick={() => closeModal()}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="btn btn-primary w-80 ml-5">
                    SUBMIT
                  </button>
                </div>
              </Row>
            </Form>
          </Row>
          {/* <Row>
            <div className="text-center">
              <button
                style={{ marginRight: 10 }}
                className="btn btn-danger w-80 mr-2"
                onClick={() => closeModal()}
              >
                CANCEL
              </button>
              <button type="submit" className="btn btn-primary w-80 ml-5">
                SUBMIT
              </button>
            </div>
          </Row> */}
        </ModalBody>
      </Modal>

      {!isErr && (
        <Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                parentLink={props.t("Agents")}
                childLink={props.t("Configuration")}
                thirdLink={props.t("Eligibility Setup")}
                hasBackBtn={true}
              />

              <Row>
                <div
                  className="text-right"
                  style={{ textAlign: "right", marginBottom: "20px" }}
                >
                  <Button
                    type="button"
                    color="success"
                    className="btn-md btn-rounded"
                    style={{ marginRight: 15 }}
                    onClick={() => showModal()}
                  >
                    New Setup
                  </Button>

                  <Button
                    type="button"
                    color="info"
                    className="btn-md btn-rounded"
                    onClick={() => {
                      gotoLink()
                    }}
                  >
                    Programme Setup
                  </Button>
                </div>
                {records?.map((item, key) => (
                  <Col key={"_col_" + key} md="3">
                    <Card className="mini-stats-wid">
                      <CardBody
                        style={{
                          paddingTop: 40,
                          paddingBottom: 40,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <CardTitle>{item.title}</CardTitle>
                        <div className="mb-4">
                          <div
                            className="row pt-3 pb-3"
                            style={{ background: "green" }}
                          >
                            <div className="col-xl-10">
                              <h5 className="text-white" style={{ margin: 0 }}>
                                Setup Description
                              </h5>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="row mt-3">
                          <div className="col-xl-10">
                            <h5 className="text-black">Qualification Rule</h5>
                            <p className="text-black">
                              {item.qualification_rule}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-xl-10">
                            <h5 className="text-black">Failure Rule</h5>
                            <p className="text-black">{item.failure_rule}</p>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-xl-10">
                            <Button
                              type="button"
                              color="info"
                              className="btn-md btn-rounded"
                              onClick={() => {
                                updateRecord(item)
                              }}
                            >
                              Update
                            </Button>

                            <Button
                              type="button"
                              color={`${
                                item?.eligibility_status ? "danger" : "primary"
                              }`}
                              className="btn-md btn-rounded"
                              style={{ marginLeft: 9 }}
                              onClick={() => handleToggleActivate(item)}
                            >
                              {item?.eligibility_status
                                ? "Deactivate"
                                : "Activate"}
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </Fragment>
      )}

      {isErr && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={errorImg} width={500} />
          <p style={{ marginTop: 5, fontSize: 18 }}>
            Error fetching records...
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default withTranslation()(EligibilitySetup)

import React, { useState } from "react"

//i18n
import { withTranslation } from "react-i18next"
import SuperAdminAgentPayment from "./SuperAdminAgentPayment"
import SupervisorAgentPayment from "./SupervisorAgentPayment"

const AgentPayments = props => {
  const [userRole, setUserRole] = useState(
    JSON.parse(sessionStorage.getItem("userRole"))
  )

  //meta title
  document.title = "Agents Payment | Beneficiary Cash Transfer Dashboard"
  return (
    <React.Fragment>
      {userRole === "SUPER_ADMIN" ? (
        <SuperAdminAgentPayment />
      ) : (
        <SupervisorAgentPayment />
      )}
    </React.Fragment>
  )
}

export default withTranslation()(AgentPayments)

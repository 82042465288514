import React, { useMemo, Fragment, useState } from "react"
import { Container, Col, Row, Button, Card, CardBody, Table } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useQuery } from "react-query"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { useParams } from "react-router"
import { GENERAL } from "../../../api/general"

const SupervisorBeneficiaries = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [apiPageSize, setApiPageSize] = useState(10)
  const [data, setRecords] = useState([
    {
      id: 1,
      first_name: "John",
      middle_name: "Doe",
      surname: "Smith",
      beneficiary_id: "BN-12345678900",
      tracking_id: "123456789",
      phone: "12345678900",
      status: "active",
      wallet_status: "Created",
      state: "Abuja",
      lga: "FCT",
      category: "Under Privilege",
      adress: "this is the test address",
      created_at: "2015-09-12",
    },
  ])

  const count = 0
  const [filterOption, setFilterOption] = React.useState("")
  const [value, setValue] = React.useState("")
  const [filter, setFiilter] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const handleSearchFn = () => {
    console.log("this is the search keyword :: ", filter, value)
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const pageDto = {
      searchKey: filter,
      list_id: userInfo?.lga,
      page: 1,
      pageSize: 20,
    }
    // getRecordsByLgaId.mutate(pageDto)
  }

  // const getBeneficiariesByStateId = useQuery({
  //   queryKey: ["getBeneficiariesByStateId"],
  //   queryFn: () => {
  //     return GENERAL.getBeneficiariesByStateId({
  //       state: params?.id,
  //       page: page,
  //       pageSize: apiPageSize,
  //     })
  //   },
  //   onSuccess: async res => {
  //     console.log("getBeneficiariesByStateId :: ", res.data)
  //     setRecords(res.data)
  //   },
  // })
  //meta title
  document.title = "Agents Registration - eRecruitement"
  const customPageSize = 6

  const gotoPreviousPage = () => {
    console.log("fetching next page...")
    setPage(page - 1)
  }
  const gotoNextPage = () => {
    console.log("fetching next page...")
    setPage(page + 1)
  }

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "fullname",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiary_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Tracking ID",
        accessor: "tracking_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Phone No.",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Wallet Status",
        accessor: "wallet_status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "adress",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Category",
        accessor: "category",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                gotoProfile()
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const gotoProfile = () => {
    navigate("/profile")
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const FullnameFormat = record => {
    console.log("this is the cell record :: ", record?.cell?.row?.original)
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.first_name ?? ""} ${rowRecord?.middle_name ?? ""} ${
      rowRecord?.surname ?? ""
    }`
  }

  const filterOptions = [
    { id: 1, name: "All Beneficiaries" },
    { id: 2, name: "Beneficiaries With NIN" },
    { id: 3, name: "Beneficiaries Without NIN" },
    { id: 4, name: "Ineligible Applicants" },
  ]

  const handleFilterOption = () => {}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink="Beneficiaries"
              childLink="State"
              thirdLink="All Benediciaries"
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={5}>
                  <div className="mb-4 h4 card-title">
                    All Beneficiaries - {data?.length}
                  </div>
                </Col>

                <Col md={7} className="mb-2">
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col md={5}>
                      <div className="search-box me-xxl-4 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => {
                                setValue(e.target.value)
                                onChange(e.target.value)
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${count} records...`}
                              value={value || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={7}>
                      <select
                        onChange={handleFilterOption}
                        className="form-select"
                        value={filterOption}
                      >
                        {/* <option key="0" value="0">
                        All States
                      </option> */}
                        {filterOptions?.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </Col>
              </div>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={gotoPreviousPage}
                        disabled={page == 1}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page <strong>{page}</strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button color="primary" onClick={gotoNextPage}>
                        {">"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SupervisorBeneficiaries

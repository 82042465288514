import React, { useEffect, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"
import SuperAdminViewVerification from "./SuperAdminViewVerification"
import SupervisorViewVerification from "./SupervisorViewVerification"

const ViewVerifications = () => {
  const [userRole, setUserRole] = useState(
    JSON.parse(sessionStorage.getItem("userRole"))
  )
  //meta title
  document.title = "Verifications | Beneficiary Cash Transfer Dashboard"

  return (
    <React.Fragment>
      {userRole === "SUPER_ADMIN" ? (
        <SuperAdminViewVerification />
      ) : (
        <SupervisorViewVerification />
      )}
    </React.Fragment>
  )
}

export default withTranslation()(ViewVerifications)

import { STATISTICS } from "api/statistics"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useMutation } from "react-query"
import { Card, CardBody, CardTitle } from "reactstrap"

const PaymentStatisticsByMonth = () => {
    const year = new Date().getFullYear()

  const monthsData = [
    {
      id: 1,
      month: "JANUARY",
      count: 0,
    },
    {
      id: 2,
      month: "FEBRUARY",
      count: 0,
    },
    {
      id: 3,
      month: "MARCH",
      count: 0,
    },
    {
      id: 4,
      month: "APRIL",
      count: 0,
    },
    {
      id: 5,
      month: "MAY",
      count: 0,
    },
    {
      id: 6,
      month: "JUNE",
      count: 0,
    },
    {
      id: 7,
      month: "JULY",
      count: 0,
    },
    {
      id: 8,
      month: "AUGUST",
      count: 0,
    },
    {
      id: 9,
      month: "SEPTEMBER",
      count: 0,
    },
    {
      id: 10,
      month: "OCTOBER",
      count: 0,
    },
    {
      id: 11,
      month: "NOVEMBER",
      count: 0,
    },
    {
      id: 12,
      month: "DECEMBER",
      count: 0,
    },
  ]
  const [dataSeries, setDataSeries] = useState([])
  const [dataLabel, setDataLabel] = useState([])
  useEffect(() => {
    getTotalPaymentsByMonth.mutate(year)
  }, [])

  const getTotalPaymentsByMonth = useMutation({
    mutationFn: payload => {
      return STATISTICS.getTotalPaymentsByMonth(payload)
    },

    onSuccess: async res => {

      // Create a mapping object from array A with lowercase names
      const aMapping = res.stats.reduce((acc, item) => {
        acc[item?.name?.toLowerCase()] = item
        return acc
      }, {})

      // Create a new array by replacing occurrences from array B with items from array A
      const newArray = monthsData.map(itemB => {
        const matchedItem = aMapping[itemB.month.toLowerCase()]
        return matchedItem ? { ...itemB, ...matchedItem } : itemB
      })

      const series = []
      const labels = []
      newArray.forEach((item) => {
        labels.push(item.month)
        series.push(item.count)
      })

      setDataSeries(series)
      setDataLabel(labels)
    },
  })

  const state3 = {
    series: [
      {
        data: dataSeries,
      },
    ],
    options: {
      colors: "#62e5e5",
      chart: {
        type: "bar",
        height: 700,
        width: 10,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: 15,
          borderRadius: 7,
          horizontal: false,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: "#62e5e5",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: dataLabel,
      },
    },
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>Payment statistics by Months:</CardTitle>
        <div style={{ height: 700 }}>
          <div id="chart">
            <ReactApexChart
              options={state3.options}
              series={state3.series}
              type="bar"
              height={700}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default PaymentStatisticsByMonth

import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useParams } from "react-router"
import { GENERAL } from "../../../api/general"
import Swal from "sweetalert2"
import useDebounce from "helpers/hooks/useDebounce"
import {
  API_LIST_ORDER,
  API_RECORD_LIST_SIZE,
} from "helpers/global_variables.helper"
import ActivityLoader from "components/activity-loader"
import PaginationComponent from "components/Common/pagination"
import { findBankName, numberToMonth } from "helpers/methods.helper"

const ViewBeneficiaryPaymentsByLga = props => {
  const params = useParams()
  const [currentIndex, setCurrentIndex] = useState(1)
  const [apiPageSize, setApiPageSize] = useState(10)
  const [data, setRecords] = useState([])
  const [searchFilter, setSearchFilter] = React.useState("")
  const onChange = value => {
    setSearchFilter(value)
  }

  const { data: bankList } = useQuery({
    queryKey: ["getBanks"],
    queryFn: () => {
      return GENERAL.getBanks()
    },
  })

  useEffect(() => {
    setCurrentIndex(1)
  }, [searchFilter, params])

  const { value: searchQuery } = useDebounce(searchFilter, 1000)
  const { data: getVerificationsByLga, isLoading } = useQuery({
    queryKey: [
      "get_verifications_by_lga",
      {
        page: currentIndex,
        search: searchQuery,
        lga: params.id,
        month: params?.month,
        year: params?.year,
      },
    ],
    queryFn: () => {
      return GENERAL.getVerifications({
        lga: params?.lga,
        pageSize: apiPageSize,
        month: params?.month,
        year: params?.year,
        order: API_LIST_ORDER.desc,
        search: searchQuery,
        page: currentIndex,
        take: API_RECORD_LIST_SIZE,
      })
    },
    onSuccess: async res => {
      setRecords(res?.data)
    },
  })

  const NINFormat = cell => {
    const NIN = cell.cell.row.original.beneficiary
      ? cell.cell.row.original.beneficiary.nin
      : ""
    const TrackingId = cell.cell.row.original.beneficiary
      ? cell.cell.row.original?.beneficiary.beneficiaryNin?.trackingId
      : ""

    if (NIN) {
      return <p>{NIN}</p>
    } else {
      return <p>{TrackingId}</p>
    }
  }
  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return getVerificationsByLga?.records?.map((_, index) => index + offset + 1)
  }, [getVerificationsByLga, currentIndex, API_RECORD_LIST_SIZE])

  const FormatVerificationStatus = cell => {
    return cell.value ? "Verified" : "Not Verified"
  }
  //meta title
  document.title = "Agents Registration - eRecruitement"
  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        Cell: cell => {
          return <p>{getSns[cell?.row?.index]}</p>
        },
      },
      {
        Header: "Full Name",
        Cell: cellProps => {
          const firstName = cellProps.row.original.beneficiary.firstName
          const middleName = cellProps.row.original.beneficiary.middleName
          const surname = cellProps.row.original.beneficiary.surname
          return `${firstName + " " ?? ""}${middleName + " " ?? ""}${
            surname + " " ?? ""
          } `
        },
      },
      {
        Header: "NIN/Tracking ID",
        Cell: cellProps => {
          return <NINFormat {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "beneficiary.phoneNumber",
      },
      {
        Header: "State of Residence",
        accessor: "beneficiary.stateOfOrigin",
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "beneficiary.lgaOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Bank",
        accessor: "beneficiary.bankCode",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const bankCode = cellProps.value
          return <>{findBankName(bankCode, bankList)}</>
        },
      },
      {
        Header: "Account Number",
        accessor: "beneficiary.accountNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Month",
        accessor: "month",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <>{numberToMonth(cellProps.value)}</>
        },
      },
      {
        Header: "Year",
        accessor: "year",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Payment Status",
        accessor: "paymentDisbursements[0].status",
        Cell: cell => {
          return <p>{cell.value ? cell.value : "----"}</p>
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                makePaymentFn(cellProps)
              }}
            >
              Pay
            </Button>
          )
        },
      },
    ],
    [data, currentIndex, bankList]
  )

  const makePaymentFn = evt => {
    const beneficiary = evt.cell.row.original

    Swal.fire({
      title: "Are you sure?",
      text: "This process cannot be reversed!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing Payment...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        const paymentDto = {
          beneficiaryId: beneficiary?.beneficiary?.id,
          month: params.month,
          year: params.year,
        }
        makePayment.mutate(paymentDto)
      }
    })
  }

  const makePayment = useMutation({
    mutationFn: payload => {
      return GENERAL.paySingleBeneficiary(payload)
    },
    onSuccess: async res => {
      Swal.close()
      if (res.status === 201) {
        return Swal.fire("Process Successful", res.data.message, "success")
      }
      if (res.status === 400) {
        return Swal.fire("Process Failed!", res.data.message, "error")
      }
    },
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: getVerificationsByLga?.records ?? [],
      initialState: {
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    })

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const payAllFn = evt => {
    Swal.fire({
      title: "Are you sure?",
      text: "This process cannot be reversed!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing Payment...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        const paymentDto = {
          lga: params.lga,
          month: params.month,
          year: params.year,
        }
        payAll.mutate(paymentDto)
      }
    })
  }

  const payAll = useMutation({
    mutationFn: payload => {
      return GENERAL.payAllLga(payload)
    },
    onSuccess: async res => {
      Swal.close()
      if (res.status === 201) {
        Swal.fire("Process Successful", res.data.message, "success")
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Payment")}
              childLink={props.t("Beneficiary")}
              thirdLink={props.t("LGA")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">All Beneficiaries</div>
              <Fragment>
                <Row className="mb-2">
                  {/* {isGlobalFilter && <GlobalFilter />} */}
                  <React.Fragment>
                    <Row>
                      <Col md={6}>
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div
                            className="position-relative"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              htmlFor="search-bar-0"
                              className="search-label"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                onChange={e => {
                                  onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`search`}
                                value={searchFilter || ""}
                              />
                            </label>
                            <i
                              className="bx bx-search-alt search-icon"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            className="btn mb-2 me-2"
                            onClick={payAllFn}
                          >
                            Pay All
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {isLoading ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : rows.length > 0 ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-end">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (getVerificationsByLga?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewBeneficiaryPaymentsByLga)

import React from "react"
import light_green_circle from "assets/images/circles/light_green_circle.svg"
import orange_circle from "assets/images/circles/orange_circle.svg"
import light_blue_circle from "assets/images/circles/light_blue_circle.svg"
import blue_circle from "assets/images/circles/blue_circle.svg"
import red_circle from "assets/images/circles/red_circle.svg"
import green_circle from "assets/images/circles/green_circle.svg"
import { withTranslation } from "react-i18next"
import {
  Card as ReactCard,
  CardBody,
  Col,
  Progress,
  Row,
  CardTitle,
} from "reactstrap"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"

const BeneficiariesByOccupation = () => {
  const { data } = useQuery({
    queryKey: ["get_beneficiary_by_category_stats"],
    queryFn: () => {
      return GENERAL.getBeneficiaryByCategory()
    },
  })

  const totalOfAllItems = data?.stats.reduce(
    (acc, item) => acc + parseInt(item.total),
    0
  )

  const imageSources = [
    light_green_circle,
    orange_circle,
    light_blue_circle,
    blue_circle,
    red_circle,
    green_circle,
  ]

  return (
    <ReactCard>
      <CardBody>
        <CardTitle>Beneficiaries Categories:</CardTitle>
        <Row style={{ marginTop: "50px" }}>
          {data?.stats.map((item, index) => {
            const randomImageSrc = imageSources[index]
            return (
              <Col key={index} xl={4}>
                <ReactCard>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <div className="w-100">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                          }}
                        >
                          <small className="text-capitalize">{item.name}</small>
                          <small>
                            {`${(
                              (parseInt(item.total) / totalOfAllItems) *
                              100
                            ).toFixed(2)}%`}
                          </small>
                        </div>
                        <div className="mb-4">
                          <Progress
                            striped
                            color="success"
                            value={
                              (parseInt(item.total) / totalOfAllItems) * 100
                            }
                          ></Progress>
                        </div>
                      </div>
                      <div className="w-2/12 flex justify-center items-center">
                        <img
                          src={randomImageSrc}
                          alt=""
                          style={{ width: 30 }}
                        />
                      </div>
                    </div>
                  </CardBody>
                </ReactCard>
              </Col>
            )
          })}
          
        </Row>
      </CardBody>
    </ReactCard>
  )
}

export default withTranslation()(BeneficiariesByOccupation)

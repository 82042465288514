import React, { Fragment, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { useTable } from "react-table"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
//Import Breadcrumb
import {
  API_RECORD_LIST_SIZE,
  GLOBAL_VARIABLES,
} from "helpers/global_variables.helper"
import { generateYears } from "helpers/methods.helper"
import { filterMonthOptions, filterStatusOptions } from "helpers/options.helper"
import { GENERAL } from "../../../api/general"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import useDebounce from "helpers/hooks/useDebounce"
import PaginationComponent from "components/Common/pagination"
import ActivityLoader from "components/activity-loader"

const SupervisorBeneficiaryPayment = props => {
  //meta title
  document.title = "Agents Registration - eRecruitement"

  const userData = JSON.parse(sessionStorage.getItem("me"))

  const [currentIndex, setCurrentIndex] = useState(1)
  const [filter, setFilter] = React.useState({
    status: "",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [searchFilter, setSearchFilter] = useState("")
  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const { data: verificationData, isLoading: isLoadingVerificationData } =
    useQuery({
      queryKey: [
        "recordsByStateId",
        { searchQuery, page: currentIndex, ...filter },
      ],
      queryFn: () => {
        return GENERAL.getBeneficiaryVerifications({
          state: userData.stateOfOrigin,
          lga: userData.lgaOfOrigin,
          take: API_RECORD_LIST_SIZE,
          search: searchQuery,
          page: currentIndex,
          month: filter.month,
          year: filter.year,
        })
      },
    })
  
  const {data: statsCount} = useQuery(
    [
      "beneficiary-payment-stats-count",
      { month: filter.month, year: filter.year },
    ],
    {
      queryFn: () =>
        GENERAL.getPaymentVerificationCount({
          state: userData.stateOfOrigin,
          lga: userData.lgaOfOrigin,
          month: filter.month,
          year: filter.year,
        }),
    }
  )

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "NIN",
        accessor: "beneficiary.nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Origin",
        accessor: "beneficiary.stateOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Origin",
        accessor: "beneficiary.lgaOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Bank",
        accessor: "beneficiary.bankCode",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          let banksList = JSON.parse(
            sessionStorage.getItem(GLOBAL_VARIABLES.banks_info)
          )

          let bankinfo = banksList?.find(
            bank =>
              bank.code == cellProps?.cell?.row?.original?.beneficiary.bankCode
          )
          return bankinfo?.name ?? "---"
        },
      },
      {
        Header: "Account Number",
        accessor: "beneficiary.accountNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "beneficiary.eligibilityStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StatusFormat {...cellProps} />
        },
      },
      // {
      //   Header: "Action",
      //   disableFilters: true,
      //   accessor: "view",
      //   Cell: cellProps => {
      //     return (
      //       <Button
      //         type="button"
      //         color="primary"
      //         className="btn-sm btn-rounded"
      //         onClick={() => {
      //           gotoProfile()
      //         }}
      //       >
      //         Pay
      //       </Button>
      //     )
      //   },
      // },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: verificationData?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: API_RECORD_LIST_SIZE,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ?? cell.value}
      </Link>
    )
  }
  const FullnameFormat = record => {
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.beneficiary.surname ?? ""} ${
      rowRecord?.beneficiary.firstName ?? ""
    } ${rowRecord?.beneficiary.middleNmae ?? ""}`
  }

  const FieldFormat = cell => {
    return cell.value ?? "---"
  }

  const StatusFormat = cell => {
    return (
      <div
        style={{
          background:
            String(cell?.value).toLowerCase() === "pending"
              ? "#E8A45F"
              : "#027A18",
          color: "white",
          display: "flex",
          justifyContent: "center",
          padding: "5px 0px",
          borderRadius: "15px",
        }}
      >
        {cell.value ?? cell.value}
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink="Payment"
              childLink="Beneficiary"
              thirdLink="State"
              fourthLink="Lga"
              hasBackBtn={true}
            />
          </Row>

          <Row>
            <Col md="3">
              <Card
                className="large-stats-wid"
                style={{
                  height: "180px",
                  background:
                    "transparent linear-gradient(144deg, #4CA8F8 0%, #8AC3F3 46%, #4CA8F8 100%) 0% 0% no-repeat padding-box",
                  boxShadow: "0px 8px 14px #00000029",
                  borderRadius: "11px",
                }}
              >
                <CardBody>
                  <Link>
                    <div
                      className="d-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        className="avatar-sm rounded-circle bg-primary mini-stat-icon"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                      >
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            className={"bx bx-copy-alt font-size-24"}
                            style={{ color: "#4ca8f8" }}
                          ></i>
                        </span>
                      </div>

                      <div className="flex-grow-1">
                        <h3 className="mb-0 text-white">
                          {statsCount?.totalVerified ?? 0}
                        </h3>
                        <p
                          className="text-white fw-medium"
                          style={{ fontSize: 16 }}
                        >
                          Verified Benediciaries
                        </p>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                className="large-stats-wid"
                style={{
                  height: "180px",
                  background:
                    "transparent linear-gradient(144deg, #fa9229 0%, #f9b26b 46%, #faa44f 100%) 0% 0% no-repeat padding-box",
                  boxShadow: "0px 8px 14px #00000029",
                  borderRadius: "11px",
                }}
              >
                <CardBody>
                  <Link>
                    <div
                      className="d-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        className="avatar-sm rounded-circle bg-white mini-stat-icon"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                      >
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            className={"bx bx-copy-alt font-size-24"}
                            style={{ color: "#fb8b1b" }}
                          ></i>
                        </span>
                      </div>

                      <div className="flex-grow-1">
                        <h3 className="mb-0 text-white">
                          {statsCount?.totalUnverified ?? 0}
                        </h3>
                        <p
                          className="text-white fw-medium"
                          style={{ fontSize: 16 }}
                        >
                          Unverified Benediciaries
                        </p>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={4}>
                  <div className="h4 card-title">
                    All Verified Beneficiaries - {verificationData?.total ?? 0}
                  </div>
                </Col>
                <Col md={8}>
                  <Row>
                    <Col md={3}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${
                                verificationData?.total ?? 0
                              } record(s)...`}
                              onChange={({ target }) =>
                                setSearchFilter(target.value)
                              }
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col md={12}>
                          <select
                            onChange={({ target }) =>
                              setFilter(prev => ({
                                ...prev,
                                status: target.value,
                              }))
                            }
                            className="form-select"
                            value={filter.status}
                          >
                            {filterStatusOptions?.map(item => (
                              <option key={item.value} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col md={12}>
                          <select
                            onChange={({ target }) =>
                              setFilter(prev => ({
                                ...prev,
                                year: target.value,
                              }))
                            }
                            className="form-select"
                            value={filter.year}
                          >
                            {generateYears(GLOBAL_VARIABLES.starting_year)?.map(
                              item => (
                                <option key={item.value} value={item.value}>
                                  {item.name}
                                </option>
                              )
                            )}
                          </select>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={3}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col md={12}>
                          <select
                            onChange={({ target }) =>
                              setFilter(prev => ({
                                ...prev,
                                month: target.value,
                              }))
                            }
                            className="form-select"
                            value={filter.month}
                          >
                            {filterMonthOptions?.map(item => (
                              <option key={item.value} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Fragment>
                <Row className="mb-2"></Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {isLoadingVerificationData ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : verificationData?.records.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-end">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (verificationData?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SupervisorBeneficiaryPayment

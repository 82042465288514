import React, { Fragment, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTable } from "react-table"
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap"
//Import Breadcrumb
import { GENERAL } from "api/general"
import PaginationComponent from "components/Common/pagination"
import ActivityLoader from "components/activity-loader"
import { API_RECORD_LIST_SIZE } from "helpers/global_variables.helper"
import useDebounce from "helpers/hooks/useDebounce"
import { useQuery } from "react-query"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const SupervisorAgentView = props => {
  //meta title
  document.title = "Agents Registration - eRecruitement"

  const userData = JSON.parse(sessionStorage.getItem("me"))

  const navigate = useNavigate()

  const [currentIndex, setCurrentIndex] = useState(1)
  const [searchFilter, setSearchFilter] = useState("")

  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const { data: agentsData, isLoading: isLoadingAgents } = useQuery({
    queryKey: ["getUsersByRole", { searchQuery }],
    queryFn: () =>
      GENERAL.getUsersByRole({
        search: searchQuery,
        take: API_RECORD_LIST_SIZE,
        state: userData.stateOfOrigin,
        lga: userData.lgaOfOrigin,
      }),
  })

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FormatBold {...cellProps} />
        },
      },
      {
        Header: "NIN",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const NIN = cellProps.cell.row.original
            ? cellProps.cell.row.original.nin
            : ""
          const TrackingId = cellProps.cell.row.original
            ? cellProps.cell.row.original?.beneficiaryNin?.trackingId
            : ""

          if (NIN) {
            return <p>{NIN}</p>
          } else {
            return <p>{TrackingId}</p>
          }
        },
      },
      {
        Header: "Phone No.",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Email Address.",
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Origin",
        accessor: "stateOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Origin",
        accessor: "lgaOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "adress",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Total Registrations",
        accessor: "totalRegistrations",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StatusFormat {...cellProps} />
        },
      },

      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="info"
              className="btn-sm btn-rounded"
              onClick={() => {
                gotoProfile(cellProps)
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const gotoProfile = record => {
    navigate(`/agents/${record?.cell?.row?.original.id}`)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: agentsData?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: API_RECORD_LIST_SIZE,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const StatusFormat = cell => {
    return (
      <div
        style={{
          background:
            String(cell?.value).toLowerCase() === "active"
              ? "#5fe891"
              : "#f61130",
          color: "white",
          display: "flex",
          justifyContent: "center",
          padding: "5px 0px",
          borderRadius: "15px",
        }}
      >
        {cell.value ?? cell.value}
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink="Agents"
              // childLink="All Verifications"
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={10}>
                  <div className="h4 card-title">
                    All Verifications - {agentsData?.total ?? 0}
                  </div>
                </Col>
                <Col md={2}>
                  <Row>
                    <Col md={12}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${agentsData?.total ?? 0} records...`}
                              value={searchFilter}
                              onChange={({ target }) =>
                                setSearchFilter(target.value)
                              }
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {isLoadingAgents ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : agentsData?.records.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-end">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (agentsData?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SupervisorAgentView

import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Alert,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import CarouselPage from "./CarouselPage"
//redux
import withRouter from "components/Common/withRouter"
import { useMutation } from "react-query"
import { Link, useNavigate } from "react-router-dom"

// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

// import images
import { setKey } from "helpers"
import { AUTH } from "../../api/auth"
import {
  default as logodark,
  default as logolight,
} from "../../assets/images/coat.png"
import ActivityLoader from "components/activity-loader"
import { default as CustomAlert } from "helpers/alert.helper"
import ErrorHandler from "helpers/error.helper"

const Login = () => {
  const [showPassword, setshowPassword] = useState(false)
  const [showLogin, setShowLogin] = useState(true)
  const [signInToken, setSignInToken] = useState(null)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const navigate = useNavigate()
  //meta title
  document.title = "Login | Palliative Admin Dashboard"

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      signIn.mutate(values)
    },
  })

  const verifyOtpForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("OTP is required"),
    }),
    onSubmit: values => {
      const otp = {
        otp: values.otp,
        token: signInToken,
      }
      verifyOtp.mutate(otp)
    },
  })

  const signIn = useMutation({
    mutationFn: payload => {
      return AUTH.userLogin(payload)
    },

    onSuccess: res => {
      if (res?.status === 201) {
        setSignInToken(res.data.token)
        setKey("signin_token", res.data.token)
        setShowErrorMessage(false)
        setShowLogin(false)
      }
    },
    onError: error => {
      ErrorHandler.parser(error)
    },
  })

  const verifyOtp = useMutation({
    mutationFn: payload => {
      return AUTH.verifyOtp(payload)
    },

    onSuccess: async res => {
      if (res?.data?.statusCode === 400) {
        setShowSuccessMessage(false)
        setShowErrorMessage(true)
        setErrorMessage(res?.data?.message)
        return false
      }

      if (res?.status === 201) {
        setShowErrorMessage(false)
        setSuccessMessage("Login successful!")
        setKey("accessToken", res.data.accessToken)
        setKey("profilePhoto", res.data.photo)
        sessionStorage.setItem("authUser", JSON.stringify(res?.data))
        sessionStorage.setItem("token", JSON.stringify(res?.data?.accessToken))
        sessionStorage.setItem(
          "userRole",
          JSON.stringify(res?.data?.role?.toUpperCase())
        )
        navigate("/dashboard")
      }
    },
    onError: error => {
      ErrorHandler.parser(error)
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto ">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="mb-4 mb-md-5"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Link to="/dashboard" className="d-block auth-logo">
                            <img
                              src={logodark}
                              alt=""
                              height="125"
                              className="logo-dark-element"
                            />
                            <img
                              src={logolight}
                              alt=""
                              height="125"
                              className="logo-light-element"
                            />
                          </Link>
                        </div>
                      </div>
                      <div>
                        <h4
                          className="text-black"
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          FEDERAL REPUBLIC OF NIGERIA
                        </h4>
                        <p
                          className="text-muted"
                          style={{ fontSize: "15px", textAlign: "center" }}
                        >
                          Palliative for Social Safety Net Management System
                        </p>
                      </div>

                      <div className="mt-4">
                        {showLogin && (
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            {showErrorMessage && (
                              <Alert color="danger">{errorMessage}</Alert>
                            )}
                            {showSuccessMessage && (
                              <Alert color="primary">{successMessage}</Alert>
                            )}

                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Password</Label>
                              <InputGroup>
                                <Input
                                  name="password"
                                  value={validation.values.password || ""}
                                  type={`${showPassword ? "text" : "password"}`}
                                  placeholder="Enter Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i
                                      onClick={() =>
                                        setshowPassword(!showPassword)
                                      }
                                      className={`mdi mdi-${
                                        !showPassword ? "eye-off" : "eye"
                                      }-outline`}
                                    />
                                  </span>
                                </div>
                              </InputGroup>
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={signIn.isLoading}
                              >
                                {signIn.isLoading ? (
                                  <ActivityLoader size="18" />
                                ) : (
                                  "Log In"
                                )}
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" />
                                Forgot your password?
                              </Link>
                            </div>
                          </Form>
                        )}

                        {!showLogin && (
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              verifyOtpForm.handleSubmit()
                              return false
                            }}
                          >
                            {showErrorMessage ? (
                              <Alert color="danger">{errorMessage}</Alert>
                            ) : null}
                            {showSuccessMessage ? (
                              <Alert color="primary">{successMessage}</Alert>
                            ) : null}

                            <div className="mb-3">
                              <Label className="form-label">OTP</Label>
                              <Input
                                name="otp"
                                className="form-control"
                                placeholder="Enter otp"
                                type="string"
                                maxLength={6}
                                onChange={verifyOtpForm.handleChange}
                                onBlur={verifyOtpForm.handleBlur}
                                value={verifyOtpForm.values.otp || ""}
                                invalid={
                                  verifyOtpForm.touched.otp &&
                                  verifyOtpForm.errors.otp
                                    ? true
                                    : false
                                }
                              />
                              {verifyOtpForm.touched.otp &&
                              verifyOtpForm.errors.otp ? (
                                <FormFeedback type="invalid">
                                  {verifyOtpForm.errors.otp}
                                </FormFeedback>
                              ) : null}
                              <div className="d-flex justify-content-end">
                                <p
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => {
                                    validation.handleSubmit()
                                    CustomAlert("OTP Resent", "success", 3000)
                                  }}
                                >
                                  Resend OTP
                                </p>
                              </div>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={verifyOtp.isLoading}
                              >
                                {verifyOtp.isLoading ? (
                                  <ActivityLoader size="18" />
                                ) : (
                                  "Validate OTP"
                                )}
                              </button>
                            </div>
                          </Form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}

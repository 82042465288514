import React, { useMemo, Fragment, useState } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
  Modal,
  ModalBody,
  Form,
  Label,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { GENERAL } from "../../../api/general"
import Swal from "sweetalert2"
import dayjs from "dayjs"
import PaginationComponent from "components/Common/pagination"
import {
  API_RECORD_LIST_SIZE,
  USER_STATUS,
} from "helpers/global_variables.helper"
import ActivityLoader from "components/activity-loader"
import { preview_base64 } from "helpers/methods.helper"
import Alert from "helpers/alert.helper"

const AgentProfile = props => {
  const params = useParams()
  const [currentIndex, setCurrentIndex] = useState(1)
  const queryClient = useQueryClient()
  const [searchFilter, setSearchFilter] = useState("")

  const navigate = useNavigate()
  //meta title
  document.title = "Agents Registration - eRecruitement"

  const { data: userProfile, isLoading } = useQuery({
    queryKey: ["getUserById", params?.id],
    queryFn: () => {
      return GENERAL.getUserById({
        id: params.id,
      })
    },
  })

  const { data: beneficiariesData, isLoading: isLoadingBeneficiaries } =
    useQuery({
      queryKey: [
        "beneficiariesByAgent",
        {
          userProfile: userProfile,
          currentIndex,
        },
      ],
      queryFn: () =>
        GENERAL.beneficiaryiesByAgent({
          agentId: userProfile?.id,
          page: currentIndex,
          take: API_RECORD_LIST_SIZE,
        }),
      enabled: !!userProfile?.id,
    })

  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return beneficiariesData?.records?.map((_, index) => index + offset + 1)
  }, [beneficiariesData, currentIndex, API_RECORD_LIST_SIZE])

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        Cell: cell => {
          return <p>{getSns[cell.row.index]}</p>
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiaryId",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "surname",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "Phone No.",
        accessor: "phoneNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "stateOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lgaOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                gotoProfile(cellProps)
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    [currentIndex, beneficiariesData, API_RECORD_LIST_SIZE]
  )

  const gotoProfile = record => {
    const beneficiaryId = record.cell.row.original.beneficiaryId
    navigate(`/beneficiary-profile/${beneficiaryId}`)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: beneficiariesData?.records ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: API_RECORD_LIST_SIZE,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    })

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const FullnameFormat = record => {
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.surname ?? ""} ${rowRecord?.firstName ?? ""} ${
      rowRecord?.middleNmae ?? ""
    }`
  }

  const [show, setShow] = useState(false)
  const [agentId, setAgentId] = useState(null)
  const showModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const handleSelectAgent = evt => {
    const agentId = evt.target.value
    setAgentId(agentId)
  }

  const deactivateAgent = useMutation({
    mutationFn: payload => {
      return GENERAL.deactivateUser(payload)
    },
    onSuccess: async response => {
      Swal.close()
      queryClient.invalidateQueries({ queryKey: ["getUserById"] })
      Swal.fire("Process Successful", response.data.message, "success")
    },
    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })
  const activateAgent = useMutation({
    mutationFn: payload => {
      return GENERAL.activateUser(payload)
    },
    onSuccess: async response => {
      Swal.close()
      queryClient.invalidateQueries({ queryKey: ["getUserById"] })
      Swal.fire("Process Successful", response.data.message, "success")
    },
    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })
  const deactivateUser = userProfile => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to deactivate this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deactivating Agent...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        // setTimeout(() => {
        //   Swal.fire(
        //     "Process Successful",
        //     "You have successfully deactivated the user",
        //     "success"
        //   )
        // }, 2000)

        const deactivateAgentDto = {
          id: userProfile.id,
        }
        deactivateAgent.mutate(deactivateAgentDto)
      }
    })
  }
  const activateUser = userProfile => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to activate this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Activating Agent...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        const activateAgentDto = {
          id: userProfile.id,
        }
        activateAgent.mutate(activateAgentDto)
      }
    })
  }

  const handleMigrateBeneficiary = () => {
    // Swal.fire({
    //   title: "Migrating Beneficiaries...",
    //   showConfirmButton: false,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     Swal.showLoading(Swal.getDenyButton())
    //   },
    // })
    migrateBeneficiaryFn.mutate({
      agentId: params?.id,
      newAgentNin: searchFilter,
    })
  }

  const migrateBeneficiaryFn = useMutation({
    mutationFn: GENERAL.migrateToBeneficiary,
    onSuccess: async ({ data }) => {
      Alert(data.message, "success")
      setShow(false)
      queryClient.invalidateQueries({ queryKey: ["beneficiariesByAgent"] })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Agents")}
              childLink={props.t("Profile")}
              hasBackBtn={true}
            />
          </Row>

          <Modal size="lg" isOpen={show} centered={true}>
            <ModalBody className="py-3 px-5">
              <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                <Col
                  lg={12}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5>Migrate Beneficiaries</h5>

                    <div onClick={closeModal}>
                      <i
                        className="bx bx-x"
                        style={{ fontSize: 24, cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                </Col>
                <Form>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password-Input">
                          Emter Agent NIN<span className="text-danger"> *</span>
                        </Label>
                        <div className="col-md-12">
                          <input
                            onChange={({ target }) =>
                              setSearchFilter(target.value)
                            }
                            id="search-bar-0"
                            type="text"
                            value={searchFilter}
                            className="form-control"
                            placeholder={`Enter NIN...`}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Row>
                <div className="text-center">
                  <button
                    style={{ marginRight: 10 }}
                    className="btn btn-danger w-80 mr-2"
                    onClick={closeModal}
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    disabled={migrateBeneficiaryFn.isLoading}
                    onClick={handleMigrateBeneficiary}
                    className="btn btn-primary w-80 ml-5"
                  >
                    {migrateBeneficiaryFn.isLoading ? (
                      <ActivityLoader variant="secondary" />
                    ) : (
                      "Migrate Beneficiaries"
                    )}
                  </button>
                </div>
              </Row>
            </ModalBody>
          </Modal>

          {isLoading ? (
            <center>
              <ActivityLoader variant="primary" />
            </center>
          ) : (
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div className="text-black">
                      <h5>Agent Profile</h5>
                    </div>

                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col lg="2" className="mt-5">
                        <div className="d-flex text-center">
                          <div className="ms-3 text-center">
                            <img
                              src={preview_base64(userProfile?.photo || "")}
                              width={400}
                              height={400}
                              style={{ borderRadius: "50%" }}
                              alt={userProfile?.name}
                              className="avatar-xl"
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg="8">
                        <Row>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Full Name</p>
                            <h5>{userProfile?.name ?? "---"}</h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Gender</p>
                            <h5>{userProfile?.gender ?? "---"}</h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Date of Birth</p>
                            <h5>
                              {new Date(
                                userProfile?.date_of_birth
                              ).toLocaleDateString() ?? "---"}
                            </h5>
                          </Col>
                          <Col lg="3" className="mt-5">
                            <p className="mb-1">State</p>
                            <h5>{userProfile?.state_of_origin ?? "---"}</h5>
                          </Col>

                          <Col lg="3" className="mt-5">
                            <p className="mb-1">LGA</p>
                            <h5>{userProfile?.lga_of_origin ?? "---"}</h5>
                          </Col>

                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Status</p>
                            <h5>
                              {userProfile?.status === "new"
                                ? "Active"
                                : userProfile?.status ?? "---"}
                            </h5>
                          </Col>

                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Phone Number</p>
                            <h5>{userProfile?.phone_number ?? "---"}</h5>
                          </Col>

                          <Col lg="3" className="mt-5">
                            <p className="mb-1">Email Address</p>
                            <h5>{userProfile?.email ?? "---"}</h5>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg="2" className="mt-5">
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            onClick={showModal}
                          >
                            Migrate Beneficiaries
                          </button>
                        </div>
                        {/* <div className="mt-3 d-grid">
                        <button
                          className="btn btn-info btn-block"
                          type="submit"
                        >
                          Update Info
                        </button>
                      </div> */}

                        {[USER_STATUS.NEW, USER_STATUS.ACTIVE].includes(
                          userProfile?.status
                        ) && (
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-danger btn-block"
                              type="submit"
                              onClick={() => deactivateUser(userProfile)}
                            >
                              Deactivate Agent
                            </button>
                          </div>
                        )}
                        {userProfile?.status === USER_STATUS.LOCKED && (
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              onClick={() => activateUser(userProfile)}
                            >
                              Activate Agent
                            </button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          )}

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">Registered Beneficiary</div>
              <Fragment>
                {/* <Row className="mb-2">{isGlobalFilter && <GlobalFilter />}</Row> */}

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {isLoadingBeneficiaries ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : beneficiariesData?.records?.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (beneficiariesData?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AgentProfile)

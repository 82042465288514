// import PropTypes from "prop-types"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { ResponsiveContainer } from "recharts"

import BeneficiaryByMaritalStatus from "./BeneficiaryByMaritalStatus"
import BeneficiaryByGender from "./BeneficiaryByGender"
import BeneficiariesByOccupation from "./BeneficiariesByOccupation"
import ReactApexChart from "react-apexcharts"

const SupervisorBeneficiaryStatisticsDashboard = props => {
  const [dashboardStats, setDashboardStats] = useState(null)

  const state = {
    series: [76, 67, 61, 90, 74],

    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ["#4ca8f8", "#fb8b1b", "#62e5e5", "#65e1b3", "#15dc92"],
      labels: ["Lagos", "Kano", "Sokoto", "Adamawa", "Enugu"],
      legend: {
        show: true,
        floating: false,
        fontSize: "16px",
        position: "right",
        offsetX: 10,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }
  const state2 = {
    series: [76, 67, 61, 90, 74],
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ["#4ca8f8", "#fb8b1b", "#62e5e5", "#65e1b3", "#15dc92"],
      labels: ["Lagos", "Kano", "Sokoto", "Adamawa", "Enugu"],
      legend: {
        show: true,
        floating: false,
        fontSize: "16px",
        position: "right",
        offsetX: 10,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }

  const state3 = {
    series: [
      {
        data: [400, 430, 448, 470, 540],
      },
    ],
    options: {
      colors: "#5fe891",
      chart: {
        type: "bar",
        height: 650,
      },
      plotOptions: {
        bar: {
          columnWidth: 25,
          borderRadius: 5,
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["18-25", "26-39", "40-59", "60-70", "80 and Above"],
      },
    },
  }

  //meta title
  document.title = "Dashboard"

  return (
    <Fragment>
      <Row>
        <Col xl="4">
          <Row>
            <Col
              md="6"
              style={{ padding: "3px", marginBottom: "15px", height: "150px" }}
            >
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(129deg, rgb(101, 225, 225) 0%, rgb(117, 229, 229) 46%, rgb(168, 248, 248) 100%) 0% 0% no-repeat padding-box padding-box transparent",

                  padding: "0px",
                }}
              >
                <CardBody>
                  <div
                    className="d-flex"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-white">
                        <i
                          className={"bx bx-copy-alt font-size-24"}
                          style={{ color: "rgb(101, 225, 225)" }}
                        ></i>
                      </span>
                    </div>
                    <div className="flex-grow-1" style={{ marginTop: "10px" }}>
                      <h4 className="mb-0 text-white">
                        {dashboardStats?.totalPendingAgents +
                          dashboardStats?.totalApprovedAgents || 0}
                      </h4>
                      <p className="text-white fw-medium">
                        Total Registration Agents
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col
              md="6"
              style={{ padding: "3px", marginBottom: "15px", height: "150px" }}
            >
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(128deg, rgb(251, 139, 27) 0%, rgb(249, 184, 119) 46%, rgb(251, 139, 27) 100%) 0% 0% no-repeat padding-box padding-box transparent",
                }}
              >
                <CardBody>
                  <div
                    className="d-flex"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-white">
                        <i
                          className={"bx bx-copy-alt font-size-24"}
                          style={{ color: "rgb(251, 139, 27)" }}
                        ></i>
                      </span>
                    </div>
                    <div className="flex-grow-1" style={{ marginTop: "10px" }}>
                      <h4 className="mb-0 text-white">
                        {dashboardStats?.totalApprovedAgents || 0}
                      </h4>
                      <p className="text-white fw-medium">
                        Approved Registration Agents
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" style={{ padding: "3px" }}>
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(144deg, rgb(76, 168, 248) 0%, rgb(138, 195, 243) 46%, rgb(76, 168, 248) 100%) 0% 0% no-repeat padding-box padding-box transparent",
                  height: "150px",
                }}
              >
                <CardBody>
                  <Link to={"/declined-agent-registrations-by-state"}>
                    <div
                      className="d-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            className={"bx bx-copy-alt font-size-24"}
                            style={{ color: "rgb(76, 168, 248)" }}
                          ></i>
                        </span>
                      </div>
                      <div
                        className="flex-grow-1"
                        style={{ marginTop: "10px" }}
                      >
                        <h4 className="mb-0 text-white">
                          {dashboardStats?.totalApprovedAgents || 0}
                        </h4>
                        <p className="text-white fw-medium">
                          Declined Registration Agents
                        </p>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" style={{ padding: "3px" }}>
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(134deg, rgb(121, 225, 101) 0%, rgb(163, 235, 149) 46%, rgb(121, 225, 101) 100%) 0% 0% no-repeat padding-box padding-box transparent",
                  height: "150px",
                }}
              >
                <CardBody>
                  <Link to={"/rejected-agent-registrations-by-state"}>
                    <div
                      className="d-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            className={"bx bx-copy-alt font-size-24"}
                            style={{ color: "rgb(121, 225, 101)" }}
                          ></i>
                        </span>
                      </div>
                      <div
                        className="flex-grow-1"
                        style={{ marginTop: "10px" }}
                      >
                        <h4 className="mb-0 text-white">
                          {dashboardStats?.totalRejectedAgents || 0}
                        </h4>
                        <p className="text-white fw-medium">
                          Rejected Registration Agents
                        </p>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col md={4}>
          <BeneficiaryByMaritalStatus />
        </Col>

        <Col md={4}>
          <BeneficiaryByGender />
        </Col>
      </Row>

      <Row style={{ display: "flex", flexDirection: "row" }}>
        <Col md="4">
          <Card>
            <CardBody>
              <CardTitle> Beneficiaries by age demographics:</CardTitle>
              <div id="chart">
                <ReactApexChart
                  options={state3.options}
                  series={state3.series}
                  type="bar"
                  height={355}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="8">
          <Row>
            <Col md={12}>
              <BeneficiariesByOccupation />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

export default SupervisorBeneficiaryStatisticsDashboard

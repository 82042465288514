import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { useQuery, useQueryClient } from "react-query"
import { GENERAL } from "api/general"
import { useParams } from "react-router"
import ActivityLoader from "components/activity-loader"

const HouseHoldDetails = () => {
  const params = useParams()
  const queryClient = useQueryClient()

  const records = queryClient.getQueryData("getBeneficiaryByBeneficiaryId")
  const { data: profileInfo, isLoading } = useQuery({
    queryKey: ["getBeneficiaryByBeneficiaryId"],
    queryFn: () => {
      return GENERAL.getBeneficiaryByBeneficiaryId(params.id)
    },
  })

  const { data: getBeneficiaryById, isLoading: photoLoading } = useQuery({
    queryKey: ["getBeneficiaryById", params?.id],
    queryFn: () => {
      return GENERAL.getBeneficiaryById(params?.id)
    },
  })

  const { data: bankName } = useQuery({
    queryKey: ["getBanks"],
    queryFn: () => {
      return GENERAL.getBanks()
    },
  })

  function findBankName(bankCode) {
    if (!bankName) {
      return "Bank data not available"
    }
    for (const bank of bankName) {
      if (bank.code === bankCode) {
        return bank.name
      }
    }
    return "Bank not found"
  }

  return (
    <React.Fragment>
      <div className="bg-white box-shadow-css">
        <div className="text-white ">
          <p className=" bg-primary py-1 px-1 col-2 m-0 w-2">
            Beneficiary's Profile
          </p>
        </div>
        {isLoading ? (
          <center className="py-5">
            <ActivityLoader variant="primary" />
          </center>
        ) : (
          <Card>
            <Row>
              <Col lg="12">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col lg="2" className=" mt-5">
                          <div className="d-flex text-center">
                            {photoLoading ? (
                              ""
                            ) : (
                              <div className="ms-3 text-center">
                                <img
                                  src={`data:image/jpeg;base64,${getBeneficiaryById?.data?.photo}`}
                                  width={400}
                                  height={400}
                                  style={{ borderRadius: "50%" }}
                                  alt=""
                                  className="avatar-xl"
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="10">
                          <div className="row row-cols-lg-5">
                            <Col className="mt-5">
                              <p className="mb-1">Application ID</p>
                              <h5>{profileInfo?.beneficiaryId ?? "---"}</h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Number of Dependants</p>
                              <h5>
                                {" "}
                                {profileInfo?.dependentBeneficiaries.length ??
                                  "---"}
                              </h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Surname</p>
                              <h5>
                                {profileInfo?.surname !== ""
                                  ? profileInfo.surname
                                  : "---"}
                              </h5>{" "}
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">First Name</p>
                              <h5>
                                {profileInfo?.firstName !== ""
                                  ? profileInfo.firstName
                                  : "---"}
                              </h5>{" "}
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Middle Name</p>
                              <h5>
                                {profileInfo?.middleName !== ""
                                  ? profileInfo.middleName
                                  : "---"}
                              </h5>{" "}
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Gender</p>
                              <h5>{profileInfo?.gender ?? "---"}</h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">State Of Origin</p>
                              <h5>{profileInfo?.stateOfOrigin ?? "---"}</h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Phone Number</p>
                              <h5>
                                {" "}
                                {profileInfo?.phoneNumber !== ""
                                  ? profileInfo.phoneNumber
                                  : "---"}
                              </h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Home Address</p>
                              <h5>{profileInfo?.address ?? "---"}</h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Beneficiary Category</p>
                              <h5>{profileInfo?.category ?? "---"}</h5>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Col>
            </Row>
            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">
                  HouseHold's Details
                </p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">NSR</p>
                            <h5>
                              {records?.questionnaire?.nrc !== ""
                                ? records?.questionnaire?.nrc
                                : "---"}
                            </h5>
                          </Col>

                          <Col className="mt-5">
                            <p className="mb-1">Locality</p>
                            <h5>
                              {records?.questionnaire?.locality !== ""
                                ? records?.questionnaire?.locality
                                : "---"}
                            </h5>
                          </Col>

                          <Col className="mt-5">
                            <p className="mb-1">Household Phone Number</p>
                            <h5>
                              {records?.questionnaire
                                ?.phoneNumberOfHousehold !== ""
                                ? records?.questionnaire?.phoneNumberOfHousehold
                                : "---"}
                            </h5>
                          </Col>

                          <Col className="mt-5">
                            <p className="mb-1">Household Address</p>
                            <h5>
                              {records?.questionnaire?.householdAddress !== ""
                                ? records?.questionnaire?.householdAddress
                                : "---"}
                            </h5>
                          </Col>

                          <Col className="mt-5">
                            <p className="mb-1">
                              How many people are in this household
                            </p>
                            <h5>
                              {records?.questionnaire?.noOfHousehold !== ""
                                ? records?.questionnaire?.noOfHousehold
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">
                  Principal Labour Market
                </p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">
                              During the past 12 months, what work did you spend
                              most time on
                            </p>
                            <h5>
                              {records?.questionnaire?.work !== ""
                                ? records?.questionnaire?.work
                                : "---"}
                            </h5>
                          </Col>

                          <Col className="mt-5">
                            <p className="mb-1">Specify</p>
                            <h5>
                              {records?.questionnaire?.specificWork !== ""
                                ? records?.questionnaire?.specificWork
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">Education</p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">
                              Highest Educational Qualification Obtained
                            </p>
                            <h5>
                              {records?.questionnaire?.highestEducation !== ""
                                ? records?.questionnaire?.highestEducation
                                : "---"}
                            </h5>
                          </Col>

                          <Col className="mt-5">
                            <p className="mb-1">
                              Is Currently enrolled in School
                            </p>
                            <h5>
                              {records?.questionnaire?.currentlyEnrolled !== ""
                                ? records?.questionnaire?.currentlyEnrolled
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">
                  Health/Disability
                </p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">
                              Benefit from any Health care Facility
                            </p>
                            <h5>
                              {records?.questionnaire?.benefitHealthCare !== ""
                                ? records?.questionnaire?.benefitHealthCare
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Have any form of severe disability
                            </p>
                            <h5>
                              {records?.questionnaire?.severeDisability !== ""
                                ? records?.questionnaire?.severeDisability
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Specify form of severe disability
                            </p>
                            <h5>
                              {records?.questionnaire?.disability !== ""
                                ? records?.questionnaire?.disability
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Is chronically ill </p>
                            <h5>
                              {records?.questionnaire?.chronicallyIll !== ""
                                ? records?.questionnaire?.chronicallyIll
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>
            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">Household Assets</p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">Items</p>
                            <h5>
                              {records?.questionnaire?.items !== ""
                                ? records?.questionnaire?.items
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Does any member of the household own farmland or
                              land for housing
                            </p>
                            <h5>
                              {records?.questionnaire?.householdMemberOwned !==
                              ""
                                ? records?.questionnaire?.householdMemberOwned
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              How many land for housing does your household own
                            </p>
                            <h5>
                              {records?.questionnaire?.noOfLands !== ""
                                ? records?.questionnaire?.noOfLands
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-3 m-0 ">
                  Household Living/Dwelling Characteristics
                </p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-4">
                          <Col className="mt-5">
                            <p className="mb-1">
                              Main construction material used for roof of
                              dwelling
                            </p>
                            <h5>
                              {records?.questionnaire?.roofMaterial !== ""
                                ? records?.questionnaire?.roofMaterial
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Main flooring material</p>
                            <h5>
                              {records?.questionnaire?.floorMaterial !== ""
                                ? records?.questionnaire?.floorMaterial
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Main source of lighting(power) for the dwelling
                            </p>
                            <h5>
                              {records?.questionnaire?.sourceOfLight !== ""
                                ? records?.questionnaire?.sourceOfLight
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Main source of cooking fuel </p>
                            <h5>
                              {records?.questionnaire?.sourceOfCooking !== ""
                                ? records?.questionnaire?.sourceOfCooking
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Main source of drinking water for household
                            </p>
                            <h5>
                              {records?.questionnaire?.sourceOfDrinkingWater !==
                              ""
                                ? records?.questionnaire?.sourceOfDrinkingWater
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Main toilet used by household{" "}
                            </p>
                            <h5>
                              {records?.questionnaire?.typeOfToilet !== ""
                                ? records?.questionnaire?.typeOfToilet
                                : "---" ?? "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-1 m-0 ">Bank Details</p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">Bank name</p>
                            <h5>{findBankName(records?.bankCode)}</h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">BVN</p>
                            <h5>
                              {records?.bvn !== null && records?.bvn !== ""
                                ? records.bvn
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Account number </p>
                            <h5>
                              {records?.accountNumber !== ""
                                ? records?.accountNumber
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Account name </p>
                            <h5>
                              {records?.accountName !== ""
                                ? records?.accountName
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

export default HouseHoldDetails

import React, { Fragment, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"
import SuperAdminAgentView from "./SuperAdminAgentView"
import SupervisorAgentView from "./SupervisorAgentView"

const Agents = () => {
  const [userRole, setUserRole] = useState(
    JSON.parse(sessionStorage.getItem("userRole"))
  )
  //meta title
  document.title = "Agents Registration - eRecruitement"

  return (
    <Fragment>
      {userRole === "SUPER_ADMIN" ? (
        <SuperAdminAgentView />
      ) : (
        <SupervisorAgentView />
      )}
    </Fragment>
  )
}

export default withTranslation()(Agents)

// import AsyncStorage from "@react-native-async-storage/async-storage";
// import * as SecureStore from "expo-secure-store";

export const setKey = async (key, value) => {
  // await SecureStore.setItemAsync(key, value);
  await sessionStorage.setItem(key, value)
}

export const storeData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value)
    // await AsyncStorage.setItem(key, jsonValue);
    await sessionStorage.setItem(key, value)
    key, jsonValue
  } catch (e) {
    // saving error
  }
}

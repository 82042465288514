import PropTypes from "prop-types"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { ResponsiveContainer } from "recharts"

//i18n
import { withTranslation } from "react-i18next"
import BeneficiaryByMaritalStatus from "./BeneficiaryByMaritalStatus"
import BeneficiaryByGender from "./BeneficiaryByGender"
import BeneficiariesByOccupation from "./BeneficiariesByOccupation"
import BeneficiaryVerificationByStates from "./BeneficiaryVerificationByStates"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query"
import { STATISTICS } from "api/statistics"
import { ROLES } from "helpers/global_variables.helper"

const BeneficiaryStatisticsDashboard = props => {
  const userRole = JSON.parse(sessionStorage.getItem("userRole"))

  const [dashboardStats, setDashboardStats] = useState(null)
  const [
    getTopFiveBeneficiariesByStateData,
    setGetTopFiveBeneficiariesByStateData,
  ] = useState([])
  const [
    getTopFiveBeneficiariesByStateLabel,
    setGetTopFiveBeneficiariesByStateLabel,
  ] = useState([])
  const [
    getTopFiveBeneficiariesByLgaData,
    setGetTopFiveBeneficiariesByLgaData,
  ] = useState([])
  const [
    getTopFiveBeneficiariesByLgaLabel,
    setGetTopFiveBeneficiariesByLgaLabel,
  ] = useState([])
  const [beneficiariesByAgeSeries, setBeneficiariesByAgeSeries] = useState([])
  const [beneficiariesByAgeLabel, setBeneficiariesByAgeLabel] = useState([])

  useEffect(() => {
    getTopFiveBeneficiariesByState
    getTopFiveBeneficiariesByLga
    getBeneficiaryCount
    getBeneficiariesByAge
  }, [])

  const getBeneficiaryCount = useQuery({
    queryKey: ["getBeneficiaryCount"],
    queryFn: () => {
      return STATISTICS.getBeneficiaryCount()
    },
    onSuccess: async res => {
      setDashboardStats(res)
    },
  })

  const getTopFiveBeneficiariesByState = useQuery({
    queryKey: ["getTopFiveBeneficiariesByState"],
    queryFn: () => {
      return STATISTICS.getTopFiveBeneficiariesByState()
    },
    onSuccess: async res => {
      const series = []
      const labels = []
      for (let index = 0; index < res.length; index++) {
        const element = res[index]
        series.push(element.total)
        labels.push(element.name)
      }
      setGetTopFiveBeneficiariesByStateData(series)
      setGetTopFiveBeneficiariesByStateLabel(labels)
    },
  })

  const getTopFiveBeneficiariesByLga = useQuery({
    queryKey: ["getTopFiveBeneficiariesByLga"],
    queryFn: () => {
      return STATISTICS.getTopFiveBeneficiariesByLga()
    },
    onSuccess: async res => {
      const series = []
      const labels = []
      for (let index = 0; index < res.length; index++) {
        const element = res[index]
        series.push(element.total)
        labels.push(element.name)
      }
      setGetTopFiveBeneficiariesByLgaData(series)
      setGetTopFiveBeneficiariesByLgaLabel(labels)
    },
  })

  const getBeneficiariesByAge = useQuery({
    queryKey: ["getBeneficiariesByAge"],
    queryFn: () => {
      return STATISTICS.getBeneficiariesByAge()
    },
    onSuccess: async res => {
      const series = []
      const labels = []
      for (let index = 0; index < res.stats.length; index++) {
        const element = res.stats[index]
        series.push(element.total)
        labels.push(element.category)
      }
      setBeneficiariesByAgeSeries(series)
      setBeneficiariesByAgeLabel(labels)
    },
  })

  const state = {
    series: getTopFiveBeneficiariesByStateData,

    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ["#4ca8f8", "#fb8b1b", "#62e5e5", "#65e1b3", "#15dc92"],
      labels: getTopFiveBeneficiariesByStateLabel,
      legend: {
        show: true,
        floating: false,
        fontSize: "16px",
        position: "right",
        offsetX: 10,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }
  const state2 = {
    series: getTopFiveBeneficiariesByLgaData,
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ["#4ca8f8", "#fb8b1b", "#62e5e5", "#65e1b3", "#15dc92"],
      labels: getTopFiveBeneficiariesByLgaLabel,
      legend: {
        show: true,
        floating: false,
        fontSize: "16px",
        position: "right",
        offsetX: 10,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }

  const state3 = {
    series: [
      {
        name: "Count",
        data: beneficiariesByAgeSeries,
      },
    ],
    options: {
      colors: "#5fe891",
      chart: {
        type: "bar",
        height: 650,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: 25,
          borderRadius: 5,
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: beneficiariesByAgeLabel,
      },
    },
  }

  //meta title
  document.title = "Dashboard"

  return (
    <Fragment>
      <Row>
        <Col xl="5">
          <Row>
            <Col
              md="6"
              style={{ padding: "3px", marginBottom: "15px", height: "150px" }}
            >
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(129deg, rgb(101, 225, 225) 0%, rgb(117, 229, 229) 46%, rgb(168, 248, 248) 100%) 0% 0% no-repeat padding-box padding-box transparent",

                  padding: "0px",
                }}
              >
                <CardBody>
                  <div
                    className="d-flex"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-white">
                        <i
                          className={"bx bx-copy-alt font-size-24"}
                          style={{ color: "rgb(101, 225, 225)" }}
                        ></i>
                      </span>
                    </div>
                    <div className="flex-grow-1" style={{ marginTop: "10px" }}>
                      <h4 className="mb-0 text-white">
                        {dashboardStats?.totalBeneficiaries || 0}
                      </h4>
                      <p className="text-white fw-medium">
                        Total Beneficiaries
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col
              md="6"
              style={{ padding: "3px", marginBottom: "15px", height: "150px" }}
            >
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(128deg, rgb(251, 139, 27) 0%, rgb(249, 184, 119) 46%, rgb(251, 139, 27) 100%) 0% 0% no-repeat padding-box padding-box transparent",
                }}
              >
                <CardBody>
                  <div
                    className="d-flex"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-white">
                        <i
                          className={"bx bx-copy-alt font-size-24"}
                          style={{ color: "rgb(251, 139, 27)" }}
                        ></i>
                      </span>
                    </div>
                    <div className="flex-grow-1" style={{ marginTop: "10px" }}>
                      <h4 className="mb-0 text-white">
                        {dashboardStats?.beneficiariesWithNIN || 0}
                      </h4>
                      <p className="text-white fw-medium">
                        Beneficiaries With NIN
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" style={{ padding: "3px" }}>
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(144deg, rgb(76, 168, 248) 0%, rgb(138, 195, 243) 46%, rgb(76, 168, 248) 100%) 0% 0% no-repeat padding-box padding-box transparent",
                  height: "150px",
                }}
              >
                <CardBody>
                  <div
                    className="d-flex"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-white">
                        <i
                          className={"bx bx-copy-alt font-size-24"}
                          style={{ color: "rgb(76, 168, 248)" }}
                        ></i>
                      </span>
                    </div>
                    <div className="flex-grow-1" style={{ marginTop: "10px" }}>
                      <h4 className="mb-0 text-white">
                        {dashboardStats?.beneficiariesWithoutNIN || 0}
                      </h4>
                      <p className="text-white fw-medium">
                        Beneficiaries Without NIN
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" style={{ padding: "3px" }}>
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(134deg, rgb(121, 225, 101) 0%, rgb(163, 235, 149) 46%, rgb(121, 225, 101) 100%) 0% 0% no-repeat padding-box padding-box transparent",
                  height: "150px",
                }}
              >
                <CardBody>
                  <div
                    className="d-flex"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-white">
                        <i
                          className={"bx bx-copy-alt font-size-24"}
                          style={{ color: "rgb(121, 225, 101)" }}
                        ></i>
                      </span>
                    </div>
                    <div className="flex-grow-1" style={{ marginTop: "10px" }}>
                      <h4 className="mb-0 text-white">
                        {dashboardStats?.ineligibleApplicates || 0}
                      </h4>
                      <p className="text-white fw-medium">
                        Ineligible Applicants
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col xl="6">
              <Card>
                <CardBody>
                  <CardTitle>
                    States with the highest number of beneficiaries:
                  </CardTitle>
                  <ResponsiveContainer width="100%" height={250}>
                    <div id="chart">
                      <ReactApexChart
                        options={state.options}
                        series={state.series}
                        type="radialBar"
                        height={260}
                      />
                    </div>
                  </ResponsiveContainer>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody>
                  <CardTitle>
                    LGAs with the highest number of beneficiaries:
                  </CardTitle>
                  <ResponsiveContainer width="100%" height={250}>
                    <div id="chart">
                      <ReactApexChart
                        options={state2.options}
                        series={state2.series}
                        type="radialBar"
                        height={260}
                      />
                    </div>
                  </ResponsiveContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ display: "flex", flexDirection: "row" }}>
        <Col md="4">
          <Card>
            <CardBody>
              <CardTitle> Beneficiaries by age demographics:</CardTitle>
              <div id="chart">
                <ReactApexChart
                  options={state3.options}
                  series={state3.series}
                  type="bar"
                  height={655}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="8">
          <Row>
            <Col md={6}>
              <BeneficiaryByMaritalStatus />
            </Col>
            <Col md={6}>
              <BeneficiaryByGender />
            </Col>
            <Col md={12}>
              <BeneficiariesByOccupation />
            </Col>
          </Row>
        </Col>
      </Row>

      {userRole != ROLES.lga_coordinator && (
        <Row>
          <BeneficiaryVerificationByStates />
        </Row>
      )}
    </Fragment>
  )
}

BeneficiaryStatisticsDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(BeneficiaryStatisticsDashboard)

import PropTypes from "prop-types"
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import StatesWithHighestPayment from "./StatesWithHighestPayment"
import PaymentStatisticsByMonth from "./PaymentStatisticsByMonth"
import PaymentStatisticsByState from "./PaymentStatisticsByState"
import { useQuery } from "react-query"
import { STATISTICS } from "api/statistics"

const PaymentStatisticsDashboard = props => {
  const [dashboardStats, setDashboardStats] = useState(0)

  //meta title
  document.title = "Dashboard"
  const { data: totalPaidToBeneficiary } = useQuery({
    queryKey: ["total_paid_to_beneficiary"],
    queryFn: () => {
      return STATISTICS.getTotalPaymentsToBeneficiaries()
    },
  })


  return (
    <Fragment>
      <Row>
        <Col xl="3">
          <Row>
            <Col
              md="12"
              style={{ padding: "3px", marginBottom: "15px", height: "150px" }}
            >
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(129deg, rgb(101, 225, 225) 0%, rgb(117, 229, 229) 46%, rgb(168, 248, 248) 100%) 0% 0% no-repeat padding-box padding-box transparent",

                  padding: "0px",
                }}
              >
                <CardBody>
                  <Link to={"/total-agent-registrations-by-state"}>
                    <div
                      className="d-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            className={"bx bx-copy-alt font-size-24"}
                            style={{ color: "rgb(101, 225, 225)" }}
                          ></i>
                        </span>
                      </div>
                      <div
                        className="flex-grow-1"
                        style={{ marginTop: "10px" }}
                      >
                        <h4 className="mb-0 text-white">
                          {totalPaidToBeneficiary?.total ?? 0}
                        </h4>
                        <p className="text-white fw-medium">
                          Total Beneficiary Payments
                        </p>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col
              md="12"
              style={{ padding: "3px", marginBottom: "15px", height: "150px" }}
            >
              <Card
                className="mini-stats-wid"
                style={{
                  background:
                    "linear-gradient(128deg, rgb(251, 139, 27) 0%, rgb(249, 184, 119) 46%, rgb(251, 139, 27) 100%) 0% 0% no-repeat padding-box padding-box transparent",
                }}
              >
                <CardBody>
                  <Link to={"/approved-agents-registration"}>
                    <div
                      className="d-flex"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="avatar-sm rounded-circle bg-white mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            className={"bx bx-copy-alt font-size-24"}
                            style={{ color: "rgb(251, 139, 27)" }}
                          ></i>
                        </span>
                      </div>
                      <div
                        className="flex-grow-1"
                        style={{ marginTop: "10px" }}
                      >
                        <h4 className="mb-0 text-white">
                          {dashboardStats?.totalApprovedAgents || 0}
                        </h4>
                        <p className="text-white fw-medium">
                          Total Agent Payments
                        </p>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <StatesWithHighestPayment />
        </Col>
        <Col xl="9">
          <Row>
            <PaymentStatisticsByMonth />
          </Row>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <PaymentStatisticsByState />
        </Col>
      </Row>
    </Fragment>
  )
}

PaymentStatisticsDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(PaymentStatisticsDashboard)

import { STATISTICS } from "api/statistics"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const StatesWithHighestPayment = () => {
  const [topStateSeries, setTopStateSeries] = useState([])
  const [topStateLabel, setTopStateLabel] = useState([])
  const state = {
    // series: [44, 55, 87],
    series: topStateSeries,
    options: {
      // labels: ["Lagos", "Kano", "Brono"],
      labels: topStateLabel,
      colors: ["#339bf5", "#0e6ab9", "#fb8b1b"],
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
              position: "bottom",
            },
          },
        },
      ],
    },
  }

  const { data } = useQuery({
    queryKey: ["topPaymentsByState"],
    queryFn: () => {
      return STATISTICS.top5PaymentsByState()
    },
    onSuccess: async res => {
      const series = []
      const labels = []
      res.stats.forEach((item, index) => {
        labels.push(item.name)
        series.push(item.total)
      })

      setTopStateSeries(series)
      setTopStateLabel(labels)
    },
  })

  const totalOfAllItems = data?.stats?.reduce(
    (acc, item) => acc + parseInt(item.count),
    0
  )
  const colors = ["danger", "primary", "info"]
  const usedColors = new Set()
  const getColor = index => {
    let color
    do {
      color = colors[index % colors.length]
      index++
    } while (usedColors.has(color) && usedColors.size < colors.length)
    usedColors.add(color)
    return color
  }


  return (
    <Card>
      <CardBody>
        <CardTitle>Top Payment by State:</CardTitle>
        <div className="w-5" style={{ width: 310 }}>
          <div id="chart">
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="donut"
            />
          </div>

          {data?.stats?.map((item, index) => {
            const color = getColor(index)
            const percentage = (
              (parseInt(item.count) / totalOfAllItems) *
              100
            ).toFixed(2)

            return (
              <div key={index} className="w-100">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <small className="text-capitalize">{item.name}</small>
                  <small>
                    {`${(
                      (parseInt(item.count) / totalOfAllItems) *
                      100
                    ).toFixed(2)}%`}
                  </small>
                </div>
                <div className="mb-4">
                  <Progress
                    striped
                    color={color}
                    value={(parseInt(item.count) / totalOfAllItems) * 100}
                  ></Progress>
                </div>
              </div>
            )
          })}
        </div>
      </CardBody>
    </Card>
  )
}

export default StatesWithHighestPayment

import axios from "axios"
import isBase64 from "is-base64"
import { get } from "services"
import ErrorHandler from "./error.helper"

export class FileUpload {
  constructor({ file_name, file, mimeType }) {
    this.file_name = file_name
    this.file = file
    this.mimeType = mimeType
  }

  // upload file
  upload = async () => {
    try {
      const response = await this.#fetchFileUploadUrl()
      const uploadAndReturnEtag = await axios.put(response.url, this.file)
      
      return {
        preview_url: response.key,
        etag: uploadAndReturnEtag.headers["etag"].slice(1, -1),
      }
    } catch (error) {
      throw error
    }
  }

  // get upload url from amazon s3
  #fetchFileUploadUrl = async () => {
    try {
      return await get(`/files/get-upload-url/${this.file_name}`)
    } catch (error) {
      throw error
    }
  }

  // get preview url from amazon s3
  static fetchFilePreviewUrl = async key => {
    try {
      return await get(`/files/get-preview-url/${key}`)
    } catch (error) {
      ErrorHandler.parser(error)
    }
  }

  // convert base64 truncated string to blob file || Remove 'data:image/jpeg;base64,' || imageSrc.slice(23)
  #convertBase64ToBlob(base64, mimeType) {
    const dataPrefix = "data:" + mimeType + ";base64,"

    if (!base64.startsWith(dataPrefix) && !isBase64(base64)) return null

    let actualBase64

    if (base64.startsWith(dataPrefix)) {
      actualBase64 = base64.slice(dataPrefix.length)
    } else {
      actualBase64 = base64
    }

    if (!this.#isValidBase64(actualBase64)) return null

    const byteCharacters = atob(actualBase64)
    const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0))
    const byteArray = new Uint8Array(byteNumbers)

    return new Blob([byteArray], { type: mimeType })
  }

  #isValidBase64 = str => {
    return /^[A-Za-z0-9+/=]+$/.test(str)
  }
}

import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Table,
  Form,
  Input,
  Label,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { GENERAL } from "../../api/general"
import Swal from "sweetalert2"
import dayjs from "dayjs"
import useDebounce from "helpers/hooks/useDebounce"
import PaginationComponent from "components/Common/pagination"
import { API_RECORD_LIST_SIZE } from "helpers/global_variables.helper"
import ActivityLoader from "components/activity-loader"

const ReportBuilder = props => {
  useEffect(() => {
    getStatesAndLgas
  }, [])
  const [currentIndex, setCurrentIndex] = useState(1)
  const [menuTitle, setMenuTitle] = React.useState("")
  const [selectedMenu, setSelectedMenu] = React.useState("")
  const [selectedCategory, setSelectedCategory] = React.useState("")
  const [selectedState, setSelectedState] = React.useState("")
  const [selectedLga, setSelectedLga] = React.useState("")
  const [dateFrom, setDateFrom] = React.useState(null)
  const [dateTo, setDateTo] = React.useState(null)
  const [statesAndLgas, setStatesAndLgas] = React.useState([])
  const [lgas, setLgas] = React.useState([])

  // const params = useParams()
  const navigate = useNavigate()
  const [data, setRecords] = useState([])

  const [searchFilter, setSearchFilter] = React.useState("")
  const onChange = value => {
    setSearchFilter(value)
  }
  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const getStatesAndLgas = useQuery({
    queryKey: ["getStatesAndLgas"],
    queryFn: () => {
      return GENERAL.getStatesAndLgas()
    },
    onSuccess: async res => {
      setStatesAndLgas(res)
    },
  })

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return data?.records?.map((_, index) => index + offset + 1)
  }, [currentIndex, data, API_RECORD_LIST_SIZE])

  const beneficiaryColumns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cell => {
          const serialNumber = getSns[cell.row.index]
          return <FieldFormat value={serialNumber} />
        },
      },
      {
        Header: "Full Name",
        accessor: "fullname",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiary_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Tracking ID",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "eligibility_status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Category",
        accessor: "category",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                gotoProfile()
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    [data, currentIndex]
  )
  const verificationColumns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cell => {
          const serialNumber = getSns[cell.row.index]
          return <FieldFormat value={serialNumber} />
        },
      },
      {
        Header: "Full Name",
        accessor: "fullname",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiary_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Tracking ID",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "eligibility_status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Category",
        accessor: "category",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
    ],
    [data, currentIndex]
  )

  const gotoProfile = () => {
    navigate("/profile")
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns:
      selectedMenu === "beneficiary" ? beneficiaryColumns : verificationColumns,
    data: data?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: API_RECORD_LIST_SIZE,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const FullnameFormat = record => {
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.first_name ?? ""} ${rowRecord?.middle_name ?? ""} ${
      rowRecord?.surname ?? ""
    }`
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const menuOptions = [
    { id: "", name: "Select Menu" },
    { id: "beneficiaries", name: "Beneficiary" },
    { id: "verifications", name: "Verifications" },
    // { id: "payment", name: "Payment" },
    // { id: "agent", name: "Agent" },
  ]

  const categoryOptions = [
    { id: "", name: "Select Category" },
    { id: "beneficiaries_with_nin", name: "Beneficiaries With NIN" },
    { id: "beneficiaries_without_nin", name: "Beneficiaries Without NIN" },
    { id: "ineligible_applicants", name: "Ineligible Applicants" },
  ]

  const handleSelectMenu = evt => {
    setSelectedMenu(evt.target.value)
  }
  const handleSelectCategory = evt => {
    setSelectedCategory(evt.target.value)
  }

  const handleSelectState = evt => {
    const state = evt.target.value
    setSelectedState(evt.target.value)
    setSelectedLga("")
    Swal.fire({
      title: "Fetching LGAs...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    Swal.close()
    const lgas = statesAndLgas.find(
      item => item?.state.toLowerCase() === state.toLowerCase()
    ).lgas
    setLgas(lgas)
  }

  const handleSelectLga = evt => {
    const state = evt.target.value
    setSelectedLga(evt.target.value)
  }
  const buildFilterDto = () => {
    return {
      menu: selectedMenu,
      search: searchQuery,
      category: selectedCategory,
      state: selectedState,
      lga: selectedLga,
      dateFrom: dateFrom,
      dateTo: dateTo,
      page: currentIndex,
      take: API_RECORD_LIST_SIZE,
    }
  }

  const handleSubmit = () => {
    if (!selectedMenu) {
      return Swal.fire("", "Please select a menu", "error")
    }
    if (!selectedCategory) {
      return Swal.fire("", "Please select a category", "error")
    }
    if (!dateFrom && dateTo) {
      return Swal.fire("", "Date from is required", "error")
    }

    const filterDto = buildFilterDto()
    buildReportFn.mutate(filterDto)
  }
  const buildReportFn = useMutation({
    mutationFn: payload => {
      return GENERAL.buildReportFn(payload)
    },
    onSuccess: async response => {
      Swal.close()
      setMenuTitle(selectedMenu)
      setRecords(response)
    },
  })

  useEffect(() => {
    const filterDto = buildFilterDto()
    if (selectedCategory && selectedMenu) buildReportFn.mutate(filterDto)
  }, [searchQuery, currentIndex])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Beneficiaries")}
              childLink={props.t("State")}
              thirdLink={props.t("All Benediciaries")}
              hasBackBtn={true}
            />
          </Row>

          <Row>
            <Col md={12}>
              <Card className="large-stats-wid">
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Col lg={8} md={12}>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Menu</Label>
                            <div className="col-md-12">
                              <select
                                onChange={handleSelectMenu}
                                className="form-select"
                                value={selectedMenu}
                              >
                                {menuOptions?.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">
                              Category
                            </Label>
                            <div className="col-md-12">
                              <select
                                onChange={handleSelectCategory}
                                className="form-select"
                                value={selectedCategory}
                              >
                                {categoryOptions?.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">State</Label>
                            <div className="col-md-12">
                              <select
                                onChange={handleSelectState}
                                className="form-select"
                                value={selectedState}
                              >
                                <option>Select State</option>
                                {statesAndLgas?.map((item, index) => (
                                  <option key={index} value={item.state}>
                                    {item.state}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">LGA</Label>
                            <div className="col-md-12">
                              <select
                                onChange={handleSelectLga}
                                className="form-select"
                                value={selectedLga}
                              >
                                <option>Select LGA</option>
                                {lgas?.map((item, index) => (
                                  <option key={index} value={item.lga_name}>
                                    {item.lga_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">
                              Date From
                            </Label>
                            <div className="col-md-12">
                              <Input
                                type="date"
                                placeholder="Enter Date From"
                                name="dateFrom"
                                value={dateFrom}
                                onChange={evt => setDateFrom(evt.target.value)}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Date To</Label>
                            <div className="col-md-12">
                              <Input
                                type="date"
                                placeholder="Enter Date To"
                                name="dateFrom"
                                value={dateTo}
                                onChange={evt => setDateTo(evt.target.value)}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="mt-3 d-grid">
                        {/* {validateEmail.isLoading && (
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Processing
                        </button>
                      )} */}
                        {/* {!validateEmail.isLoading && ( */}
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Search Records
                        </button>
                        {/* )} */}
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={9}>
                  <div className="mb-4 h4 card-title">
                    All {menuTitle === "beneficiaries" && "Beneficiaries"}
                    {menuTitle === "verifications" && "Verifications"}-{" "}
                    {data?.total}
                  </div>
                </Col>

                <Col md={3} className="mb-2">
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col md={12}>
                      <div className="search-box me-xxl-4 my-3 my-xxl-0 d-inline-block">
                        {data?.records?.length > 0 && (
                          <div
                            className="position-relative"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              htmlFor="search-bar-0"
                              className="search-label"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                onChange={e => {
                                  onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`search`}
                                value={searchFilter || ""}
                              />
                            </label>
                            <i
                              className="bx bx-search-alt search-icon"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {buildReportFn.isLoading ? (
                        <tr>
                          <td
                            colSpan={
                              menuTitle === "beneficiaries"
                                ? beneficiaryColumns.length
                                : verificationColumns.length
                            }
                            className=" text-center"
                          >
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : rows.length > 0 ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={
                              menuTitle === "beneficiaries"
                                ? beneficiaryColumns.length
                                : verificationColumns.length
                            }
                            className=" text-center"
                          >
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (data?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ReportBuilder)

import { STATISTICS } from "api/statistics"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query"
import { Card, CardBody, CardTitle } from "reactstrap"

const VerificationStatisticsByMonth = () => {
  useEffect(() => {
    verifiedByMonth
  }, [])

  const [dataSeries, setDataSeries] = useState([])
  const [dataLabel, setDataLabel] = useState([])
  const state3 = {
    series: [
      {
        name: "Count",
        // data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 400, 430],
        data: dataSeries,
      },
    ],
    options: {
      colors: "#62e5e5",
      chart: {
        type: "bar",
        height: 700,
        width: 10,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: 15,
          borderRadius: 7,
          horizontal: false,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: "#62e5e5",
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        // categories: [
        //   "JANUARY",
        //   "FEBRUARY",
        //   "MARCH",
        //   "APRIL",
        //   "MAY",
        //   "JUNE",
        //   "JULY",
        //   "AUGUST",
        //   "SEPTEMBER",
        //   "OCTOBER",
        //   "NOVEMBER",
        //   "DECEMBER",
        // ],
        categories: dataLabel,
      },
    },
  }

  const verifiedByMonth = useQuery({
    queryKey: ["verifiedByMonth"],
    queryFn: () => {
      return STATISTICS.verifiedByMonth()
    },
    onSuccess: async res => {

      const series = []
      const labels = []
      res.stats.forEach((item, index) => {
        labels.push(item.name)
        series.push(item.total)
      })

      setDataSeries(series)
      setDataLabel(labels)
    },
  })
  return (
    <Card>
      <CardBody>
        <CardTitle>Verification statistics by Months:</CardTitle>
        <div style={{ height: 700 }}>
          <div id="chart">
            <ReactApexChart
              options={state3.options}
              series={state3.series}
              type="bar"
              height={700}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default VerificationStatisticsByMonth

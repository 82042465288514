import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GENERAL } from "../../../api/general"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { data } from "common/data/states"
import errorImg from "assets/images/error-img.png"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import ActivityLoader from "components/activity-loader"

const BeneficiaryPaymentByLga = props => {
  const [isErr, setIsErr] = useState(false)
  const params = useParams()
  const [records, setRecords] = useState([])
  const { getVerificationsGroupByLga, isLoading } = useQuery({
    queryKey: [
      "getVerificationsGroupByLga",
      { state: params?.state, month: params?.month, year: params?.year },
    ],
    queryFn: () => {
      return GENERAL.getVerificationsGroupByLga({
        state: params?.state,
        month: params?.month,
        year: params?.year,
      })
    },
    onSuccess: async res => {
      Swal.close()
      setIsErr(false)
      setRecords(res?.sort((a, b) => a.name.localeCompare(b.name)))
    },
  })

  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }
  //meta title
  document.title = "Agents Registration - eRecruitement"
  return (
    <React.Fragment>
      {!isErr && (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Row>
              <Breadcrumbs
                parentLink={props.t("Payment")}
                childLink={props.t("Beneficiary")}
                hasBackBtn={true}
              />
            </Row>
            {isLoading ? (
              <center className="py-5">
                <ActivityLoader variant="primary" />
              </center>
            ) : (
              <Row>
                {records?.map((item, key) => (
                  <Col key={"_col_" + key} md="3">
                    <Card className="mini-stats-wid">
                      <CardBody
                        style={{
                          paddingTop: 40,
                          paddingBottom: 40,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div className="text-center mb-4">
                          <img
                            src={getItemByName(
                              data,
                              String(params?.state).toLowerCase()
                            )}
                            width={100}
                            height={100}
                            style={{ textAlign: "center" }}
                          />

                          <div className="row justify-content-center mt-3">
                            <div className="col-xl-10">
                              <h4 className="text-primary">{item.name}</h4>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "space-between",
                            alignContent: "center",
                          }}
                        >
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1.3rem",
                              fontWeight: "semi-bold",
                              color: "#000",
                            }}
                          >
                            Verified Beneficiaries
                          </h5>

                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                              background: "#bcebca",
                              padding: "10px",
                              marginLeft: "20%",
                              width: "70%",
                              borderRadius: "10px",
                            }}
                          >
                            {item?.registeredBeneficiaries
                              ? item?.registeredBeneficiaries.toLocaleString()
                              : 0}
                          </h6>
                        </Row>
                        <hr />

                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/view-beneficiary-payment-by-lga/${encodeURIComponent(
                                  item.id
                                )}/lga/${encodeURIComponent(item.name)}/${
                                  params?.month
                                }/${params?.year}`}
                              >
                                View Beneficiaries
                              </Link>
                            </h5>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </div>
      )}

      {isErr && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={errorImg} width={500} />
          <p style={{ marginTop: 5, fontSize: 18 }}>
            Error fetching records...
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default withTranslation()(BeneficiaryPaymentByLga)

import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { useMutation, useQuery } from "react-query"
import { GENERAL } from "api/general"
import { states } from "common/data/states"

const VerificationStatisticsByState = () => {
  const statesData = states
  const [dataSeries, setDataSeries] = useState([])
  const [dataLabel, setDataLabel] = useState([])
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1

  const state3 = {
    series: [
      {
        name: "Count",
        data: dataSeries,
      },
    ],
    options: {
      colors: "#4ca8f8",
      chart: {
        type: "bar",
        height: 700,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: 25,
          borderRadius: 5,
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: dataLabel,
      },
    },
  }

  const getVerificationsGroupByState = useQuery({
    queryKey: ["get_verifications_by_state", month, year],
    queryFn: () => {
      return GENERAL.getVerificationsGroupByState({ month, year })
    },

    onSuccess: async res => {
      // Create a mapping object from array A with lowercase names
      const aMapping = res.reduce((acc, item) => {
        acc[item.state.toLowerCase()] = item
        return acc
      }, {})

      // Create a new array by replacing occurrences from array B with items from array A
      const newArray = statesData.map(itemB => {
        const matchedItem = aMapping[itemB.state.toLowerCase()]
        return matchedItem ? { ...itemB, ...matchedItem } : itemB
      })

      const series = []
      const labels = []
      newArray.forEach((item, index) => {
        labels.push(item.state)
        series.push(item.count)
      })

      setDataSeries(series)
      setDataLabel(labels)
    },
  })

  return (
    <Card>
      <CardBody>
        <CardTitle>Verification by states:</CardTitle>
        <div style={{ height: 700 }}>
          <div id="chart">
            <ReactApexChart
              options={state3.options}
              series={state3.series}
              type="bar"
              height={700}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default VerificationStatisticsByState

import abiaStateLogo from "assets/images/states/Abia_State_Logo.png"
import adamawaStateLogo from "assets/images/states/Adamawa_State_Logo.png"
import akwaIbomStateLogo from "assets/images/states/Akwa_Ibom_State_Logo.png"
import AnambraStateLogo from "assets/images/states/Anambra_State_Logo.png"
import BauchiStateLogo from "assets/images/states/Bauchi_State_Logo.png"
import BayelsaStateLogo from "assets/images/states/Bayelsa_State_Logo.png"
import BenueStateLogo from "assets/images/states/Benue_State_Logo.png"
import BornoStateLogo from "assets/images/states/Borno_State_Logo.png"
import CrossRiverStateLogo from "assets/images/states/Cross_River_State_Logo.png"
import DeltaStateLogo from "assets/images/states/Delta_State_Logo.png"
import EbonyiStateLogo from "assets/images/states/Ebonyi_State_Logo.png"
import EdoStateLogo from "assets/images/states/Edo_State_Logo.png"
import EkitiStateLogo from "assets/images/states/Ekiti_State_Logo.png"
import EnuguStateLogo from "assets/images/states/Enugu_State_Logo.png"
import FCTStateLogo from "assets/images/states/FCT.png"
import GOMBESTATELOGOStateLogo from "assets/images/states/GOMBE_STATE_LOGO.png"
import Imo_State_LogoStateLogo from "assets/images/states/Imo_State_Logo.png"
import Jigawa_State_LogoStateLogo from "assets/images/states/Jigawa_State_Logo.png"
import Kaduna_State_LogoStateLogo from "assets/images/states/Kaduna_State_Logo.png"
import Kano_State_LogoStateLogo from "assets/images/states/Kano_State_Logo.png"
import Kastina_State_LogoStateLogo from "assets/images/states/Kastina_State_Logo.png"
import Kebbi_State_LogoStateLogo from "assets/images/states/Kebbi_State_Logo.png"
import Kogi_State_LogoStateLogo from "assets/images/states/Kogi_State_Logo.png"
import Kwara_State_LogoStateLogo from "assets/images/states/Kwara_State_Logo.png"
import Lagos_State_LogoStateLogo from "assets/images/states/Lagos_State_Logo.png"
import Nasarawa_State_LogoStateLogo from "assets/images/states/Nasarawa_State_Logo.png"
import Niger_State_LogoStateLogo from "assets/images/states/Niger_State_Logo.png"
import Ogun_State_LogoStateLogo from "assets/images/states/Ogun_State_Logo.png"
import Ondo_State_LogoStateLogo from "assets/images/states/Ondo_State_Logo.png"
import Osun_State_LogoStateLogo from "assets/images/states/Osun_State_Logo.png"
import Oyo_State_LogoStateLogo from "assets/images/states/Oyo_State_Logo.png"
import Plateau_State_LogoStateLogo from "assets/images/states/Plateau_State_Logo.png"
import Rivers_State_LogoStateLogo from "assets/images/states/Rivers_State_Logo.png"
import Sokoto_State_LogoStateLogo from "assets/images/states/Sokoto_State_Logo.png"
import Taraba_State_LogoStateLogo from "assets/images/states/Taraba_State_Logo.png"
import Yobe_State_LogoStateLogo from "assets/images/states/Yobe_State_Logo.png"
import Zamfara_State_LogoStateLogo from "assets/images/states/Zamfara_State_Logo.png"

export const data = [
  {
    logo: abiaStateLogo,
    name: "ABIA",
  },
  {
    logo: adamawaStateLogo,
    name: "adamawa",
  },
  {
    logo: akwaIbomStateLogo,
    name: "Akwa Ibom",
  },
  {
    logo: AnambraStateLogo,
    name: "Anambra",
  },
  {
    logo: BauchiStateLogo,
    name: "Bauchi",
  },
  {
    logo: BayelsaStateLogo,
    name: "Bayelsa",
  },
  {
    logo: BenueStateLogo,
    name: "Benue",
  },
  {
    logo: BornoStateLogo,
    name: "Borno",
  },
  {
    logo: CrossRiverStateLogo,
    name: "Cross River",
  },
  {
    logo: DeltaStateLogo,
    name: "Delta",
  },
  {
    logo: EbonyiStateLogo,
    name: "Ebonyi",
  },
  {
    logo: EdoStateLogo,
    name: "Edo",
  },
  {
    logo: EkitiStateLogo,
    name: "Ekiti",
  },
  {
    logo: EnuguStateLogo,
    name: "Enugu",
  },
  {
    logo: FCTStateLogo,
    name: "Federal Capital Territory",
  },
  {
    logo: FCTStateLogo,
    name: "FCT Abuja",
  },
  {
    logo: GOMBESTATELOGOStateLogo,
    name: "GOMBE",
  },
  {
    logo: Imo_State_LogoStateLogo,
    name: "Imo",
  },
  {
    logo: Jigawa_State_LogoStateLogo,
    name: "Jigawa",
  },
  {
    logo: Kaduna_State_LogoStateLogo,
    name: "Kaduna",
  },
  {
    logo: Kano_State_LogoStateLogo,
    name: "Kano",
  },
  {
    logo: Kastina_State_LogoStateLogo,
    name: "Katsina",
  },
  {
    logo: Kebbi_State_LogoStateLogo,
    name: "Kebbi",
  },
  {
    logo: Kogi_State_LogoStateLogo,
    name: "Kogi",
  },
  {
    logo: Kwara_State_LogoStateLogo,
    name: "Kwara",
  },
  {
    logo: Lagos_State_LogoStateLogo,
    name: "Lagos",
  },
  {
    logo: Nasarawa_State_LogoStateLogo,
    name: "Nasarawa",
  },
  {
    logo: Niger_State_LogoStateLogo,
    name: "Niger",
  },
  {
    logo: Ogun_State_LogoStateLogo,
    name: "Ogun",
  },
  {
    logo: Ondo_State_LogoStateLogo,
    name: "Ondo",
  },
  {
    logo: Osun_State_LogoStateLogo,
    name: "Osun",
  },
  {
    logo: Oyo_State_LogoStateLogo,
    name: "Oyo",
  },
  {
    logo: Plateau_State_LogoStateLogo,
    name: "Plateau",
  },
  {
    logo: Rivers_State_LogoStateLogo,
    name: "Rivers",
  },
  {
    logo: Sokoto_State_LogoStateLogo,
    name: "Sokoto",
  },
  {
    logo: Taraba_State_LogoStateLogo,
    name: "Taraba",
  },
  {
    logo: Yobe_State_LogoStateLogo,
    name: "Yobe",
  },
  {
    logo: Zamfara_State_LogoStateLogo,
    name: "Zamfara",
  },
]

export const states = [
  {
    id: 31,
    state: "ABIA",
    threshold: 0,
    count: 0,
  },
  {
    id: 7,
    state: "ADAMAWA",
    threshold: 0,
    count: 0,
  },
  {
    id: 30,
    state: "AKWA IBOM",
    threshold: 0,
    count: 0,
  },
  {
    id: 26,
    state: "ANAMBRA",
    threshold: 0,
    count: 0,
  },
  {
    id: 9,
    state: "BAUCHI",
    threshold: 0,
    count: 0,
  },
  {
    id: 34,
    state: "BAYELSA",
    threshold: 0,
    count: 0,
  },
  {
    id: 18,
    state: "BENUE",
    threshold: 0,
    count: 0,
  },
  {
    id: 6,
    state: "BORNO",
    threshold: 0,
    count: 0,
  },
  {
    id: 29,
    state: "CROSS RIVER",
    threshold: 0,
    count: 0,
  },
  {
    id: 35,
    state: "DELTA",
    threshold: 0,
    count: 0,
  },
  {
    id: 28,
    state: "EBONYI",
    threshold: 0,
    count: 0,
  },
  {
    id: 25,
    state: "EDO",
    threshold: 0,
    count: 0,
  },
  {
    id: 23,
    state: "EKITI",
    threshold: 0,
    count: 0,
  },
  {
    id: 27,
    state: "ENUGU",
    threshold: 0,
    count: 0,
  },
  {
    id: 14,
    state: "Federal Capital Territory",
    threshold: 0,
    count: 0,
  },
  {
    id: 8,
    state: "GOMBE",
    threshold: 0,
    count: 0,
  },
  {
    id: 32,
    state: "IMO",
    threshold: 0,
    count: 0,
  },
  {
    id: 4,
    state: "JIGAWA",
    threshold: 0,
    count: 0,
  },
  {
    id: 11,
    state: "KADUNA",
    threshold: 0,
    count: 0,
  },
  {
    id: 10,
    state: "KANO",
    threshold: 0,
    count: 0,
  },
  {
    id: 3,
    state: "KATSINA",
    threshold: 0,
    count: 0,
  },
  {
    id: 12,
    state: "KEBBI",
    threshold: 0,
    count: 0,
  },
  {
    id: 19,
    state: "KOGI",
    threshold: 0,
    count: 0,
  },
  {
    id: 20,
    state: "KWARA",
    threshold: 0,
    count: 0,
  },
  {
    id: 36,
    state: "LAGOS",
    threshold: 0,
    count: 0,
  },
  {
    id: 15,
    state: "NASARAWA",
    threshold: 0,
    count: 0,
  },
  {
    id: 13,
    state: "NIGER",
    threshold: 0,
    count: 0,
  },
  {
    id: 37,
    state: "OGUN",
    threshold: 0,
    count: 0,
  },
  {
    id: 24,
    state: "ONDO",
    threshold: 0,
    count: 0,
  },
  {
    id: 22,
    state: "OSUN",
    threshold: 0,
    count: 0,
  },
  {
    id: 21,
    state: "OYO",
    threshold: 0,
    count: 0,
  },
  {
    id: 16,
    state: "PLATEAU",
    threshold: 0,
    count: 0,
  },
  {
    id: 33,
    state: "RIVERS",
    threshold: 0,
    count: 0,
  },
  {
    id: 1,
    state: "SOKOTO",
    threshold: 0,
    count: 0,
  },
  {
    id: 17,
    state: "TARABA",
    threshold: 0,
    count: 0,
  },
  {
    id: 5,
    state: "YOBE",
    threshold: 0,
    count: 0,
  },
  {
    id: 2,
    state: "ZAMFARA",
    threshold: 0,
    count: 0,
  },
]

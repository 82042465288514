import React, { Fragment, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { useTable } from "react-table"
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import PaginationComponent from "components/Common/pagination"
import dayjs from "dayjs"
import { API_RECORD_LIST_SIZE } from "helpers/global_variables.helper"
import useDebounce from "helpers/hooks/useDebounce"
import { withTranslation } from "react-i18next"
import { GENERAL } from "../../../api/general"
import ActivityLoader from "components/activity-loader"

const LgaCoordinatorViewBeneficiaryByLga = props => {
  //meta title
  document.title = "Agents Registration - eRecruitement"

  const coordinatorLga = JSON.parse(sessionStorage.getItem("me"))?.lgaOfOrigin

  const navigate = useNavigate()

  const [categoryOptions, setCategoryOptions] = useState("")
  const [searchFilter, setSearchFilter] = useState("")
  const [currentIndex, setCurrentIndex] = useState(1)

  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const { data: beneficiaryRecordsByLga, isLoading: isLoadingRecords } =
    useQuery({
      queryKey: [
        "getBeneficiariesByLgaId",
        {
          searchQuery,
          category: categoryOptions,
          page: currentIndex,
          lga: coordinatorLga,
        },
      ],
      queryFn: () => {
        return GENERAL.getBeneficiariesByLgaId({
          lga: coordinatorLga,
          page: currentIndex,
          pageSize: API_RECORD_LIST_SIZE,
          search: searchQuery,
          category: categoryOptions,
        })
      },
      keepPreviousData: true,
    })

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "fullname",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiaryId",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "NIN/Tracking ID",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const NIN = cellProps.cell.row.original
            ? cellProps.cell.row.original.nin
            : ""
          const TrackingId = cellProps.cell.row.original
            ? cellProps.cell.row.original?.beneficiaryNin?.trackingId
            : ""

          if (NIN) {
            return <p>{NIN}</p>
          } else {
            return <p>{TrackingId}</p>
          }
        },
      },
      {
        Header: "Phone No.",
        accessor: "phoneNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State",
        accessor: "stateOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Wallet Status",
        accessor: "",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const { accountNumber, walletNumber } =
            cellProps.cell.row.original || {}
          if (accountNumber) return <p>N/A</p>
          else if (walletNumber) {
            return <p className="text-success">Created</p>
          }
          return null
        },
      },
      {
        Header: "Registration Type",
        accessor: "channel",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Registration Date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded w-100"
              onClick={() => {
                const beneficiaryId = cellProps.cell.row.original.beneficiaryId
                navigate(`/profile/${beneficiaryId}`)
              }}
            >
              View
            </Button>
          )
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: beneficiaryRecordsByLga?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: API_RECORD_LIST_SIZE,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const FullnameFormat = record => {
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.first_name ?? ""} ${rowRecord?.middle_name ?? ""} ${
      rowRecord?.surname ?? ""
    }`
  }

  const filterOptions = [
    { id: "", name: "All Beneficiaries" },
    { id: "beneficiary_with_nin", name: "Beneficiaries With NIN" },
    { id: "beneficiary_without_nin", name: "Beneficiaries Without NIN" },
    { id: "ineligible", name: "Ineligible Applicants" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Beneficiaries")}
              childLink={props.t("LGA")}
              thirdLink={props.t(coordinatorLga)}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={5}>
                  <div className="mb-4 h4 card-title">
                    All Beneficiaries - {beneficiaryRecordsByLga?.total}
                  </div>
                </Col>

                <Col md={7} className="mb-2">
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col md={5}>
                      <div className="search-box me-xxl-4 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <input
                              onChange={({ target }) =>
                                setSearchFilter(target.value)
                              }
                              id="search-bar-0"
                              type="text"
                              className="form-control w-100"
                              placeholder={`${
                                beneficiaryRecordsByLga?.total ?? 0
                              } record(s)...`}
                              value={searchFilter}
                            />
                            <i
                              className="bx bx-search-alt search-icon"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </label>
                        </div>
                      </div>
                    </Col>
                    <Col md={7}>
                      <select
                        onChange={({ target }) =>
                          setCategoryOptions(target.value)
                        }
                        className="form-select"
                        value={categoryOptions}
                      >
                        {filterOptions?.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </Col>
              </div>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {isLoadingRecords ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : beneficiaryRecordsByLga?.records.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (beneficiaryRecordsByLga?.total || 0) /
                        API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(LgaCoordinatorViewBeneficiaryByLga)

import axios from "axios"
import { getKey } from "../helpers"
import ErrorHandler from "helpers/error.helper"

const BASEURL = process.env.REACT_APP_BASEURL

const service = axios.create({
  baseURL: BASEURL,
})

// Add a request interceptor
service.interceptors.request.use(
  async function (config) {
    // let data = JSON.parse(await getKey("accessToken"))
    let data = JSON.parse(await getKey("token"))
    if (data) {
      config.headers["Authorization"] = `Bearer ${data}`
    }

    return config
  },
  function (error) {
    if (error.response) {
      return Promise.reject(error.response)
    } else if (error.request) {
      return Promise.reject(error.request)
    } else {
      return Promise.reject(error.message)
    }
  }
)

// Add a response interceptor
service.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return handleRequestError(error)
  }
)

export const get = async (url, params = {}) => {
  try {
    const { data } = await service.get(url, { params })
    return data
  } catch (error) {
    handleRequestError(error)
    throw error
  }
}

export const post = async (url, payload) => {
  try {
    const data = await service.post(url, payload)
    return data
  } catch (error) {
    handleRequestError(error)
    throw error
  }
}

export const patch = async (url, payload) => {
  try {
    const data = await service.patch(url, payload)
    return data
  } catch (error) {
    handleRequestError(error)
    throw error
  }
}

export const put = async (url, payload) => {
  try {
    const data = await service.put(url, payload)
    return data
  } catch (error) {
    handleRequestError(error)
    throw error
  }
}

export const Delete = async url => {
  try {
    const data = await service.delete(url)
    return data
  } catch (error) {
    handleRequestError(error)
    throw error
  }
}

const handleRequestError = error => {
  if (error.response.status === 401) {
    sessionStorage.clear()
    localStorage.clear()
    window.location.replace("/login")
  } else ErrorHandler.parser(error)
}

import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"
import { useParams } from "react-router"
import ActivityLoader from "components/activity-loader"

const DependantsPersonalDetails = () => {
  const params = useParams()
  const dependantBeneficiaryID = params.dependantId

  const { data: getBeneficiaryById, isLoading: photoLoading } = useQuery({
    queryKey: ["getBeneficiaryById", dependantBeneficiaryID],
    queryFn: () => {
      return GENERAL.getBeneficiaryById(dependantBeneficiaryID)
    },
  })
  
  const { data: profileInfo, isLoading } = useQuery({
    queryKey: ["getBeneficiaryByBeneficiaryId", dependantBeneficiaryID],
    queryFn: () => {
      return GENERAL.getBeneficiaryByBeneficiaryId(dependantBeneficiaryID)
    },
  })

    const { data: bankName } = useQuery({
      queryKey: ["getBanks"],
      queryFn: () => {
        return GENERAL.getBanks()
      },
    })

    function findBankName(bankCode) {
      if (!bankName) {
        return "Bank data not available"
      }
      for (const bank of bankName) {
        if (bank.code === bankCode) {
          return bank.name
        }
      }
      return "Bank not found"
    }


  return (
    <React.Fragment>
      <div className="bg-white box-shadow-css">
        <div className="text-white ">
          <p className=" bg-primary py-1 px-1 col-2 m-0 w-2">
            Dependant Beneficiary's Profile
          </p>
        </div>
        {isLoading ? (
          <center className="py-5">
            <ActivityLoader variant="primary" />
          </center>
        ) : (
          <Card>
            <Row>
              <Col lg="12">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col lg="2" className=" mt-5">
                          <div className="d-flex text-center">
                            {photoLoading ? (
                              ""
                            ) : (
                              <div className="ms-3 text-center">
                                <img
                                  src={`data:image/jpeg;base64,${getBeneficiaryById.data?.photo}`}
                                  width={400}
                                  height={400}
                                  style={{ borderRadius: "50%" }}
                                  alt=""
                                  className="avatar-xl"
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="10">
                          <div className="row row-cols-lg-5">
                            <Col className="mt-5">
                              <p className="mb-1">Surname</p>
                              <h5>
                                {profileInfo?.surname !== ""
                                  ? profileInfo?.surname
                                  : "---"}
                              </h5>{" "}
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">First Name</p>
                              <h5>
                                {profileInfo?.firstName !== ""
                                  ? profileInfo?.firstName
                                  : "---"}
                              </h5>{" "}
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Middle Name</p>
                              <h5>
                                {profileInfo?.middleName !== ""
                                  ? profileInfo?.middleName
                                  : "---"}
                              </h5>{" "}
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Name of Principal</p>
                              <h5>
                                {`${
                                  profileInfo?.beneficiaryNin?.lastName ??
                                  " ----"
                                } ${
                                  profileInfo?.beneficiaryNin?.lastName ?? ""
                                }`}
                              </h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Gender</p>
                              <h5>{profileInfo?.gender ?? "---"}</h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">State Of Origin</p>
                              <h5>{profileInfo?.stateOfOrigin ?? "---"}</h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Phone Number</p>
                              <h5>
                                {" "}
                                {profileInfo?.phoneNumber !== ""
                                  ? profileInfo?.phoneNumber
                                  : "---"}
                              </h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Home Address</p>
                              <h5>{profileInfo?.address ?? "---"}</h5>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Beneficiary Category</p>
                              <h5>{profileInfo?.category ?? "---"}</h5>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Col>
            </Row>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">Labour Market</p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">
                              During the past 12 months, what work did you spend
                              most time on
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.work !== ""
                                ? profileInfo?.questionnaire?.work
                                : "---"}
                            </h5>
                          </Col>

                          <Col className="mt-5">
                            <p className="mb-1">Specify</p>
                            <h5>
                              {profileInfo?.questionnaire?.specificWork !== ""
                                ? profileInfo?.questionnaire?.specificWork
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">Education</p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">
                              Highest Educational Qualification Obtained
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.highestEducation !==
                              ""
                                ? profileInfo?.questionnaire?.highestEducation
                                : "---"}
                            </h5>
                          </Col>

                          <Col className="mt-5">
                            <p className="mb-1">
                              Is Currently enrolled in School
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.currentlyEnrolled !==
                              ""
                                ? profileInfo?.questionnaire?.currentlyEnrolled
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">
                  Health/Disability
                </p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">
                              Benefit from any Health care Facility
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.benefitHealthCare !==
                              ""
                                ? profileInfo?.questionnaire?.benefitHealthCare
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Have any form of severe disability
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.severeDisability !==
                              ""
                                ? profileInfo?.questionnaire?.severeDisability
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Specify form of severe disability
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.disability !== ""
                                ? profileInfo?.questionnaire?.disability
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Is chronically ill </p>
                            <h5>
                              {profileInfo?.questionnaire?.chronicallyIll !== ""
                                ? profileInfo?.questionnaire?.chronicallyIll
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>
            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-2 m-0 ">Household Assets</p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">Items</p>
                            <h5>
                              {profileInfo?.questionnaire?.items !== ""
                                ? profileInfo?.questionnaire?.items
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Does any member of the household own farmland or
                              land for housing
                            </p>
                            <h5>
                              {profileInfo?.questionnaire
                                ?.householdMemberOwned !== ""
                                ? profileInfo?.questionnaire
                                    ?.householdMemberOwned
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              How many land for housing does your household own
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.noOfLands !== ""
                                ? profileInfo?.questionnaire?.noOfLands
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-3 m-0 ">
                  Household Living/Dwelling Characteristics
                </p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-4">
                          <Col className="mt-5">
                            <p className="mb-1">
                              Main construction material used for roof of
                              dwelling
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.roofMaterial !== ""
                                ? profileInfo?.questionnaire?.roofMaterial
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Main flooring material</p>
                            <h5>
                              {profileInfo?.questionnaire?.floorMaterial !== ""
                                ? profileInfo?.questionnaire?.floorMaterial
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Main source of lighting(power) for the dwelling
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.sourceOfLight !== ""
                                ? profileInfo?.questionnaire?.sourceOfLight
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Main source of cooking fuel </p>
                            <h5>
                              {profileInfo?.questionnaire?.sourceOfCooking !==
                              ""
                                ? profileInfo?.questionnaire?.sourceOfCooking
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Main source of drinking water for household
                            </p>
                            <h5>
                              {profileInfo?.questionnaire
                                ?.sourceOfDrinkingWater !== ""
                                ? profileInfo?.questionnaire
                                    ?.sourceOfDrinkingWater
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">
                              Main toilet used by household{" "}
                            </p>
                            <h5>
                              {profileInfo?.questionnaire?.typeOfToilet !== ""
                                ? profileInfo?.questionnaire?.typeOfToilet
                                : "---" ?? "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>

            <div className="bg-white p-2">
              <div className="text-white">
                <p className=" bg-secondary p-1 col-1 m-0 ">Bank Details</p>
              </div>

              <Row>
                <Col lg="12">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="row row-cols-lg-5">
                          <Col className="mt-5">
                            <p className="mb-1">Bank name</p>
                            <h5>{findBankName(profileInfo?.bankCode)}</h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">BVN</p>
                            <h5>
                              {profileInfo?.bvn !== null &&
                              profileInfo?.bvn !== ""
                                ? profileInfo?.bvn
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Account number </p>
                            <h5>
                              {profileInfo?.accountNumber !== ""
                                ? profileInfo?.accountNumber
                                : "---"}
                            </h5>
                          </Col>
                          <Col className="mt-5">
                            <p className="mb-1">Account name </p>
                            <h5>
                              {profileInfo?.accountName !== ""
                                ? profileInfo?.accountName
                                : "---"}
                            </h5>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

export default DependantsPersonalDetails

import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
import withRouter from "components/Common/withRouter"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"
import { useParams } from "react-router"

const UserProfile = props => {
  //meta title
  document.title = "Beneficiary Profile"
  const params = useParams()
  const [profileInfo, setUserProfile] = useState({})

  useEffect(() => {
    getBeneficiaryByBeneficiaryId
  }, [])

  const getBeneficiaryByBeneficiaryId = useQuery({
    queryKey: ["getBeneficiaryByBeneficiaryId"],
    queryFn: () => {
      return GENERAL.getBeneficiaryByBeneficiaryId(params.id)
    },
    onSuccess: async res => {
      setUserProfile(res.data)
    },
  })

  const getBeneficiaryById = useQuery({
    queryKey: ["getBeneficiaryById", params.id],
    queryFn: () => {
      return GENERAL.getBeneficiaryById(params.id)
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            parentLink="Beneficiary"
            childLink="Profile"
            hasBackBtn={true}
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div className="text-black">
                        <h5>Beneficiary's Profile</h5>
                      </div>

                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col lg="2" className="mt-5">
                          <div className="d-flex text-center">
                            <div className="ms-3 text-center">
                              <img
                                src={`data:image/jpeg;base64,${getBeneficiaryById.data?.data?.photo}`}
                                width={400}
                                height={400}
                                style={{ borderRadius: "50%" }}
                                alt=""
                                className="avatar-xl"
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg="10">
                          <Row>
                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Application ID</p>
                              <h5>{profileInfo?.beneficiaryId ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">NIN/Tracking ID</p>
                              <h5>
                                {(profileInfo?.nin ||
                                  profileInfo?.beneficiaryNin?.trackingId) ??
                                  "---"}
                              </h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Surname</p>
                              <h5>{profileInfo?.surname ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">First Name</p>
                              <h5>{profileInfo?.firstName ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Middle Name</p>
                              <h5>{profileInfo?.middleName ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Gender</p>
                              <h5>{profileInfo?.gender ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">State Of Origin</p>
                              <h5>{profileInfo?.stateOfOrigin ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">LGA Of Origin</p>
                              <h5>{profileInfo?.lgaOfOrigin ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Phone Number</p>
                              <h5>{profileInfo?.phoneNumber ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Home Address</p>
                              <h5>{profileInfo?.address ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Beneficiary Category</p>
                              <h5>{profileInfo?.category ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">NIN Status</p>
                              <h5>
                                {(profileInfo?.nin ||
                                  profileInfo?.beneficiaryNin?.trackingId) ??
                                  "---"}
                              </h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Eligibility Status</p>
                              <h5>{profileInfo?.eligibilityStatus ?? "---"}</h5>
                            </Col>

                            <Col lg="3" className="mt-5">
                              <p className="mb-1">Eligibility Check Counts</p>
                              <h5>
                                {profileInfo?.eligibilityCheckCount ??
                                  "---"}
                              </h5>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <div style={{ margin: 20, alignItems: "end" }}>
              <button
                type="submit"
                className="btn btn-danger w-md"
                style={{ marginRight: 25 }}
              >
                Delete
              </button>
              <button type="submit" className="btn btn-info w-md">
                Back
              </button>
            </div>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)

import { post } from "../services";

export const AUTH = {
  createUser: async (createUserDto) => {
    let data = await post(`auth/admin-signup`, createUserDto);
    return data;
  },
  userLogin: async (loginDto) => {
    let data = await post(`auth/login`, loginDto);
    return data;
  },
  verifyOtp: async (otpDto) => {
    let data = await post(`auth/verify-otp`, otpDto);
    return data;
  },
  forgotPwd: async (payload) => {
    let data = await post(`auth/forgot-password`, payload);

    return data;
  },
  validateOtp: async (payload) => {
    let data = await post(`auth/verify-forgot-password-otp`, payload);

    return data;
  },
  changePwd: async (payload) => {
    let data = await post(`auth/change-password`, payload);

    return data;
  },
  updatePassword: async (passwordDto) => {
    let data = await post(`auth/update-password`, passwordDto);

    return data;
  },
};

import { get } from "./apiService"

export const trigerDocumentProcessing = async (filename, etag) => {
  return await get(`/files/process/${filename}/${etag}`)
}

export const fetchDocumentUploads = async (params = {}) => {
  return await get("/files/my-uploads", { ...params })
}

export const fetchDocumentPreviewUrl = async (filename, etag) => {
  return await get(`/files/get-preview-url/${filename}/${etag}`)
}

import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { useTable } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useParams } from "react-router"
import { GENERAL } from "../../../api/general"
import useDebounce from "helpers/hooks/useDebounce"
import PaginationComponent from "components/Common/pagination"
import ActivityLoader from "components/activity-loader"
import { API_RECORD_LIST_SIZE } from "helpers/global_variables.helper"
import Swal from "sweetalert2"

const ViewAgentPaymentsByLga = props => {
  const params = useParams()
  const [data, setRecords] = useState([])
  const [currentIndex, setCurrentIndex] = useState(1)
  const [searchFilter, setSearchFilter] = React.useState("")
  const onChange = value => {
    setSearchFilter(value)
  }
  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  useEffect(() => {
    setCurrentIndex(1)
  }, [searchFilter])

  const { data: getAgentsByLgaData, isLoading } = useQuery({
    queryKey: [
      "get_agents_by_lga",
      {
        page: currentIndex,
        searchQuery,
        lga: params.id,
        month: params?.month,
        year: params?.year,
      },
    ],
    queryFn: () =>
      GENERAL.getUsersByRole({
        search: searchQuery,
        take: API_RECORD_LIST_SIZE,
        month: params?.month,
        year: params?.year,
        lga: params?.id,
        role: "agent",
      }),
  })

  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return getAgentsByLgaData?.records?.map((_, index) => index + offset + 1)
  }, [getAgentsByLgaData, currentIndex, API_RECORD_LIST_SIZE])

  //meta title
  document.title = "Agents Registration - eRecruitement"
  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        Cell: cell => {
          return <p>{getSns[cell.row.index]}</p>
        },
      },
      {
        Header: "Full Name",
        Cell: cellProps => {
          const name = cellProps.row.original.name
          return `${name} `
        },
      },
      {
        Header: "State",
        accessor: "stateOfOrigin",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FormatBold {...cellProps} />
        },
      },
      {
        Header: "Lga",
        accessor: "lgaOfOrigin",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FormatBold {...cellProps} />
        },
      },
      {
        Header: "Total Registrations",
        accessor: "totalRegistrations",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const count = +cellProps.value
          return <>{count}</>
        },
      },
      {
        Header: "Total Verifications",
        accessor: "totalVerified",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const count = +cellProps.value
          return <>{count}</>
        },
      },
      {
        Header: "Total Unverified",
        accessor: "totalUnverified",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const count = +cellProps.value
          return <>{count}</>
        },
      },
      {
        Header: "Payment Qualification",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Bank Name",
        accessor: "bankName",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Account Number",
        accessor: "accountNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Payment Status",
        accessor: "payments[0].status",
        Cell: cell => {
          return <p>{cell.value ? cell.value : "----"}</p>
        },
      },

      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                makePaymentFn(cellProps)
              }}
            >
              Pay
            </Button>
          )
        },
      },
    ],
    [getAgentsByLgaData, currentIndex]
  )

  const makePaymentFn = evt => {
    const agent = evt.cell.row.original
    Swal.fire({
      title: "Are you sure?",
      text: "This process cannot be reversed!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing Payment...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        const paymentDto = {
          agentId: agent?.id,
          month: params.month,
          year: params.year,
        }
        makePayment.mutate(paymentDto)
      }
    })
  }

  const makePayment = useMutation({
    mutationFn: payload => {
      return GENERAL.paySingleAgent(payload)
    },
    onSuccess: async res => {
      Swal.close()
      if (res.status === 201) {
        return Swal.fire("Process Successful", res.data.message, "success")
      }
      if (res.status === 400) {
        return Swal.fire("Process Failed!", res.data.message, "error")
      }
    },
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: getAgentsByLgaData?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: API_RECORD_LIST_SIZE,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const payAll = useMutation({
    mutationFn: payload => {
      return GENERAL.payAllAgentLga(payload)
    },
    onSuccess: async res => {
      Swal.close()
      if (res.status === 201) {
        Swal.fire("Process Successful", res.data.message, "success")
      }
    },
  })

  const payAllFn = evt => {
    Swal.fire({
      title: "Are you sure?",
      text: "This process cannot be reversed!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing Payment...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        const paymentDto = {
          lga: params.id,
          month: params.month,
          year: params.year,
        }
        payAll.mutate(paymentDto)
      }
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("All Agents")}
              breadcrumbItem={props.t("All Agents")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">All Agents</div>
              <Fragment>
                <Row className="mb-2">
                  {/* {isGlobalFilter && <GlobalFilter />} */}
                  <React.Fragment>
                    <Col md={6}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`search`}
                              value={searchFilter || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="btn mb-2 me-2"
                          onClick={payAllFn}
                        >
                          Pay All
                        </Button>
                      </div>
                    </Col>
                  </React.Fragment>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {isLoading ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : rows.length > 0 ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (getAgentsByLgaData?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewAgentPaymentsByLga)

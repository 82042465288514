import React, { useMemo, Fragment, useState, useEffect } from "react"
import { Button, Card, CardBody, Table } from "reactstrap"
import { useTable } from "react-table"
import dayjs from "dayjs"
import ActivityLoader from "components/activity-loader"
import { API_RECORD_LIST_SIZE } from "helpers/global_variables.helper"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

const DependantsDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const [data, setData] = useState([])
  const navigate = useNavigate()

  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return data?.map((_, index) => index + offset + 1)
  }, [currentIndex, data, API_RECORD_LIST_SIZE])

  const queryClient = useQueryClient()
  const records = queryClient.getQueryData("getBeneficiaryByBeneficiaryId")

  useEffect(() => {
    records && setData(records?.dependentBeneficiaries)
  }, [records])

  const columns = useMemo(() => {
    return [
      {
        Header: "S/N",
        Cell: cell => {
          const serialNumber = getSns[cell.row.index]
          return <FieldFormat value={serialNumber} />
        },
      },
      {
        Header: "Full Name",
        // accessor: "fullname",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiaryId",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "NIN/Tracking ID",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <NINFormat {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Wallet Status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <WalletFormat {...cellProps} />
        },
      },
      {
        Header: "State",
        accessor: "stateOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },

      {
        Header: "Home Address",
        accessor: "channel",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Registration Date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "eligibilityButton",
        Cell: cellProps => {
          const dependantBeneficiaryID =
            cellProps.cell.row.original.beneficiaryId
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded w-100"
              onClick={() => {
                navigate(`/beneficiary-profile/dependants/${dependantBeneficiaryID}`)
              }}
            >
              View
            </Button>
          )
        },
      },
    ]
  }, [data, currentIndex])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      initialState: {
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    })

  const FieldFormat = cell => {
    return cell.value ? cell.value : "----"
  }

  const WalletFormat = cell => {
    const { accountNumber, walletNumber } = cell.cell.row.original || {}
    if (accountNumber) return <p>N/A</p>
    else if (walletNumber) {
      return <p className="text-success">Created</p>
    }
    return null
  }

  const NINFormat = cell => {
    const NIN = cell.cell.row.original ? cell.cell.row.original.nin : ""
    const TrackingId = cell.cell.row.original
      ? cell.cell.row.original?.beneficiaryNin?.trackingId
      : ""

    if (NIN) {
      return <p>{NIN}</p>
    } else {
      return <p>{TrackingId}</p>
    }
  }

  const getBeneficiariesByStateId = {
    isLoading: false,
  }

  const FullnameFormat = record => {
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.firstName ?? ""} ${rowRecord?.middleName ?? ""} ${
      rowRecord?.surname ?? ""
    }`
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  return (
    <React.Fragment>
      <div className="bg-white box-shadow-css">
        <div className="text-white ">
          <p className=" bg-primary py-1 px-1 col-2 m-0 w-2">
            Beneficiary's Profile
          </p>
        </div>
        <Card>
          <CardBody>
            <Fragment>
              <div className="table-responsivreact-table">
                <Table bordered hover {...getTableProps()}>
                  <thead className="table-primary table-nowrap">
                    {headerGroups.map(headerGroup => (
                      <tr
                        key={headerGroup.id}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map(column => (
                          <th key={column.id} {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {getBeneficiariesByStateId?.isLoading ||
                    getBeneficiariesByStateId?.isFetching ? (
                      <tr>
                        <td colSpan={columns.length} className=" text-center">
                          <ActivityLoader variant="primary" />
                        </td>
                      </tr>
                    ) : rows.length ? (
                      rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={columns.length} className=" text-center">
                          No results.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              {/* <div className="d-flex justify-content-end align-items-center">
                <PaginationComponent
                  maxPages={Math.ceil(
                    (getBeneficiariesByStateId?.data?.total || 0) /
                      API_RECORD_LIST_SIZE
                  )}
                  getCurrentPage={setCurrentIndex}
                  page={currentIndex}
                />
              </div> */}
            </Fragment>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default DependantsDetails

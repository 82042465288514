import React, { Fragment, useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { GENERAL } from "../../../api/general"
import { useMutation, useQuery } from "react-query"
import { data, states } from "common/data/states"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Swal from "sweetalert2"
import yearOptions, { filterMonthOptions } from "helpers/options.helper"
import ActivityLoader from "components/activity-loader"
import CountUp from "react-countup"

const SuperAdminBeneficiaryPayment = props => {
  const statesData = states
  const [records, setRecords] = useState([])
  const [yearOption, setYearOption] = React.useState(new Date().getFullYear())
  const [monthOption, setMonthOption] = React.useState(
    new Date().getMonth() + 1
  )

  useEffect(() => {
    getVerificationsGroupByState.mutate({
      month: monthOption,
      year: yearOption,
    })
  }, [monthOption, yearOption])

  const getVerificationsGroupByState = useMutation({
    mutationFn: payload => {
      return GENERAL.getVerificationsGroupByState(payload)
    },

    onSuccess: async res => {
      Swal.close()
      // Create a mapping object from array A with lowercase names
      const aMapping = res.reduce((acc, item) => {
        acc[item.state.toLowerCase()] = item
        return acc
      }, {})

      const newArray = statesData.map(itemB => {
        const matchedItem = aMapping[itemB.state.toLowerCase()]
        return matchedItem ? { ...itemB, ...matchedItem } : itemB
      })

      setRecords(newArray.sort((a, b) => a.state.localeCompare(b.state)))
    },
  })

  const { data: getVerifiedBeneficiaryCount, isLoading } = useQuery({
    queryKey: [
      "getVerifiedBeneficiaryCount",
      {
        month: monthOption,
        year: yearOption,
      },
    ],
    queryFn: () => {
      return GENERAL.getVerifiedBeneficiaryCount({
        month: monthOption,
        year: yearOption,
      })
    },
  })

  const {
    data: getUnverifiedBeneficiaryCount,
    isLoading: isUnverifiedLoading,
  } = useQuery({
    queryKey: [
      "getUnverifiedBeneficiaryCount",
      {
        month: monthOption,
        year: yearOption,
      },
    ],
    queryFn: () => {
      return GENERAL.getUnverifiedBeneficiaryCount({
        month: monthOption,
        year: yearOption,
      })
    },
  })

  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }

  return (
    <React.Fragment>
      <Fragment>
        {/* {userRole === "SUPER_ADMIN" && ( */}
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs parentLink="Payment" childLink="Beneficiary Payment" />

            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "end",
                marginBottom: "2rem",
              }}
            >
              <Col md="3">
                <Card
                  className="large-stats-wid mb-0"
                  style={{
                    height: "180px",
                    background:
                      "transparent linear-gradient(144deg, #4CA8F8 0%, #8AC3F3 46%, #4CA8F8 100%) 0% 0% no-repeat padding-box",
                  }}
                >
                  <CardBody>
                    <Link>
                      <div
                        className="d-flex"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          className="avatar-sm rounded-circle bg-primary mini-stat-icon"
                          style={{ marginTop: "10px", marginBottom: "20px" }}
                        >
                          <span className="avatar-title rounded-circle">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>

                        <div className="flex-grow-1">
                          {isLoading ? (
                            ""
                          ) : (
                            <h3 className="mb-0 text-white">
                              {" "}
                              <CountUp
                                start={0}
                                end={
                                  getVerifiedBeneficiaryCount?.count ?? "0"
                                }
                                duration={2.75}
                              />
                            </h3>
                          )}
                          <p className="text-white fw-medium">
                            Verified Beneficiaries
                          </p>
                        </div>
                      </div>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card
                  className="large-stats-wid mb-0"
                  style={{
                    height: "180px",
                    background:
                      "transparent linear-gradient(144deg, #fa9530 0%, #f9b674 46%, #f9a652 100%) 0% 0% no-repeat padding-box",
                  }}
                >
                  <CardBody>
                    <Link>
                      <div
                        className="d-flex"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          className="avatar-sm rounded-circle bg-primary mini-stat-icon"
                          style={{ marginTop: "10px", marginBottom: "20px" }}
                        >
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>

                        <div className="flex-grow-1">
                          {isUnverifiedLoading ? (
                            ""
                          ) : (
                            <h3 className="mb-0 text-white">
                              {" "}
                              <CountUp
                                start={0}
                                end={
                                  getUnverifiedBeneficiaryCount?.count ?? "0"
                                }
                                duration={2.75}
                              />
                            </h3>
                          )}
                          <p className="text-white fw-medium">
                            Unverified Beneficiaries
                          </p>
                        </div>
                      </div>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Row>
                  <Col md={6}>
                    <select
                      onChange={({ target }) => setMonthOption(target.value)}
                      className="form-select"
                      value={monthOption}
                    >
                      <option key="" value="">
                        Select Month
                      </option>
                      {filterMonthOptions?.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={6}>
                    <select
                      onChange={({ target }) => setYearOption(target.value)}
                      className="form-select"
                      value={yearOption}
                    >
                      <option key="" value="">
                        Select Year
                      </option>
                      {yearOptions?.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>

            {getVerificationsGroupByState.isLoading ? (
              <center className="py-5">
                <ActivityLoader variant="primary" />
              </center>
            ) : (
              <Row>
                {records?.map((item, key) => (
                  <Col key={"_col_" + key} md="3">
                    <Card className="mini-stats-wid">
                      <CardBody
                        style={{
                          paddingTop: 40,
                          paddingBottom: 40,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div className="text-center mb-4">
                          <img
                            src={getItemByName(
                              data,
                              String(item?.state).toLowerCase()
                            )}
                            width={100}
                            height={100}
                            style={{ textAlign: "center" }}
                          />

                          <div className="row justify-content-center mt-3">
                            <div className="col-xl-10">
                              <h4 className="text-primary">{item.state}</h4>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "space-between",
                            alignContent: "center",
                          }}
                        >
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1.3rem",
                              fontWeight: "semi-bold",
                              color: "#000",
                            }}
                          >
                            Verified Beneficiaries
                          </h5>

                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                              background: "#bcebca",
                              padding: "10px",
                              marginLeft: "20%",
                              width: "70%",
                              borderRadius: "10px",
                            }}
                          >
                            {item?.count.toLocaleString()}
                          </h6>
                        </Row>
                        <hr />

                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/beneficiary-payment-by-lga/${
                                  item?.id
                                }/${encodeURIComponent(
                                  item?.state
                                )}/${monthOption}/${yearOption}`}
                              >
                                View LGAs
                              </Link>
                            </h5>
                          </Col>
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/view-beneficiary-payment-by-state/${item?.id}/state/${item?.state}/${monthOption}/${yearOption}`}
                              >
                                View Beneficiary
                              </Link>
                            </h5>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </div>
        {/* )} */}

        {/* {userRole === "NIMC_STAFF" && <ViewApprovedAgentsByLga />} */}
      </Fragment>
    </React.Fragment>
  )
}

export default SuperAdminBeneficiaryPayment

import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, CardTitle } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query"
import { STATISTICS } from "api/statistics"

const BeneficiaryByMaritalStatus = () => {
  const [
    beneficiariesByMaritalStatusSeries,
    setBeneficiariesByMaritalStatusSeries,
  ] = useState([])
  const [
    beneficiariesByMaritalStatusLabel,
    setBeneficiariesByMaritalStatusLabel,
  ] = useState([])

  useEffect(() => {
    getBeneficiariesByMaritalStatus
  }, [])

  const getBeneficiariesByMaritalStatus = useQuery({
    queryKey: ["getBeneficiariesByMaritalStatus"],
    queryFn: () => {
      return STATISTICS.getBeneficiariesByMaritalStatus()
    },
    onSuccess: async res => {
      const keysArray = Object.keys(res)
      const valuesArray = Object.values(res)
      setBeneficiariesByMaritalStatusSeries(valuesArray)
      setBeneficiariesByMaritalStatusLabel(keysArray)
    },
  })

  const state3 = {
    series: [
      {
        name: "Beneficiaries",
        data: beneficiariesByMaritalStatusSeries,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 300,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: 10,
          borderRadius: 5,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: beneficiariesByMaritalStatusLabel,
      },
    },
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>Beneficiaries by marital status:</CardTitle>
        {/* <Bar data={data} options={options} /> */}
        <div id="chart">
          <ReactApexChart
            options={state3.options}
            series={state3.series}
            type="bar"
            height={250}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default withTranslation()(BeneficiaryByMaritalStatus)

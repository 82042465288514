import React, { useState } from "react"

//i18n
import { withTranslation } from "react-i18next"
import SuperAdminBeneficiaryPayment from "./SuperAdminBeneficiaryPayment"
import SupervisorBeneficiaryPayment from "./SupervisorBeneficiaryPayment"

const BeneficiaryPayments = props => {
  const [userRole, setUserRole] = useState(
    JSON.parse(sessionStorage.getItem("userRole"))
  )

  //meta title
  document.title = "Beneficisry Payment - Palliative Cash Transfer"
  return (
    <React.Fragment>
      {userRole === "SUPER_ADMIN" ? (
        <SuperAdminBeneficiaryPayment />
      ) : (
        <SupervisorBeneficiaryPayment />
      )}
    </React.Fragment>
  )
}

export default withTranslation()(BeneficiaryPayments)

import React, { useMemo, Fragment, useState } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
  Modal,
  ModalBody,
  Form,
  FormFeedback,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useTable } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
//i18n
import { withTranslation } from "react-i18next"
import { GENERAL } from "../../../api/general"
import dayjs from "dayjs"
import { MoreHorizontal } from "lucide-react"
import PaginationComponent from "components/Common/pagination"
import { API_RECORD_LIST_SIZE } from "helpers/global_variables.helper"
import useDebounce from "helpers/hooks/useDebounce"
import ActivityLoader from "components/activity-loader"

const ProgramSetup = props => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const queryClient = useQueryClient()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: "",
      title: "",
      description: "",
      programme_status: "",
      start_date: "",
      end_date: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      start_date: Yup.string().required("Start date is required"),
      end_date: Yup.string().required("End date is required"),
    }),
    onSubmit: values => {
      Swal.fire({
        title: "Creating Programme...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      const creatProgrammeDto = {
        title: values.title,
        description: values.description,
        startDate: values.start_date,
        endDate: values.end_date,
      }
      createProgrammeFn.mutate(creatProgrammeDto)
    },
  })

  const createProgrammeFn = useMutation({
    mutationFn: payload => {
      return GENERAL.createProgramme(payload)
    },
    onSuccess: async response => {
      Swal.close()
      if (response.status === 201) {
        Swal.fire("Success", "Programme created successfully.", "success").then(
          res => {
            if (res.isConfirmed) {
              validation.setValues({
                id: "",
                title: "",
                description: "",
                start_date: "",
                end_date: "",
              })
              getProgramms.mutate()
              setShow(false)
            }
          }
        )
        queryClient.invalidateQueries({ queryKey: ["single_user"] })
      }
      if (response.status === 400) {
        Swal.fire("Process Failed!", response?.data?.message, "error")
      }
    },

    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })

  const [show, setShow] = useState(false)
  const [showEligibilityForm, setShowEligibilityForm] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [searchFilter, setSearchFilter] = React.useState("")
  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const {
    data: getProgramms,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      "get_programs",
      {
        searchQuery,
        currentIndex,
      },
    ],

    queryFn: () => {
      return GENERAL.getProgramms({
        page: currentIndex,
        search: searchQuery,
        take: API_RECORD_LIST_SIZE,
      })
    },
  })

  //meta title
  document.title = "Agents Registration - eRecruitement"
  const customPageSize = 6

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat value={cellProps.row.index + 1} />
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FormatBold {...cellProps} />
        },
      },
      {
        Header: "Description",
        accessor: "description",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Programme Status",
        accessor: "programmeStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StatusFormat {...cellProps} />
        },
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return dayjs(cellProps.value ?? "").format("DD-MM-YYYY")
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return dayjs(cellProps.value ?? "").format("DD-MM-YYYY")
        },
      },

      // {
      //   Header: "Action",
      //   disableFilters: true,
      //   accessor: "view",
      //   Cell: cellProps => {
      //     return <ButtonFormat {...cellProps} />
      //   },
      // },

      // {
      //   Header: "Action",
      //   disableFilters: true,
      //   accessor: "eligibilityButton",
      //   Cell: cellProps => {
      //     return <EligibiltyButton {...cellProps} />
      //   },
      // },

      {
        Header: "Actions",
        id: "actions",
        Cell: cellProps => {
          const [dropdownOpen, setDropdownOpen] = useState(false)
          return (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => {
                setDropdownOpen(!dropdownOpen)
              }}
            >
              <DropdownToggle
                style={{ cursor: "pointer" }}
                data-toggle="dropdown"
                tag="span"
              >
                <MoreHorizontal />
              </DropdownToggle>
              <DropdownMenu className="border ">
                <DropdownItem className="w-100">
                  <EligibiltyButton {...cellProps} />
                </DropdownItem>
                <DropdownItem className="w-100">
                  <ButtonFormat {...cellProps} />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: getProgramms?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const StatusFormat = cell => {
    return cell?.row?.original.programmeStatus ? (
      <span className="bg-success badge">Active</span>
    ) : (
      <span className="bg-danger badge">Inactive</span>
    )
  }

  const ButtonFormat = cell => {
    const programRecord = cell.row?.original
    return programRecord.programmeStatus ? (
      <Button
        type="button"
        color="danger"
        className="btn-sm w-100 btn-rounded"
        onClick={() => {
          handleToggleActivate(
            programRecord?.id,
            programRecord?.programme_status
          )
        }}
      >
        Deactivate
      </Button>
    ) : (
      <Button
        type="button"
        color="primary"
        className="btn-sm w-100 btn-rounded"
        onClick={() => {
          handleToggleActivate(
            programRecord?.id,
            programRecord?.programme_status
          )
        }}
      >
        Activate
      </Button>
    )
  }

  const handleToggleActivate = (id, status) => {
    Swal.fire({
      title: `Are you sure you want to ${
        status ? "deactivate" : "activate"
      } this program setup?`,
      icon: "question",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (status) {
          Swal.fire({
            title: "Deactivating Program...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(Swal.getDenyButton())
            },
          })

          deactivateProgramFn.mutate(id)
        }
        if (!status) {
          Swal.fire({
            title: "Activating Program Setup...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(Swal.getDenyButton())
            },
          })
          activateProgramFn.mutate(id)
        }
      } else if (result.isDenied) {
        Swal.fire("Process cancelled", "", "info")
      }
    })
  }

  const deactivateProgramFn = useMutation({
    mutationFn: payload => {
      return GENERAL.deactivateProgram(payload)
    },
    onSuccess: async response => {
      Swal.close()
      if (response.status === 200) {
        Swal.fire(
          "Process Successful",
          "Successfully deactivated setup",
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            getProgramms.mutate()
          }
        })
      }
      if (response.status === 400) {
        Swal.fire("Process Failed!", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.fire(
          "Process Failed!",
          response?.data?.message.join(", "),
          "error"
        )
      }
      if (response.status === 401) {
        Swal.fire("Process Failed!", "Unauthorized!", "error")
      }
    },
    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })

  const activateProgramFn = useMutation({
    mutationFn: payload => {
      return GENERAL.activateProgram(payload)
    },
    onSuccess: async response => {
      Swal.close()
      if (response.status === 200) {
        Swal.fire(
          "Process Successful",
          "Successfully activated setup",
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            getProgramms.mutate()
          }
        })
      }
      if (response.status === 400) {
        Swal.fire("Process Failed!", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.fire(
          "Process Failed!",
          response?.data?.message.join(", "),
          "error"
        )
      }
      if (response.status === 401) {
        Swal.fire("Process Failed!", "Unauthorized!", "error")
      }
    },
    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })

  const EligibiltyButton = cell => {
    const programRecord = cell?.row?.original
    const eligibilities = cell.row?.original
    return eligibilities.id != null ? (
      <Button
        type="button"
        color="primary"
        className="btn-sm w-100 btn-rounded"
        // disabled={!programRecord?.programmeStatus}
        onClick={() => {
          updateRecord(eligibilities)
        }}
      >
        View Eligibilty
      </Button>
    ) : (
      <Button
        type="button"
        color="warning"
        className="btn-sm btn-rounded"
        disabled={!programRecord?.programmeStatus}
        onClick={() => {
          createEligibilityRecord(true)
        }}
      >
        Create Eligibilty
      </Button>
    )
  }

  const showModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }
  const closeEligibilityModal = () => {
    setShowEligibilityForm()
  }

  const eligibilityForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: "",
      title: "",
      description: "",
      amount: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      amount: Yup.string().required("Amount is required"),
    }),
    onSubmit: values => {
      if (!isUpdate) {
        Swal.fire({
          title: "Creating Eligibility Setup...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })
        const creatEligibilityDto = {
          title: values.title,
          amount: values.amount,
          failureRule: values.failure_rule,
          qualificationRule: values.qualification_rule,
        }
        createEligibilityFn.mutate(creatEligibilityDto)
      }
      if (isUpdate) {
        Swal.fire({
          title: "Updating Eligibility Setup...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })
        const creatEligibilityDto = {
          id: values.id,
          title: values.title,
          amount: values.amount,
          failureRule: values.failure_rule,
          qualificationRule: values.qualification_rule,
        }
        updateEligibilityFn.mutate(creatEligibilityDto)
      }
    },
  })

  const createEligibilityFn = useMutation({
    mutationFn: payload => {
      return GENERAL.createEligibilityRecord(payload)
    },
    onSuccess: async response => {
      Swal.close()
      if (response.status === 201) {
        Swal.fire(
          "Success",
          "Eligibility created successfully.",
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            eligibilityForm.setValues({
              id: "",
              qualification_rule: "",
              failure_rule: "",
              title: "",
              amount: "",
            })
            // getEligibiltyRecords.refetch()
            getProgramms.mutate()
            setShowEligibilityForm(false)
          }
        })
      }
      if (response.status === 400) {
        Swal.fire("Process Failed!", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.fire(
          "Process Failed!",
          response?.data?.message.join(", "),
          "error"
        )
      }
      if (response.status === 401) {
        Swal.fire("Process Failed!", "Unauthorized!", "error")
      }
    },
  })

  const updateEligibilityFn = useMutation({
    mutationFn: payload => {
      return GENERAL.updateEligibilityRecord(payload)
    },
    onSuccess: async response => {
      Swal.close()
      if (response.status === 200) {
        Swal.fire(
          "Success",
          "Eligibility updated successfully.",
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            eligibilityForm.setValues({
              id: "",
              qualification_rule: "",
              failure_rule: "",
              title: "",
              amount: "",
            })
            // getEligibiltyRecords.refetch()
            getProgramms.mutate()
            setShowEligibilityForm(false)
          }
        })
      }
      if (response.status === 400) {
        Swal.fire("Process Failed!", response?.data?.message, "error")
      }
      if (response.status === 422) {
        Swal.fire(
          "Process Failed!",
          response?.data?.message.join(", "),
          "error"
        )
      }
      if (response.status === 401) {
        Swal.fire("Process Failed!", "Unauthorized!", "error")
      }
    },
  })

  const createEligibilityRecord = () => {
    eligibilityForm.setValues({
      id: "",
      description: "",
      title: "",
      amount: "",
    })
    setIsUpdate(false)
    setShowEligibilityForm(true)
  }

  const updateRecord = record => {
    let { description, title, amount, id } = record
    eligibilityForm.setValues({
      id,
      title,
      amount,
      description,
    })
    setIsUpdate(true)
    setShowEligibilityForm(true)
  }

  return (
    <React.Fragment>
      {/* create program */}
      <Modal size="md" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5>Create Program</h5>

                <div onClick={closeModal}>
                  <i
                    className="bx bx-x"
                    style={{ fontSize: 24, cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </Col>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-title-Input">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="title"
                        className="form-control"
                        placeholder="Enter title"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-description-input">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="description"
                        className="form-control"
                        placeholder="Enter description"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-start_date-input">
                      Start Date <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="start_date"
                        className="form-control"
                        placeholder="Enter start_date"
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.start_date || ""}
                        invalid={
                          validation.touched.start_date &&
                          validation.errors.start_date
                            ? true
                            : false
                        }
                      />
                      {validation.touched.start_date &&
                      validation.errors.start_date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.start_date}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-end_date-input">
                      End Date <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="end_date"
                        className="form-control"
                        placeholder="Enter end_date"
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.end_date || ""}
                        invalid={
                          validation.touched.end_date &&
                          validation.errors.end_date
                            ? true
                            : false
                        }
                      />
                      {validation.touched.end_date &&
                      validation.errors.end_date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.end_date}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="text-center">
                  <button
                    style={{ marginRight: 10 }}
                    className="btn btn-danger w-80 mr-2"
                    onClick={closeModal}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="btn btn-primary w-80 ml-5">
                    SUBMIT
                  </button>
                </div>
              </Row>
            </Form>
          </Row>
        </ModalBody>
      </Modal>

      {/* create eligibility */}
      <Modal size="md" isOpen={showEligibilityForm} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5>{isUpdate ? "Update" : "Create"} Eligibility</h5>

                <div onClick={closeEligibilityModal}>
                  <i
                    className="bx bx-x"
                    style={{ fontSize: 24, cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </Col>
            <Form
              onSubmit={e => {
                e.preventDefault()
                eligibilityForm.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-email-Input">
                      Eligibility Title <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="title"
                        className="form-control"
                        placeholder="Enter title"
                        type="text"
                        onChange={eligibilityForm.handleChange}
                        onBlur={eligibilityForm.handleBlur}
                        value={eligibilityForm.values.title || ""}
                        invalid={
                          eligibilityForm.touched.title &&
                          eligibilityForm.errors.title
                            ? true
                            : false
                        }
                      />
                      {eligibilityForm.touched.title &&
                      eligibilityForm.errors.title ? (
                        <FormFeedback type="invalid">
                          {eligibilityForm.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-qualification_rule-Input">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="qualification_rule"
                        className="form-control"
                        placeholder="Enter Description"
                        type="textarea"
                        onChange={eligibilityForm.handleChange}
                        onBlur={eligibilityForm.handleBlur}
                        value={eligibilityForm.values.description || ""}
                        invalid={
                          eligibilityForm.touched.qualification_rule &&
                          eligibilityForm.errors.qualification_rule
                            ? true
                            : false
                        }
                      />
                      {eligibilityForm.touched.qualification_rule &&
                      eligibilityForm.errors.qualification_rule ? (
                        <FormFeedback type="invalid">
                          {eligibilityForm.errors.qualification_rule}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-amount-Input">
                      Amount <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        name="amount"
                        className="form-control"
                        placeholder="Enter amount"
                        type="text"
                        onChange={eligibilityForm.handleChange}
                        onBlur={eligibilityForm.handleBlur}
                        value={eligibilityForm.values.amount || ""}
                        invalid={
                          eligibilityForm.touched.amount &&
                          eligibilityForm.errors.amount
                            ? true
                            : false
                        }
                      />
                      {eligibilityForm.touched.amount &&
                      eligibilityForm.errors.amount ? (
                        <FormFeedback type="invalid">
                          {eligibilityForm.errors.amount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="text-center">
                  <button
                    style={{ marginRight: 10 }}
                    className="btn btn-danger w-80 mr-2"
                    onClick={() => closeEligibilityModal()}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="btn btn-primary w-80 ml-5">
                    SUBMIT
                  </button>
                </div>
              </Row>
            </Form>
          </Row>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Program Setup")}
              childLink={props.t("Program Setup")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">Program Setups</div>
              <Fragment>
                <Row className="mb-2">
                  <React.Fragment>
                    <Col md={10}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={({ target }) =>
                                setSearchFilter(target.value)
                              }
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`search...`}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </Col>

                    <Col sm="2">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="btn mb-2 me-2"
                          onClick={() => showModal()}
                        >
                          Create Program Setup
                        </Button>
                      </div>
                    </Col>
                  </React.Fragment>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {isLoading || isFetching ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : rows.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-end">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (getProgramms?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ProgramSetup)

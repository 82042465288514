export function generateYears(start) {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let i = start; i <= currentYear; i++) {
    years.push({ value: String(i), name: String(i) })
  }

  return years
}

export const preview_base64 = (basde64String) => {
  if (
    basde64String.startsWith("data:image/png;base64,") ||
    basde64String.startsWith("data:image/jpeg;base64,")
  ) {
    return basde64String
  } else {
    if (basde64String.startsWith("/9j/")) {
      return `data:image/jpeg;base64,${basde64String}`
    } else {
      return `data:image/png;base64,${basde64String}`
    }
  }
}

export function findBankName(bankCode, bankList) {
  if (!bankList) {
    return "Bank data not available"
  }
  for (const bank of bankList) {
    if (bank.code === bankCode) {
      return bank.name
    }
  }
  return "Bank not found"
}

export const numberToMonth = number => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  if (number >= 1 && number <= 12) {
    return months[number - 1]
  } else {
    return "Invalid month number"
  }
}

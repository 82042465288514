import React from "react"
import Pagination from "@mui/material/Pagination"

const PaginationComponent = ({ maxPages, getCurrentPage, page }) => {
  return (
    <>
      <Pagination
        count={maxPages}
        page={page}
        onChange={(event, page) => getCurrentPage(page)}
        size="small"
      />
    </>
  )
}

export default PaginationComponent

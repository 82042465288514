export const VERIFICATION_STATUS = {
  VERIFIED: true,
  UNVERIFIED: false,
}

export const filterMonthOptions = [
  { value: "1", name: "January" },
  { value: "2", name: "February" },
  { value: "3", name: "March" },
  { value: "4", name: "April" },
  { value: "5", name: "May" },
  { value: "6", name: "June" },
  { value: "7", name: "July" },
  { value: "8", name: "August" },
  { value: "9", name: "September" },
  { value: "10", name: "October" },
  { value: "11", name: "November" },
  { value: "12", name: "December" },
]

const yearOptions = []
const currentYear = new Date().getFullYear()
const startYear = currentYear - 10 
const endYear = currentYear

for (let year = startYear; year <= endYear; year++) {
  yearOptions.push({ value: year, name: year.toString() })
}

export default yearOptions

export const filterStatusOptions = [
  { value: "", name: "All Verifications" },
  { value: "true", name: "Verified Beneficiary" },
  { value: "false", name: "Unverified Beneficiary" },
]

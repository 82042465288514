import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, CardTitle } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "react-query"
import { STATISTICS } from "api/statistics"

const BeneficiaryByGender = () => {
  const [beneficiariesByGenderData, setGetBeneficiariesByGenderData] = useState(
    []
  )
  const [beneficiariesByGenderLabel, setGetBeneficiariesByGenderLabel] = useState(
    []
  )

  useEffect(() => {
    getBeneficiariesByGender
  }, [])

  const getBeneficiariesByGender = useQuery({
    queryKey: ["getBeneficiariesByGender"],
    queryFn: () => {
      return STATISTICS.getBeneficiariesByGender()
    },
    onSuccess: async res => {
      // series.push(res?.male ?? 0, res?.female ?? 0)

      const series = []
      const keysArray = Object.keys(res)
      keysArray.forEach(key => {
        if (res.hasOwnProperty(key)) {
          series.push(res[key])
        } else {
          // Handle the case where the key is not available
          series.push(null) // or any other default value
        }
      })

      const keysArray2 = Object.keys(res)
      const labels = []
      keysArray2.forEach(key => {
        if (res.hasOwnProperty(key)) {
          labels.push(key)
        } else {
          // Handle the case where the key is not available
          labels.push(null) // or any other default value
        }
      })

      setGetBeneficiariesByGenderData(series)
      setGetBeneficiariesByGenderLabel(labels)
    },
  })

  const state = {
    series: beneficiariesByGenderData,
    // series: [23, 34],
    options: {
      // labels: ["Male", "Female"],
      labels: beneficiariesByGenderLabel,
      colors: ["#fb8b1b", "#a9d6fe"],
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>Beneficiaries by gender:</CardTitle>
        <div className="w-5" style={{ width: 405 }}>
          <div id="chart">
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="donut"
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default withTranslation()(BeneficiaryByGender)

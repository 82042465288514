import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GENERAL } from "../../../api/general"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { data } from "common/data/states"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ActivityLoader from "components/activity-loader"

const AgentPaymentByLga = props => {
  const params = useParams()
  const [records, setRecords] = useState([])

  const { data: agentsGroupByLga, isLoading } = useQuery({
    queryKey: [
      "get_agents_group_by_lga",
      {
        state: params?.state,
      },
    ],
    queryFn: () => {
      return GENERAL.getAgentsGroupByLga({
        state: params?.state,
      })
    },
  })

  useEffect(() => {
    if (agentsGroupByLga) {
      setRecords(agentsGroupByLga?.sort((a, b) => a.name.localeCompare(b.name)))
    }
  }, [agentsGroupByLga])

  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }
  //meta title
  document.title = "Agents Registration - eRecruitement"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Approved Agents Registration")}
              breadcrumbItem={props.t("Approved Agents Registration By LGA")}
              hasBackBtn={true}
            />
          </Row>
          {isLoading ? (
            <center className="h-full py-5">
              <ActivityLoader variant="primary" />
            </center>
          ) : (
            <Row>
              {records?.map((item, key) => (
                <Col key={"_col_" + key} md="3">
                  <Card className="mini-stats-wid">
                    <CardBody
                      style={{
                        paddingTop: 40,
                        paddingBottom: 40,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <div className="text-center mb-4">
                        <img
                          src={getItemByName(
                            data,
                            String(params?.state).toLowerCase()
                          )}
                          width={100}
                          height={100}
                          style={{ textAlign: "center" }}
                        />

                        <div className="row justify-content-center mt-3">
                          <div className="col-xl-10">
                            <h4 className="text-primary">{item.name}</h4>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col md="5">
                          <Row>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              Threshold
                            </h5>
                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              {item?.threshold?.toLocaleString()}
                            </h6>
                          </Row>
                        </Col>
                        <Col md="7">
                          <Row>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              Total Agents
                            </h5>
                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              {item?.count?.toLocaleString()}
                            </h6>
                          </Row>
                        </Col>
                      </Row>
                      <hr />

                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            <Link
                              to={`/view-agent-payments-by-lga/${item.name}/${params?.month}/${params?.year}`}
                            >
                              View Agents
                            </Link>
                          </h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AgentPaymentByLga)

import React, { Fragment, useMemo, useState } from "react";
import { useTable } from "react-table";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
//Import Breadcrumb
import { GENERAL } from "api/general";
import PaginationComponent from "components/Common/pagination";
import ActivityLoader from "components/activity-loader";
import {
  API_LIST_ORDER,
  API_RECORD_LIST_SIZE,
  GLOBAL_VARIABLES,
} from "helpers/global_variables.helper";
import useDebounce from "helpers/hooks/useDebounce";
import { generateYears } from "helpers/methods.helper";
import { useQuery } from "react-query";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { filterMonthOptions, filterStatusOptions } from "helpers/options.helper";

const SupervisorViewVerification = props => {
  //meta title
  document.title = "Agents Registration - eRecruitement"

  const userData = JSON.parse(sessionStorage.getItem("me"));

  const [currentIndex, setCurrentIndex] = useState(1)
  const [filter, setFiilter] = React.useState({
    status: "",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [searchFilter, setSearchFilter] = useState("")

  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const { data: verificationData, isLoading: isLoadingVerificatoins } =
    useQuery({
      queryKey: [
        "getVerifications",
        { filter: { searchQuery, page: currentIndex, ...filter } },
      ],
      queryFn: () => {
        return GENERAL.getVerifications({
          page: currentIndex,
          take: API_RECORD_LIST_SIZE,
          month: filter.month,
          year: filter.year,
          search: searchQuery,
          order: API_LIST_ORDER.asc,
          state: userData.stateOfOrigin,
          lga: userData.lgaOfOrigin,
        })
      },
    })

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return `${row.original.beneficiary.surname}, ${row.original.beneficiary.firstName}`
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiary.beneficiaryId",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "NIN/Tracking ID",
        accessor: "",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const NIN = cellProps.cell.row.original
            ? cellProps.cell.row.original.beneficiary.nin
            : ""
          const TrackingId = cellProps.cell.row.original
            ? cellProps.cell.row.original?.beneficiary?.beneficiaryNin
                ?.trackingId
            : ""

          if (NIN) {
            return <p>{NIN}</p>
          } else {
            return <p>{TrackingId}</p>
          }
        },
      },
      {
        Header: "Phone No.",
        accessor: "beneficiary.phoneNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Verification Status",
        accessor: "verificationStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          if (cellProps.cell.row.original.nin) {
            return <p>Verified</p>
          } else {
            return <p>Not Verified</p>
          }
        },
      },
      {
        Header: "State",
        accessor: "beneficiary.stateOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA",
        accessor: "beneficiary.lgaOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "beneficiary.address",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "beneficiary.eligibilityStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Agent",
        accessor: "agentId",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Verification Date",
        accessor: "verificationDate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: verificationData?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: API_RECORD_LIST_SIZE,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title="All Verifications"
              breadcrumbItem="All Verifications"
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={3}>
                  <div className="h4 card-title">
                    All Verifications - {verificationData?.total}
                  </div>
                </Col>
                <Col md={9}>
                  <Row>
                    <Col md={3}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${verificationData?.total} records...`}
                              value={searchFilter}
                              onChange={({ target }) =>
                                setSearchFilter(target.value)
                              }
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col
                          md={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ padding: 0, margin: 0 }}>
                            Status
                          </label>
                        </Col>
                        <Col md={10}>
                          <select
                            onChange={({ target }) =>
                              setFiilter(prev => ({
                                ...prev,
                                status: target.value,
                              }))
                            }
                            className="form-select"
                            value={filter.status}
                          >
                            {filterStatusOptions?.map(item => (
                              <option key={item.name} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={3}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col
                          md={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ padding: 0, margin: 0 }}>Year</label>
                        </Col>
                        <Col md={10}>
                          <select
                            onChange={({ target }) =>
                              setFiilter(prev => ({
                                ...prev,
                                year: target.value,
                              }))
                            }
                            className="form-select"
                            value={filter.year}
                          >
                            {generateYears(GLOBAL_VARIABLES.starting_year)?.map(
                              item => (
                                <option key={item.name} value={item.name}>
                                  {item.name}
                                </option>
                              )
                            )}
                          </select>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={3}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col
                          md={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ padding: 0, margin: 0 }}>Month</label>
                        </Col>
                        <Col md={10}>
                          <select
                            onChange={({ target }) =>
                              setFiilter(prev => ({
                                ...prev,
                                month: target.value,
                              }))
                            }
                            className="form-select"
                            value={filter.month}
                          >
                            {filterMonthOptions?.map(item => (
                              <option key={item.value} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {isLoadingVerificatoins ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : verificationData?.records.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-end">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (verificationData?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SupervisorViewVerification

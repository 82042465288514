import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { ROLES } from "helpers/global_variables.helper"

const Navbar = props => {
  // const [menuData, setMenuData] = useState(JSON.parse(sessionStorage.getItem("menu")));
  // console.log("menuData :: ", menuData);
  const [userRole, setUserRole] = useState(
    JSON.parse(sessionStorage.getItem("userRole"))
  )

  const [ui, setui] = useState(false)

  const menuData = [
    {
      name: "DASHBOARD",
      url: "/dashboard",
    },

    {
      name: "BENEFICIARIES",
      url: "/beneficiary",
    },
    {
      name: "VERIFICATIONS",
      url: "/verifications",
    },
    {
      name: "PAYMENTS",
      children: [
        {
          name: "Beneficiaries",
          url: "/beneficiary-payment",
        },
        {
          name: "Agents",
          url: "/agent-payments",
        },
      ],
    },
    {
      name: "AGENTS",
      url: "/agents",
    },
    {
      name: "ELIGIBILITY SETUP",
      url: "/eligibility-setup",
    },
    {
      name: "REPORT BUILDER",
      url: "/report-builder",
    },
    {
      name: "USER ACCESS",
      url: "/manage-users",
    },
  ]

  const supervisorMenuData = [
    {
      name: "DASHBOARD",
      url: "/dashboard",
    },

    {
      name: "BENEFICIARIES",
      url: "/beneficiary",
    },
    {
      name: "VERIFICATIONS",
      url: "/verifications",
    },
    {
      name: "PAYMENTS",
      children: [
        {
          name: "Beneficiaries",
          url: "/beneficiary-payment",
        },
        {
          name: "Agents",
          url: "/agent-payments",
        },
      ],
    },
    {
      name: "AGENTS",
      url: "/agents",
    },
  ]

  const regionalCoordMenuData = [
    {
      name: "DASHBOARD",
      url: "/dashboard",
    },
  ]

  const stateCoordMenuData = [
    {
      name: "DASHBOARD",
      url: "/dashboard",
    },
    {
      name: "BENEFICIARIES",
      url: "/beneficiary-by-lga-state-coordinator",
    },
    {
      name: "UPLOADED DOCUMENTS",
      url: "/uploaded-documents",
    },
  ]

  const lgaCoordMenuData = [
    {
      name: "DASHBOARD",
      url: "/dashboard",
    },
    {
      name: "BENEFICIARIES",
      url: "/view-beneficiary-by-lga/lga-coordinator",
    },
    {
      name: "VERIFICATIONS",
      url: "/verifications",
    },
    {
      name: "PAYMENTS",
      children: [
        {
          name: "Beneficiaries",
          url: "/beneficiary-payment",
        },
        {
          name: "Agents",
          url: "/agent-payments",
        },
      ],
    },
    {
      name: "AGENTS",
      url: "/agents",
    },
  ]

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    // <React.Fragment>
    //   <div className="topnav">
    //     <div className="container-fluid">
    //       <nav
    //         className="navbar navbar-light navbar-expand-lg topnav-menu"
    //         id="navigation"
    //       >
    //         <Collapse
    //           isOpen={props.leftMenu}
    //           className="navbar-collapse"
    //           id="topnav-menu-content"
    //         >
    //           <ul className="navbar-nav">
    //             <li className="nav-item">
    //               <Link className="nav-link" to="/dashboard">
    //                 <i className="bx bx-home-circle me-2"></i>
    //                 {props.t("DASHBOARD")} {props.menuOpen}
    //               </Link>
    //             </li>

    //             <li className="nav-item dropdown">
    //               <Link
    //                 to="/#"
    //                 onClick={e => {
    //                   e.preventDefault()
    //                   setui(!ui)
    //                 }}
    //                 className="nav-link dropdown-toggle arrow-none"
    //               >
    //                 <i className="bx bx-tone me-2"></i>
    //                 {props.t("AGENTS")} <div className="arrow-down"></div>
    //               </Link>
    //               <div
    //                 className={classname(
    //                   "dropdown-menu mega-dropdown-menu dropdown-menu-left",
    //                   { show: ui }
    //                 )}
    //               >
    //                 <Row>
    //                   <Col lg={4}>
    //                     <div>
    //                       <Link
    //                         to="/approved-agents-registration"
    //                         className="dropdown-item"
    //                       >
    //                         {props.t("APPROVED AGENTS")}
    //                       </Link>
    //                       <Link
    //                         to="/approved-nimc-supervisors-by-state"
    //                         className="dropdown-item"
    //                       >
    //                         {props.t("APPROVED NIMC APPLICANTS")}
    //                       </Link>
    //                     </div>
    //                   </Col>
    //                 </Row>
    //               </div>
    //             </li>

    //             <li className="nav-item">
    //               <Link className="nav-link" to="/training-records-by-state">
    //                 <i className="bx bx-home-circle me-2"></i>
    //                 {props.t("TRAINING RECORDS")} {props.menuOpen}
    //               </Link>
    //             </li>

    //             <li className="nav-item">
    //               <Link className="nav-link" to="/generate-code">
    //                 <i className="bx bx-home-circle me-2"></i>
    //                 {props.t("GENERATE CODES")} {props.menuOpen}
    //               </Link>
    //             </li>
    //           </ul>
    //         </Collapse>
    //       </nav>
    //     </div>
    //   </div>
    // </React.Fragment>
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {(
                  (userRole === ROLES.superadmin && menuData) ||
                  (userRole === ROLES.supervisor && supervisorMenuData) ||
                  (userRole === ROLES.regional_coordinator &&
                    regionalCoordMenuData) ||
                  (userRole === ROLES.state_coordinator &&
                    stateCoordMenuData) ||
                  (userRole === ROLES.lga_coordinator && lgaCoordMenuData)
                ).map((item, index) =>
                  item.children && item.children.length > 0 ? (
                    <li key={index} className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setui(!ui)
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {props.t(String(item.name).toUpperCase())}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname(
                          "dropdown-menu mega-dropdown-menu dropdown-menu-left",
                          { show: ui }
                        )}
                      >
                        <Row>
                          <Col lg={4}>
                            <div>
                              {/* map through children link */}
                              {item.children.map((childItem, index) => (
                                <Link
                                  key={index}
                                  to={childItem.url}
                                  className="dropdown-item"
                                >
                                  {props.t(
                                    String(childItem.name).toUpperCase()
                                  )}
                                </Link>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </li>
                  ) : (
                    <li key={index} className="nav-item">
                      <Link className="nav-link" to={item.url}>
                        {props.t(String(item.name).toUpperCase())}{" "}
                        {props.menuOpen}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)

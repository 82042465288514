import { useState } from "react"
import { FileUpload } from "../fileUpload.helper"
import ErrorHandler from "helpers/error.helper"

// export type uploadMimeType = 'application/pdf' | 'image/jpeg' | 'image/png' | 'image/jpg';

export const useFileUploader = () => {
  const [loading, setLoading] = useState(false)

  const upload = async ({ file_name, file, mimeType }) => {
    try {
      setLoading(true)

      const { preview_url, etag } = await new FileUpload({
        file_name: file_name,
        file: file,
        mimeType,
      }).upload()

      setLoading(false)

      return { preview_url, etag }
    } catch (error) {
      setLoading(false)
      ErrorHandler.parser(error)
    }
  }

  return { upload, loading }
}
